import React from 'react';
import { Link } from "react-router-dom";

export default function Header({logo}) {
    return (
        <header className="text-gray-600 body-font">
          <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <Link to="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              <img src={ logo } alt="" className="w-10 h-10 text-white bg-indigo-500 rounded-full"/>
              <span className="ml-3 text-xl">Ash Planetarium</span>
            </Link>
            <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
              <Link to="/" className="mr-5 hover:text-gray-900">Home</Link>
              <Link to="/planets" className="mr-5 hover:text-gray-900">Planets</Link>
              <Link to="/dwrafplanets" className="mr-5 hover:text-gray-900">Dwraf Planets</Link>
              <Link to="/about" className="mr-5 hover:text-gray-900">About</Link>
            </nav>
            <Link to="/explore">
              <button className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Explore
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </Link>
          </div>
        </header>
    )
}
