import React from 'react'

const Phobos = () => {
   return (
      <>
         {/* HeroSection */}
         <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
               <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                  <img className="object-cover object-center rounded" alt="Phobos Image" src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Phobos_colour_2008.jpg"/>
               </div>
               <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Phobos</h1>
                  <p className="mb-8 leading-relaxed">Phobos is the innermost and larger of the two natural satellites of Mars, the other being Deimos. Both moons were discovered in 1877 by American astronomer Asaph Hall. Phobos is named after the Greek god Phobos, a son of Ares and Aphrodite and twin brother of Deimos.</p>
               </div>
            </div>
         </section>

         <hr />

         {/* ImfoOfPhobos */}
         <section className="text-gray-600 body-font">
            {/* Discovery Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Discovery Of Phobos</h1>
               <div className="lg:w-2/3 w-full">
                  <p>Phobos was discovered by astronomer Asaph Hall on 18 August 1877 at the United States Naval Observatory in Washington, D.C., at about 09:14 Greenwich Mean Time (contemporary sources, using the pre-1925 astronomical convention that began the day at noon, give the time of discovery as 17 August at 16:06 Washington mean time, meaning 18 August 04:06 in the modern convention). Hall had discovered Deimos, Mars's other moon, a few days earlier on 12 August 1877 at about 07:48 UTC. The names, originally spelled Phobus and Deimus respectively, were suggested by Henry Madan (1838–1901), science master at Eton College, based on Greek mythology, in which Phobos is a companion to the god, Ares.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Physical Characteristics Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Physical Characteristics Of Phobos</h1>
               <div className="items-center flex flex-col sm:flex-row">
                  <img alt="ecommerce" className="object-cover object-center h-full block mr-1 mb-3 sm:mb-0" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/PIA21858-MarsMoon-Phobos-ThermalIR-20170929.jpg/800px-PIA21858-MarsMoon-Phobos-ThermalIR-20170929.jpg" width="250"/>
                  <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/PIA23206-MartianMoon-Phobos-VIS-TIR-20190424.jpg/800px-PIA23206-MartianMoon-Phobos-VIS-TIR-20190424.jpg" width="345"/>
               </div>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Phobos-surface temperatures (THEMIS)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Phobos has dimensions of 27 km × 22 km × 18 km, and retains too little mass to be rounded under its own gravity. Phobos does not have an atmosphere due to its low mass and low gravity. It is one of the least reflective bodies in the Solar System, with an albedo of about 0.071. Infrared spectra show that it has carbon-rich material found in carbonaceous chondrites. Instead, its composition shows similarities to that of Mars' surface. Phobos's density is too low to be solid rock, and it is known to have significant porosity. These results led to the suggestion that Phobos might contain a substantial reservoir of ice. Spectral observations indicate that the surface regolith layer lacks hydration, but ice below the regolith is not ruled out.</p>
                  <br />
                  <p>Phobos is heavily cratered. The most prominent of these is the crater, Stickney, a large impact crater some 9 km (5.6 mi) in diameter, taking up a substantial proportion of the moon's surface area. As with Mimas's crater Herschel, the impact that created Stickney must have nearly shattered Phobos.</p>
                  <br />
                  <p>Many grooves and streaks also cover the oddly shaped surface. The grooves are typically less than 30 meters (98 ft) deep, 100 to 200 meters (330 to 660 ft) wide, and up to 20 kilometers (12 mi) in length, and were originally assumed to have been the result of the same impact that created Stickney. Analysis of results from the Mars Express spacecraft, however, revealed that the grooves are not in fact radial to Stickney, but are centered on the leading apex of Phobos in its orbit (which is not far from Stickney). Researchers suspect that they have been excavated by material ejected into space by impacts on the surface of Mars. The grooves thus formed as crater chains, and all of them fade away as the trailing apex of Phobos is approached. They have been grouped into 12 or more families of varying age, presumably representing at least 12 Martian impact events. However, in November 2018, following further computational probability analysis, astronomers concluded that the many grooves on Phobos were caused by boulders, ejected from the asteroid impact that created Stickney crater.These boulders rolled in a predictable pattern on the surface of the moon.</p>
                  <br />
                  <p>Faint dust rings produced by Phobos and Deimos have long been predicted but attempts to observe these rings have, to date, failed. Recent images from Mars Global Surveyor indicate that Phobos is covered with a layer of fine-grained regolith at least 100 meters thick; it is hypothesized to have been created by impacts from other bodies, but it is not known how the material stuck to an object with almost no gravity.</p>
                  <br />
                  <p>he unique Kaidun meteorite that fell on a Soviet military base in Yemen in 1980 has been hypothesized to be a piece of Phobos, but this has been difficult to verify because little is known about the exact composition of Phobos.</p>
                  <br />
                  <p>A person who weighs 68 kilogram-force (150 pounds) on Earth would weigh about 40 gram-force (2 ounces) standing on the surface of Phobos.</p>
               </div>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/PIA23893-MarsMoonPhobos-20200608.jpg/1280px-PIA23893-MarsMoonPhobos-20200608.jpg" width="550"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Mars Moon Phobos: six views (8 June 2020)</h3>
               </div>
               {/* Named Geological Features Of Phobos */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Named Geological Features Of Phobos</h2>
               <div className="lg:w-2/3 w-full">
                  <p>Geological features on Phobos are named after astronomers who studied Phobos and people and places from Jonathan Swift's Gulliver's Travels.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Orbital Characteristics Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Orbital Characteristics Of Phobos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/d/db/Orbits_of_Phobos_and_Deimos.gif" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Orbits of Phobos and Deimos. Phobos makes about four orbits for every one made by Deimos.</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>The orbital motion of Phobos has been intensively studied, making it "the best studied natural satellite in the Solar System" in terms of orbits completed. Its close orbit around Mars produces some unusual effects. With an altitude of 5,989 km (3,721 mi), Phobos orbits Mars below the synchronous orbit radius, meaning that it moves around Mars faster than Mars itself rotates. Therefore, from the point of view of an observer on the surface of Mars, it rises in the west, moves comparatively rapidly across the sky (in 4 h 15 min or less) and sets in the east, approximately twice each Martian day (every 11 h 6 min). Because it is close to the surface and in an equatorial orbit, it cannot be seen above the horizon from latitudes greater than 70.4°. Its orbit is so low that its angular diameter, as seen by an observer on Mars, varies visibly with its position in the sky. Seen at the horizon, Phobos is about 0.14° wide; at zenith it is 0.20°, one-third as wide as the full Moon as seen from Earth. By comparison, the Sun has an apparent size of about 0.35° in the Martian sky. Phobos's phases, inasmuch as they can be observed from Mars, take 0.3191 days to run their course, a mere 13 seconds longer than Phobos's sidereal period. As seen from Phobos, Mars would appear 6,400 times larger and 2,500 times brighter than the full Moon appears from Earth, taking up a quarter of the width of a celestial hemisphere.</p>
               </div>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/0/0c/PIA17351-ApparentSizes-MarsDeimosPhobos-EarthMoon.jpg" width="550"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The relative sizes of Deimos and Phobos as seen from the surface of Mars, compared to the relative size in the sky of the Moon as seen from Earth</h3>
               </div>
               {/* Solar Transits Of Phobos */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Solar Transits Of Phobos</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/5/54/PIA17356-MarsCuriosityRover-EclipseOfSunByPhobos.jpg" width="550"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Annular eclipse of the Sun by Phobos as viewed by the Mars Curiosity rover (20 August 2013).</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>An observer situated on the Martian surface, in a position to observe Phobos, would see regular transits of Phobos across the Sun. Several of these transits have been photographed by the Mars Rover Opportunity. During the transits, Phobos's shadow is cast on the surface of Mars; an event which has been photographed by several spacecraft. Phobos is not large enough to cover the Sun's disk, and so cannot cause a total eclipse.</p>
               </div>
               {/* Predicted Destruction Of Phobos */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Predicted Destruction Of Phobos</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/5/54/PIA17356-MarsCuriosityRover-EclipseOfSunByPhobos.jpg" width="550"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Annular eclipse of the Sun by Phobos as viewed by the Mars Curiosity rover (20 August 2013).</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Tidal deceleration is gradually decreasing the orbital radius of Phobos by two meters every 100 years., and with decreasing orbital radius the likelihood of breakup due to tidal forces increases, estimated in approximately 30–50 million years, with one study's estimate being about 43 million years.</p>
                  <br />
                  <p>Tidal deceleration is gradually decreasing the orbital radius of Phobos by two meters every 100 years., and with decreasing orbital radius the likelihood of breakup due to tidal forces increases, estimated in approximately 30–50 million years, with one study's estimate being about 43 million years.</p>
                  <br />
                  <p>Given Phobos's irregular shape and assuming that it is a pile of rubble (specifically a Mohr–Coulomb body), it will eventually break up due to tidal forces when it reaches approximately 2.1 Mars radii. When Phobos is broken up, it will form a planetary ring around Mars. This predicted ring may last from 1 million to 100 million years. The fraction of the mass of Phobos that will form the ring depends on the unknown internal structure of Phobos. Loose, weakly bound material will form the ring. Components of Phobos with strong cohesion will escape tidal breakup and will enter the Martian atmosphere.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Origin Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Origin Of Phobos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/2/2f/PIA17352-MarsMoons-PhobosPassesDeimos-RealTime.gif" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Curiosity's view of the Mars moons: Phobos passing in front of Deimos – in real-time (video-gif, 1 August 2013)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>The origin of the Martian moons is still controversial. Phobos and Deimos both have much in common with carbonaceous C-type asteroids, with spectra, albedo, and density very similar to those of C- or D-type asteroids. Based on their similarity, one hypothesis is that both moons may be captured main-belt asteroids. Both moons have very circular orbits which lie almost exactly in Mars's equatorial plane, and hence a capture origin requires a mechanism for circularizing the initially highly eccentric orbit, and adjusting its inclination into the equatorial plane, most probably by a combination of atmospheric drag and tidal forces, although it is not clear that sufficient time is available for this to occur for Deimos. Capture also requires dissipation of energy. The current Martian atmosphere is too thin to capture a Phobos-sized object by atmospheric braking. Geoffrey A. Landis has pointed out that the capture could have occurred if the original body was a binary asteroid that separated under tidal forces.</p>
                  <br />
                  <p>Phobos could be a second-generation Solar System object that coalesced in orbit after Mars formed, rather than forming concurrently out of the same birth cloud as Mars.</p>
                  <br />
                  <p>Another hypothesis is that Mars was once surrounded by many Phobos- and Deimos-sized bodies, perhaps ejected into orbit around it by a collision with a large planetesimal. The high porosity of the interior of Phobos is inconsistent with an asteroidal origin. Observations of Phobos in the thermal infrared suggest a composition containing mainly phyllosilicates, which are well known from the surface of Mars. The spectra are distinct from those of all classes of chondrite meteorites, again pointing away from an asteroidal origin. Both sets of findings support an origin of Phobos from material ejected by an impact on Mars that reaccreted in Martian orbit, similar to the prevailing theory for the origin of Earth's moon.</p>
                  <br />
                  <p>Some areas of the surface have turned out to be reddish in color, while others are bluish. The hypothesis is that gravity pull from Mars makes the reddish regolith move over the surface, exposing relatively fresh, unweathered and bluish material from the moon, while the regolith covering it over time has been weathered due to exposure of solar radiation. Because the blue rock differs from known Martian rock, it could contradict the theory that the moon is formed from leftover planetary material after the impact of a large object.</p>
                  <br />
                  <p>Most recently, Amirhossein Bagheri (ETH Zurich), Amir Khan (ETH Zurich), Michael Efroimsky (US Naval Observatory) and their colleagues proposed a new hypothesis on the origin of the moons. By analyzing the seismic and orbital data from Mars InSight Mission and other missions, they proposed that the moons are born from disruption of a common parent body around 1 to 2.7 Billion years ago. The common progenitor of Phobos and Deimos was most probably hit by another object and shattered to form Phobos and Deimos</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Shklovsky's "Hollow Phobos" Hypothesis Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Shklovsky's "Hollow Phobos" Hypothesis Of Phobos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Globe_of_Phobos.JPG/1280px-Globe_of_Phobos.JPG" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Globe of Phobos at the Memorial Museum of Astronautics in Moscow (19 May 2012).</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>In the late 1950s and 1960s, the unusual orbital characteristics of Phobos led to speculations that it might be hollow.</p>
                  <br />
                  <p>Around 1958, Russian astrophysicist Iosif Samuilovich Shklovsky, studying the secular acceleration of Phobos's orbital motion, suggested a "thin sheet metal" structure for Phobos, a suggestion which led to speculations that Phobos was of artificial origin. Shklovsky based his analysis on estimates of the upper Martian atmosphere's density, and deduced that for the weak braking effect to be able to account for the secular acceleration, Phobos had to be very light—one calculation yielded a hollow iron sphere 16 kilometers (9.9 mi) across but less than 6 cm thick. In a February 1960 letter to the journal Astronautics, Fred Singer, then science advisor to U.S. President Dwight D. Eisenhower, said of Shklovsky's theory:
                  <li className="mt-2 ml-4">If the satellite is indeed spiraling inward as deduced from astronomical observation, then there is little alternative to the hypothesis that it is hollow and therefore Martian made. The big 'if' lies in the astronomical observations; they may well be in error. Since they are based on several independent sets of measurements taken decades apart by different observers with different instruments, systematic errors may have influenced them.</li>
                  </p>
                  <br />
                  <p>Subsequently, the systematic data errors that Singer predicted were found to exist, and the claim was called into doubt, and accurate measurements of the orbit available by 1969 showed that the discrepancy did not exist. Singer's critique was justified when earlier studies were discovered to have used an overestimated value of 5 cm/yr for the rate of altitude loss, which was later revised to 1.8 cm/yr. The secular acceleration is now attributed to tidal effects, which had not been considered in the earlier studies.</p>
                  <br />
                  <p>The density of Phobos has now been directly measured by spacecraft to be 1.887 g/cm3. Current observations are consistent with Phobos being a rubble pile. In addition, images obtained by the Viking probes in the 1970s clearly showed a natural object, not an artificial one. Nevertheless, mapping by the Mars Express probe and subsequent volume calculations do suggest the presence of voids and indicate that it is not a solid chunk of rock but a porous body. The porosity of Phobos was calculated to be 30% ± 5%, or a quarter to a third being empty.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Exploration Of Phobos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Exploration Of Phobos</h1>
               {/* Launched Missions Of Phobos */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Launched Missions Of Phobos</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/c/cc/Phobos_Viewed_from_Mars.jpg" width="650"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Phobos imaged by Spirit rover (first two images) and by Mars Express (last image) in 2005.</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Phobos has been photographed in close-up by several spacecraft whose primary mission has been to photograph Mars. The first was Mariner 7 in 1969, followed by Mariner 9 in 1971, Viking 1 in 1977, Phobos 2 in 1989 Mars Global Surveyor in 1998 and 2003, Mars Express in 2004, 2008, 2010 and 2019, and Mars Reconnaissance Orbiter in 2007 and 2008. On 25 August 2005, the Spirit rover, with an excess of energy due to wind blowing dust off of its solar panels, took several short-exposure photographs of the night sky from the surface of Mars. Phobos and Deimos are both clearly visible in the photograph.</p>
                  <br />
                  <p>The Soviet Union undertook the Phobos program with two probes, both launched successfully in July 1988. Phobos 1 was accidentally shut down by an erroneous command from ground control issued in September 1988 and lost while the craft was still en route. Phobos 2 arrived at the Mars system in January 1989 and, after transmitting a small amount of data and imagery but shortly before beginning its detailed examination of Phobos's surface, the probe abruptly ceased transmission due either to failure of the onboard computer or of the radio transmitter, already operating on the backup power. Other Mars missions collected more data, but no dedicated sample return mission has been performed.</p>
                  <br />
                  <p>The Russian Space Agency launched a sample return mission to Phobos in November 2011, called Fobos-Grunt. The return capsule also included a life science experiment of The Planetary Society, called Living Interplanetary Flight Experiment, or LIFE. A second contributor to this mission was the China National Space Administration, which supplied a surveying satellite called "Yinghuo-1", which would have been released in the orbit of Mars, and a soil-grinding and sieving system for the scientific payload of the Phobos lander. However, after achieving Earth orbit, the Fobos–Grunt probe failed to initiate subsequent burns that would have sent it to Mars. Attempts to recover the probe were unsuccessful and it crashed back to Earth in January 2012.</p>
                  <br />
                  <p>On 1 July 2020, Mars orbiter of Indian Space Research Organisation was able to capture photos of the body from 4200 km away.</p>
               </div>
               {/* Missions Considered */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 font-medium text-gray-900">Missions Considered</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/S86_25375patraw.jpg/330px-S86_25375patraw.jpg" width="350"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Fuel is mined from Phobos with the help of a nuclear reactor. (P. Rawlings, 1986)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>In 1997 and 1998, the Aladdin mission was selected as a finalist in the NASA Discovery Program. The plan was to visit both Phobos and Deimos, and launch projectiles at the satellites. The probe would collect the ejecta as it performed a slow flyby (~1 km/s). These samples would be returned to Earth for study three years later. The Principal Investigator was Dr. Carle Pieters of Brown University. The total mission cost, including launch vehicle and operations was $247.7 million. Ultimately, the mission chosen to fly was MESSENGER, a probe to Mercury.</p>
                  <br />
                  <p>In 2007, the European aerospace subsidiary EADS Astrium was reported to have been developing a mission to Phobos as a technology demonstrator. Astrium was involved in developing a European Space Agency plan for a sample return mission to Mars, as part of the ESA's Aurora programme, and sending a mission to Phobos with its low gravity was seen as a good opportunity for testing and proving the technologies required for an eventual sample return mission to Mars. The mission was envisioned to start in 2016, was to last for three years. The company planned to use a "mothership", which would be propelled by an ion engine, releasing a lander to the surface of Phobos. The lander would perform some tests and experiments, gather samples in a capsule, then return to the mothership and head back to Earth where the samples would be jettisoned for recovery on the surface.</p>
                  <br />
                  <p>Given Phobos's irregular shape and assuming that it is a pile of rubble (specifically a Mohr–Coulomb body), it will eventually break up due to tidal forces when it reaches approximately 2.1 Mars radii. When Phobos is broken up, it will form a planetary ring around Mars. This predicted ring may last from 1 million to 100 million years. The fraction of the mass of Phobos that will form the ring depends on the unknown internal structure of Phobos. Loose, weakly bound material will form the ring. Components of Phobos with strong cohesion will escape tidal breakup and will enter the Martian atmosphere.</p>
               </div>
               {/* Proposed Missions */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 font-medium text-gray-900">Proposed Missions</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Monolith55103h-crop.jpg" width="650"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The Phobos monolith (right of center) as taken by the Mars Global Surveyor (MOC Image 55103, 1998).</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>In 2007, the Canadian Space Agency funded a study by Optech and the Mars Institute for an unmanned mission to Phobos known as Phobos Reconnaissance and International Mars Exploration (PRIME). A proposed landing site for the PRIME spacecraft is at the "Phobos monolith", a prominent object near Stickney crater. The PRIME mission would be composed of an orbiter and lander, and each would carry 4 instruments designed to study various aspects of Phobos's geology. ` In 2008, NASA Glenn Research Center began studying a Phobos and Deimos sample return mission that would use solar electric propulsion. The study gave rise to the "Hall" mission concept, a New Frontiers-class mission under further study as of 2010.</p>
                  <br />
                  <p>Another concept of a sample return mission from Phobos and Deimos is OSIRIS-REx II, which would use heritage technology from the first OSIRIS-REx mission.</p>
                  <br />
                  <p>As of January 2013, a new Phobos Surveyor mission is currently under development by a collaboration of Stanford University, NASA's Jet Propulsion Laboratory, and the Massachusetts Institute of Technology. The mission is currently in the testing phases, and the team at Stanford plans to launch the mission between 2023 and 2033.</p>
                  <br />
                  <p>In March 2014, a Discovery class mission was proposed to place an orbiter in Mars orbit by 2021 to study Phobos and Deimos through a series of close flybys. The mission is called Phobos And Deimos & Mars Environment (PADME). Two other Phobos missions that were proposed for the Discovery 13 selection included a mission called Merlin, which would flyby Deimos but actually orbit and land on Phobos, and another one is Pandora which would orbit both Deimos and Phobos.</p>
                  <br />
                  <p>The Japanese Aerospace Exploration Agency (JAXA) unveiled on 9 June 2015 the Martian Moons Exploration (MMX), a sample return mission targeting Phobos. MMX will land and collect samples from Phobos multiple times, along with conducting Deimos flyby observations and monitoring Mars's climate. By using a corer sampling mechanism, the spacecraft aims to retrieve a minimum 10 g amount of samples. NASA, ESA, DLR, and CNES are also participating in the project, and will provide scientific instruments. The U.S. will contribute the Neutron and Gamma-Ray Spectrometer (NGRS), and France the Near IR Spectrometer (NIRS4/MacrOmega). Although the mission has been selected for implementation and is now beyond proposal stage, formal project approval by JAXA has been postponed following the Hitomi mishap. Development and testing of key components, including the sampler, is currently ongoing. As of 2017, MMX is scheduled to be launched in 2024, and will return to Earth five years later.</p>
                  <br />
                  <p>Russia plans to repeat Fobos-Grunt mission in the late 2020s, and the European Space Agency is assessing a sample-return mission for 2024 called Phootprint.</p>
               </div>
               {/* As Part Of A Human Mission To Mars */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 font-medium text-gray-900">As Part Of A Human Mission To Mars</h2>
               <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/2/23/Phobosmgs.gif" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Phobos in 1998</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Phobos has been proposed as an early target for a human mission to Mars. The teleoperation of robotic scouts on Mars by humans on Phobos could be conducted without significant time delay, and planetary protection concerns in early Mars exploration might be addressed by such an approach.</p>
                  <br />
                  <p>Phobos has been proposed as an early target for a manned mission to Mars because a landing on Phobos would be considerably less difficult and expensive than a landing on the surface of Mars itself. A lander bound for Mars would need to be capable of atmospheric entry and subsequent return to orbit without any support facilities, or would require the creation of support facilities in-situ. A lander instead bound for Phobos could be based on equipment designed for lunar and asteroid landings. Furthermore, due to Phobos's very weak gravity, the delta-v required to land on Phobos and return is only 80% of that required for a trip to and from the surface of the Moon.</p>
                  <br />
                  <p>It has been proposed that the sands of Phobos could serve as a valuable material for aerobraking during a Mars landing. A relatively small amount of chemical fuel brought from Earth could be used to lift a large amount of sand from the surface of Phobos to a transfer orbit. This sand could be released in front of a spacecraft during the descent maneuver causing a densification of the atmosphere just in front of the spacecraft.</p>
                  <br />
                  <p>While human exploration of Phobos could serve as a catalyst for the human exploration of Mars, it could be scientifically valuable in its own right.</p>
               </div>
            </div>
         </section>
      </>
   )
}

export default Phobos
