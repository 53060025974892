// ! Imports
import React from 'react';
// ? MainComponents
import Header from './Components/Header.jsx';
import Footer from './Components/Footer.jsx';
// ? Basic Components
import Home from './Components/Home.jsx';
import About from './Components/About.jsx';
import Explore from './Components/Explore.jsx';
// ? Planets
import Planets from './Components/Planets/Planets.jsx';
import Mercury from './Components/Planets/Mercury/Mercury.jsx';
import Venus from './Components/Planets/Venus/Venus.jsx';
import EarthAndItsMoons from './Components/Planets/Earth/Earth_And_Its_Moons.jsx';
import Earth from './Components/Planets/Earth/Earth.jsx';
import Moon from './Components/Planets/Earth/Moon';
import MarsAndItsMoons from './Components/Planets/Mars/Mars_And_Its_Moons';
import Mars from './Components/Planets/Mars/Mars';
import Phobos from './Components/Planets/Mars/Phobos.jsx';
import Deimos from './Components/Planets/Mars/Deimos.jsx';
import JupiterAndItsMoons from './Components/Planets/Jupiter/Jupiter_And_Its_Moons.jsx';
import Jupiter from './Components/Planets/Jupiter/Jupiter.jsx';
// ? DrawfPlanets
import DwrafPlanets from './Components/DwrafPlanets/DwrafPlanets.jsx';
// ? CSS
import './css/style.css';
import "./css/tailwind.css";
// ? Images
import logo from './img/logo.png';
import SolarSystem from './img/SolarSystem.gif';
import SolarSystemDated from './img/SolarSystemDated.png';
import LandUseIn2015 from './img/Land-Use.png';
// ? ReactRouter
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header logo = {logo} />
        <main>
          <Switch>
            <Route exact path="/">
              <Home SolarSystem = { SolarSystem } />
            </Route>
            <Route exact path="/about">
              <About/>
            </Route>
            <Route exact path="/planets">
              <Planets/>
            </Route>
            <Route exact path="/dwrafplanets">
              <DwrafPlanets/>
            </Route>
            <Route exact path="/explore">
              <Explore/>
            </Route>
            <Route exact path="/mercury">
              <Mercury/>
            </Route>
            <Route exact path="/venus">
              <Venus/>
            </Route>
            <Route exact path="/earth-and-its-moons">
              <EarthAndItsMoons/>
            </Route>
            <Route exact path="/earth-and-its-moons/earth">
              <Earth SolarSystemDated = {SolarSystemDated} LandUseIn2015 = {LandUseIn2015} />
            </Route>
            <Route exact path="/earth-and-its-moons/moon">
              <Moon />
            </Route>
            <Route exact path="/mars-and-its-moons">
              <MarsAndItsMoons />
            </Route>
            <Route exact path="/mars-and-its-moons/mars">
              <Mars />
            </Route>
            <Route exact path="/mars-and-its-moons/phobos">
              <Phobos />
            </Route>
            <Route exact path="/mars-and-its-moons/deimos">
              <Deimos />
            </Route>
            <Route exact path="/jupiter-and-its-moons">
              <JupiterAndItsMoons />
            </Route>
            <Route exact path="/jupiter-and-its-moons/jupiter">
              <Jupiter />
            </Route>
          </Switch>
        </main>
        <Footer logo = {logo} />
      </Router>
    </>
  );
}

export default App;
