import React from 'react';
import { Link } from "react-router-dom";

export default function Earth_And_Its_Moons() {
    return (
        // ! Earth And Its Moon
        <section className="text-gray-600 body-font">
            <div className="lg:w-1/2 w-full mb-6 m-5 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Earth And Its Moons</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
            <div className="container px-5 py-10 mx-auto">
                <div className="flex flex-wrap -m-4 justify-center">
                    <div className="p-4 md:w-1/3">
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://media.npr.org/assets/img/2013/03/06/bluemarble3k-smaller-nasa_custom-644f0b7082d6d0f6814a9e82908569c07ea55ccb-s800-c85.jpg" alt="blog"/>
                            <div className="p-6">
                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Earth</h1>
                                <p className="leading-relaxed mb-3">Earth is the third planet from the Sun and the only astronomical object known to harbor life. About 29% of Earth's surface is land consisting of continents and islands.</p>
                                <div className="flex items-center flex-wrap">
                                    <Link to="/earth-and-its-moons/earth" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/3">
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://earthsky.org/upl/2018/10/moon-waxing-crescent-earthshine-1-8-2019-Chuck-Reinhart-Vincennes-IN-e1547258318928.jpg" alt="blog"/>
                            <div className="p-6">
                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Moon</h1>
                                <p className="leading-relaxed mb-3">The Moon is Earth's only proper natural satellite. At one-quarter the diameter of Earth, it is the largest natural satellite in the Solar System relative to the size of its planet, and the fifth largest satellite in the Solar System overall.</p>
                                <div className="flex items-center flex-wrap">
                                    <Link to="/moon" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
