import React from 'react';
import { Link } from "react-router-dom";

const Jupiter_And_Its_Moons = () => {
   return (
      // ! Jupiter And Its Moon
      <section className="text-gray-600 body-font">
         <div className="lg:w-1/2 w-full mb-6 m-5 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Jupiter And Its Moons</h1>
            <div className="h-1 w-20 bg-indigo-500 rounded"></div>
         </div>
         <div className="container px-5 py-10 mx-auto">
            <div className="flex flex-wrap -m-4 justify-center">
               <div className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                     <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/2/2b/Jupiter_and_its_shrunken_Great_Red_Spot.jpg" alt="blog" />
                     <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Jupiter</h1>
                        <p className="leading-relaxed mb-3">Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is Link gas giant with Link mass one-thousandth that of the Sun, but two and Link half times that of all the other planets in the Solar System combined.</p>
                        <div className="flex items-center flex-wrap">
                           <Link to="/jupiter-and-its-moons/jupiter" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M5 12h14"></path>
                                 <path d="M12 5l7 7-7 7"></path>
                              </svg>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                     <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Io_highest_resolution_true_color.jpg" alt="blog" />
                     <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Io</h1>
                        <p className="leading-relaxed mb-3">Io, or Jupiter I, is the innermost and third-largest of the four Galilean moons of the planet Jupiter. Slightly larger than the Moon, Io is the fourth-largest moon in the Solar System, has the highest density of all of them, and has the lowest amount of water of any known astronomical object in the Solar System.</p>
                        <div className="flex items-center flex-wrap">
                           <Link to="/jupiter-and-its-moons" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M5 12h14"></path>
                                 <path d="M12 5l7 7-7 7"></path>
                              </svg>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                     <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/e/e4/Europa-moon-with-margins.jpg" alt="blog" />
                     <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Europa</h1>
                        <p className="leading-relaxed mb-3">Europa, or Jupiter II, is the smallest of the four Galilean moons orbiting Jupiter, and the sixth-closest to the planet of all the 79 known moons of Jupiter. It is also the sixth-largest moon in the Solar System.</p>
                        <div className="flex items-center flex-wrap">
                           <Link to="/jupiter-and-its-moons" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M5 12h14"></path>
                                 <path d="M12 5l7 7-7 7"></path>
                              </svg>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                     <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/f/f2/Ganymede_g1_true-edit1.jpg" alt="blog" />
                     <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Ganymede</h1>
                        <p className="leading-relaxed mb-3">Ganymede, a satellite of Jupiter, is the largest and most massive of the Solar System's moons. The ninth-largest object in the Solar System, it is the largest without a substantial atmosphere. It has a diameter of 5,268 km, making it 26% larger than the planet Mercury by volume, although it is only 45% as massive.</p>
                        <div className="flex items-center flex-wrap">
                           <Link to="/jupiter-and-its-moons" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M5 12h14"></path>
                                 <path d="M12 5l7 7-7 7"></path>
                              </svg>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="p-4 md:w-1/3">
                  <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                     <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Callisto.jpg" alt="blog" />
                     <div className="p-6">
                        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Callisto</h1>
                        <p className="leading-relaxed mb-3">Callisto, or Jupiter IV, is the second-largest moon of Jupiter, after Ganymede. It is the third-largest moon in the Solar System after Ganymede and Saturn's largest moon Titan, and the largest object in the Solar System that may not be properly differentiated. Callisto was discovered in 1610 by Galileo Galilei.</p>
                        <div className="flex items-center flex-wrap">
                           <Link to="/jupiter-and-its-moons" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                 <path d="M5 12h14"></path>
                                 <path d="M12 5l7 7-7 7"></path>
                              </svg>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default Jupiter_And_Its_Moons;
