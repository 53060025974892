import React from 'react';
import { Link } from "react-router-dom";

const Mars_And_Its_Moons = () => {
    return (
        // ! Mars And Its Moon
        <section className="text-gray-600 body-font">
            <div className="lg:w-1/2 w-full mb-6 m-5 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Mars And Its Moons</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
            <div className="container px-5 py-10 mx-auto">
                <div className="flex flex-wrap -m-4 justify-center">
                    <div className="p-4 md:w-1/3">
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg" alt="blog"/>
                            <div className="p-6">
                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Mars</h1>
                                <p className="leading-relaxed mb-3">Mars is the fourth planet from the Sun and the second-smallest planet in the Solar System, being larger than only Mercury. In English, Mars carries the name of the Roman god of war and is often referred to as the "Red Planet"</p>
                                <div className="flex items-center flex-wrap">
                                    <Link to="/mars-and-its-moons/mars" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/3">
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Phobos_colour_2008.jpg" alt="blog"/>
                            <div className="p-6">
                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Phobos</h1>
                                <p className="leading-relaxed mb-3">Phobos is the innermost and larger of the two natural satellites of Mars, the other being Deimos. Both moons were discovered in 1877 by American astronomer Asaph Hall. Phobos is named after the Greek god Phobos, a son of Ares and Aphrodite and twin brother of Deimos.</p>
                                <div className="flex items-center flex-wrap">
                                    <Link to="/mars-and-its-moons/phobos" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/3">
                        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://upload.wikimedia.org/wikipedia/commons/8/8d/Deimos-MRO.jpg" alt="blog"/>
                            <div className="p-6">
                                <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Deimos</h1>
                                <p className="leading-relaxed mb-3">Deimos is the smaller and outermost of the two natural satellites of Mars, the other being Phobos. Deimos has a mean radius of 6.2 km and takes 30.3 hours to orbit Mars. Deimos is 23,460 km from Mars, much further than Mars's other moon, Phobos.</p>
                                <div className="flex items-center flex-wrap">
                                    <Link to="/mars-and-its-moons/deimos" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mars_And_Its_Moons
