import React from 'react'

export default function Mercury() {
    return (
        <>
            {/* HeroSection */}
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                  <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <img class="object-cover object-center rounded" alt="Mercury Image" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Mercury_in_color_-_Prockter07-edit1.jpg/1024px-Mercury_in_color_-_Prockter07-edit1.jpg"/>
                  </div>
                  <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Mercury</h1>
                    <p class="mb-8 leading-relaxed">Mercury is the smallest and closest planet to the Sun in the Solar System. Its orbit around the Sun takes 87.97 Earth days, the shortest of all the planets in the Solar System.</p>
                  </div>
                </div>
            </section>

            <hr/>

            {/* ImfoOfMercury */}
            <section class="text-gray-600 body-font">
              <div class="container mx-auto flex px-5 py-10 items-center flex-col">
                {/* Internal Section Of Mercury */}
                <h1 class="title-font sm:text-4xl text-3xl mb-10 font-medium text-gray-900">Internal Section Of Mercury</h1>
                <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Mercury_with_magnetic_field.svg/1920px-Mercury_with_magnetic_field.svg.png"/>
                <div class="lg:w-2/3 w-full">
                  <p>Mercury appears to have a solid silicate crust and mantle overlying a solid, iron sulfide outer core layer, a deeper liquid core layer, and a solid inner core.</p>
                  <br/>
                  <p> Mercury is one of four terrestrial planets in the Solar System, and is a rocky body like Earth. It is the smallest planet in the Solar System, with an equatorial radius of 2,439.7 kilometres (1,516.0 mi). Mercury is also smaller—albeit more massive—than the largest natural satellites in the Solar System, Ganymede and Titan. Mercury consists of approximately 70% metallic and 30% silicate material. Mercury's density is the second highest in the Solar System at 5.427 g/cm3, only slightly less than Earth's density of 5.515 g/cm3. If the effect of gravitational compression were to be factored out from both planets, the materials of which Mercury is made would be denser than those of Earth, with an uncompressed density of 5.3 g/cm3 versus Earth's 4.4 g/cm3</p>
                  <br/>
                  <p>Mercury's density can be used to infer details of its inner structure. Although Earth's high density results appreciably from gravitational compression, particularly at the core, Mercury is much smaller and its inner regions are not as compressed. Therefore, for it to have such a high density, its core must be large and rich in iron.</p>
                  <br/>  
                  <p>Geologists estimate that Mercury's core occupies about 55% of its volume; for Earth this proportion is 17%. Research published in 2007 suggests that Mercury has a molten core. Surrounding the core is a 500–700 km (310–430 mi) mantle consisting of silicates. Based on data from the Mariner 10 mission and Earth-based observation, Mercury's crust is estimated to be 35 km (22 mi) thick. One distinctive feature of Mercury's surface is the presence of numerous narrow ridges, extending up to several hundred kilometers in length. It is thought that these were formed as Mercury's core and mantle cooled and contracted at a time when the crust had already solidified.</p>
                  <br/>  
                  <p>Mercury's core has a higher iron content than that of any other major planet in the Solar System, and several theories have been proposed to explain this. The most widely accepted theory is that Mercury originally had a metal–silicate ratio similar to common chondrite meteorites, thought to be typical of the Solar System's rocky matter, and a mass approximately 2.25 times its current mass. Early in the Solar System's history, Mercury may have been struck by a planetesimal of approximately 1/6 that mass and several thousand kilometers across. The impact would have stripped away much of the original crust and mantle, leaving the core behind as a relatively major component. A similar process, known as the giant impact hypothesis, has been proposed to explain the formation of the Moon.</p>
                  <br/>    
                  <p>Alternatively, Mercury may have formed from the solar nebula before the Sun's energy output had stabilized. It would initially have had twice its present mass, but as the protosun contracted, temperatures near Mercury could have been between 2,500 and 3,500 K and possibly even as high as 10,000 K. Much of Mercury's surface rock could have been vaporized at such temperatures, forming an atmosphere of "rock vapor" that could have been carried away by the solar wind.</p>
                  <br/>    
                  <p>A third hypothesis proposes that the solar nebula caused drag on the particles from which Mercury was accreting, which meant that lighter particles were lost from the accreting material and not gathered by Mercury. Each hypothesis predicts a different surface composition, and there are two space missions set to make observations. MESSENGER, which ended in 2015, found higher-than-expected potassium and sulfur levels on the surface, suggesting that the giant impact hypothesis and vaporization of the crust and mantle did not occur because potassium and sulfur would have been driven off by the extreme heat of these events. BepiColombo, which will arrive at Mercury in 2025, will make observations to test these hypotheses. The findings so far would seem to favor the third hypothesis; however, further analysis of the data is needed.</p>
                </div>

                <hr/>

                {/* Surface Geology Of Mercury */}
                <h1 class="title-font sm:text-4xl text-3xl mt-10 mb-10 font-medium text-gray-900">Surface Geology Of Mercury</h1>
                <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/PIA19420-Mercury-NorthHem-Topography-MLA-Messenger-20150416.jpg/900px-PIA19420-Mercury-NorthHem-Topography-MLA-Messenger-20150416.jpg"/>
                <div class="lg:w-2/3 w-full">
                  <p>Mercury's surface is similar in appearance to that of the Moon, showing extensive mare-like plains and heavy cratering, indicating that it has been geologically inactive for billions of years. Because knowledge of Mercury's geology had been based only on the 1975 Mariner 10 flyby and terrestrial observations, it is the least understood of the terrestrial planets. As data from MESSENGER orbiter are processed, this knowledge will increase. For example, an unusual crater with radiating troughs has been discovered that scientists called "the spider". It was later named Apollodorus.</p>
                  <br/>
                  <p>Albedo features are areas of markedly different reflectivity, as seen by telescopic observation. Mercury has dorsa (also called "wrinkle-ridges"), Moon-like highlands, montes (mountains), planitiae (plains), rupes (escarpments), and valles (valleys)</p>
                  <br/>
                  <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Oblique_Abedin_2015.png/1920px-Oblique_Abedin_2015.png" width="100%"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1 text-center">Interior of Abedin crater</h3>
                </div>
                  <p>Names for features on Mercury come from a variety of sources. Names coming from people are limited to the deceased. Craters are named for artists, musicians, painters, and authors who have made outstanding or fundamental contributions to their field. Ridges, or dorsa, are named for scientists who have contributed to the study of Mercury. Depressions or fossae are named for works of architecture. Montes are named for the word "hot" in a variety of languages. Plains or planitiae are named for Mercury in various languages. Escarpments or rupēs are named for ships of scientific expeditions. Valleys or valles are named for abandoned cities, towns, or settlements of antiquity.</p>
                  <br/>
                  <p>Mercury was heavily bombarded by comets and asteroids during and shortly following its formation 4.6 billion years ago, as well as during a possibly separate subsequent episode called the Late Heavy Bombardment that ended 3.8 billion years ago. During this period of intense crater formation, Mercury received impacts over its entire surface, facilitated by the lack of any atmosphere to slow impactors down. During this time Mercury was volcanically active; basins such as the Caloris Basin were filled by magma, producing smooth plains similar to the maria found on the Moon.</p>
                  <br/>
                  <p>Data from the October 2008 flyby of MESSENGER gave researchers a greater appreciation for the jumbled nature of Mercury's surface. Mercury's surface is more heterogeneous than either Mars's or the Moon's, both of which contain significant stretches of similar geology, such as maria and plateaus</p>
                </div>

                {/* Plains Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Plains Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/The_Mighty_Caloris_%28PIA19213%29.png/1024px-The_Mighty_Caloris_%28PIA19213%29.png" width="450"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Caloris Basin, one of the largest impact basins in the Solar System</h3>
                </div>
                <div class="lg:w-2/3 w-full">
                  <p>There are two geologically distinct plains regions on Mercury. Gently rolling, hilly plains in the regions between craters are Mercury's oldest visible surfaces, predating the heavily cratered terrain. These inter-crater plains appear to have obliterated many earlier craters, and show a general paucity of smaller craters below about 30 km in diameter.</p>
                  <br/>
                  <p>Smooth plains are widespread flat areas that fill depressions of various sizes and bear a strong resemblance to the lunar maria. Notably, they fill a wide ring surrounding the Caloris Basin. Unlike lunar maria, the smooth plains of Mercury have the same albedo as the older inter-crater plains. Despite a lack of unequivocally volcanic characteristics, the localisation and rounded, lobate shape of these plains strongly support volcanic origins. All the smooth plains of Mercury formed significantly later than the Caloris basin, as evidenced by appreciably smaller crater densities than on the Caloris ejecta blanket. The floor of the Caloris Basin is filled by a geologically distinct flat plain, broken up by ridges and fractures in a roughly polygonal pattern. It is not clear whether they are volcanic lavas induced by the impact or a large sheet of impact melt.</p>
                </div>

                {/* Plains Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Plains Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block mb-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Picasso_crater.png/1024px-Picasso_crater.png" width="450"/>
                <div class="lg:w-2/3 w-full">
                  <p>Images obtained by MESSENGER have revealed evidence for pyroclastic flows on Mercury from low-profile shield volcanoes. MESSENGER data has helped identify 51 pyroclastic deposits on the surface, where 90% of them are found within impact craters. A study of the degradation state of the impact craters that host pyroclastic deposits suggests that pyroclastic activity occurred on Mercury over a prolonged interval.</p>
                  <br/>
                  <p>A "rimless depression" inside the southwest rim of the Caloris Basin consists of at least nine overlapping volcanic vents, each individually up to 8 km in diameter. It is thus a "compound volcano". The vent floors are at least 1 km below their brinks and they bear a closer resemblance to volcanic craters sculpted by explosive eruptions or modified by collapse into void spaces created by magma withdrawal back down into a conduit. Scientists could not quantify the age of the volcanic complex system but reported that it could be of the order of a billion years</p>
                </div>

                {/* Compressional Features Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Compressional Features Of Mercury</h2>
                <div class="lg:w-2/3 w-full">
                  <p>One unusual feature of Mercury's surface is the numerous compression folds, or rupes, that crisscross the plains. As Mercury's interior cooled, it contracted and its surface began to deform, creating wrinkle ridges and lobate scarps associated with thrust faults. The scarps can reach lengths of 1000 km and heights of 3 km. These compressional features can be seen on top of other features, such as craters and smooth plains, indicating they are more recent. Mapping of the features has suggested a total shrinkage of Mercury's radius in the range of ~1 to 7 km. Small-scale thrust fault scarps have been found, tens of meters in height and with lengths in the range of a few km, that appear to be less than 50 million years old, indicating that compression of the interior and consequent surface geological activity continue to the present</p>
                  <br/>
                  <p>The Lunar Reconnaissance Orbiter discovered that similar small thrust faults exist on the Moon. </p>
                </div>

                {/* Volcanism Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Volcanism Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Picasso_crater.png/330px-Picasso_crater.png" width="450"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Picasso Crater</h3>
                </div>
                <div class="lg:w-2/3 w-full">
                  <p>Images obtained by MESSENGER have revealed evidence for pyroclastic flows on Mercury from low-profile shield volcanoes. MESSENGER data has helped identify 51 pyroclastic deposits on the surface, where 90% of them are found within impact craters. A study of the degradation state of the impact craters that host pyroclastic deposits suggests that pyroclastic activity occurred on Mercury over a prolonged interval.</p>
                  <br/>
                  <p>A "rimless depression" inside the southwest rim of the Caloris Basin consists of at least nine overlapping volcanic vents, each individually up to 8 km in diameter. It is thus a "compound volcano". The vent floors are at least 1 km below their brinks and they bear a closer resemblance to volcanic craters sculpted by explosive eruptions or modified by collapse into void spaces created by magma withdrawal back down into a conduit. Scientists could not quantify the age of the volcanic complex system but reported that it could be of the order of a billion years.</p>
                </div>

                {/* Surface Conditions & Exosphere Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Surface Conditions & Exosphere Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Mercury_Globe-MESSENGER_mosaic_centered_at_0degN-0degE.jpg/330px-Mercury_Globe-MESSENGER_mosaic_centered_at_0degN-0degE.jpg" width="450"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Composite image of Mercury taken by MESSENGER</h3>
                </div>
                <div class="lg:w-2/3 w-full">
                  <p>The surface temperature of Mercury ranges from 100 to 700 K (−173 to 427 °C; −280 to 800 °F) at the most extreme places: 0°N, 0°W, or 180°W. It never rises above 180 K at the poles, due to the absence of an atmosphere and a steep temperature gradient between the equator and the poles. The subsolar point reaches about 700 K during perihelion (0°W or 180°W), but only 550 K at aphelion (90° or 270°W). On the dark side of the planet, temperatures average 110 K. The intensity of sunlight on Mercury's surface ranges between 4.59 and 10.61 times the solar constant (1,370 W·m<sup>−2</sup>).</p>
                  <br/>
                  <p>Although the daylight temperature at the surface of Mercury is generally extremely high, observations strongly suggest that ice (frozen water) exists on Mercury. The floors of deep craters at the poles are never exposed to direct sunlight, and temperatures there remain below 102 K; far lower than the global average. Water ice strongly reflects radar, and observations by the 70-meter Goldstone Solar System Radar and the VLA in the early 1990s revealed that there are patches of high radar reflection near the poles. Although ice was not the only possible cause of these reflective regions, astronomers think it was the most likely.</p>
                  <br/>
                  <p>The icy regions are estimated to contain about 10<sup>14</sup>–10<sup>15</sup> kg of ice, and may be covered by a layer of regolith that inhibits sublimation. By comparison, the Antarctic ice sheet on Earth has a mass of about 4×10<sup>18</sup> kg, and Mars's south polar cap contains about 10<sup>16</sup> kg of water. The origin of the ice on Mercury is not yet known, but the two most likely sources are from outgassing of water from the planet's interior or deposition by impacts of comets</p>
                  <br/>
                  <p>Mercury is too small and hot for its gravity to retain any significant atmosphere over long periods of time; it does have a tenuous surface-bounded exosphere containing hydrogen, helium, oxygen, sodium, calcium, potassium and others at a surface pressure of less than approximately 0.5 nPa (0.005 picobars). This exosphere is not stable—atoms are continuously lost and replenished from a variety of sources. Hydrogen atoms and helium atoms probably come from the solar wind, diffusing into Mercury's magnetosphere before later escaping back into space. Radioactive decay of elements within Mercury's crust is another source of helium, as well as sodium and potassium. MESSENGER found high proportions of calcium, helium, hydroxide, magnesium, oxygen, potassium, silicon and sodium. Water vapor is present, released by a combination of processes such as: comets striking its surface, sputtering creating water out of hydrogen from the solar wind and oxygen from rock, and sublimation from reservoirs of water ice in the permanently shadowed polar craters. The detection of high amounts of water-related ions like O<sup>+</sup>, OH<sup>−</sup>, and H3O<sup>+</sup> was a surprise. Because of the quantities of these ions that were detected in Mercury's space environment, scientists surmise that these molecules were blasted from the surface or exosphere by the solar wind.</p>
                  <br/>
                  <p>Sodium, potassium and calcium were discovered in the atmosphere during the 1980–1990s, and are thought to result primarily from the vaporization of surface rock struck by micrometeorite impacts including presently from Comet Encke In 2008, magnesium was discovered by MESSENGER. Studies indicate that, at times, sodium emissions are localized at points that correspond to the planet's magnetic poles. This would indicate an interaction between the magnetosphere and the planet's surface.</p>
                  <br/>
                  <p>On November 29, 2012, NASA confirmed that images from MESSENGER had detected that craters at the north pole contained water ice. MESSENGER's principal investigator Sean Solomon is quoted in The New York Times estimating the volume of the ice to be large enough to "encase Washington, D.C., in a frozen block two and a half miles deep".</p>
                </div>
                
                {/* Magnetic Field & Magnetosphere Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Magnetic Field & Magnetosphere Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Mercury_Magnetic_Field_NASA.jpg/330px-Mercury_Magnetic_Field_NASA.jpg" width="450"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Graph showing relative strength of Mercury's magnetic field</h3>
                </div>
                <div class="lg:w-2/3 w-full">
                  <p>Despite its small size and slow 59-day-long rotation, Mercury has a significant, and apparently global, magnetic field. According to measurements taken by Mariner 10, it is about 1.1% the strength of Earth's. The magnetic-field strength at Mercury's equator is about 300 nT. Like that of Earth, Mercury's magnetic field is dipolar. Unlike Earth's, Mercury's poles are nearly aligned with the planet's spin axis. Measurements from both the Mariner 10 and MESSENGER space probes have indicated that the strength and shape of the magnetic field are stable.</p>
                  <br/>
                  <p>It is likely that this magnetic field is generated by a dynamo effect, in a manner similar to the magnetic field of Earth. This dynamo effect would result from the circulation of the planet's iron-rich liquid core. Particularly strong tidal effects caused by the planet's high orbital eccentricity would serve to keep the core in the liquid state necessary for this dynamo effect.</p>
                  <br/>
                  <p>Mercury's magnetic field is strong enough to deflect the solar wind around the planet, creating a magnetosphere. The planet's magnetosphere, though small enough to fit within Earth, is strong enough to trap solar wind plasma. This contributes to the space weathering of the planet's surface. Observations taken by the Mariner 10 spacecraft detected this low energy plasma in the magnetosphere of the planet's nightside. Bursts of energetic particles in the planet's magnetotail indicate a dynamic quality to the planet's magnetosphere.</p>
                  <br/>
                  <p>During its second flyby of the planet on October 6, 2008, MESSENGER discovered that Mercury's magnetic field can be extremely "leaky". The spacecraft encountered magnetic "tornadoes" – twisted bundles of magnetic fields connecting the planetary magnetic field to interplanetary space – that were up to 800 km wide or a third of the radius of the planet. These twisted magnetic flux tubes, technically known as flux transfer events, form open windows in the planet's magnetic shield through which the solar wind may enter and directly impact Mercury's surface via magnetic reconnection This also occurs in Earth's magnetic field. The MESSENGER observations showed the reconnection rate is ten times higher at Mercury, but its proximity to the Sun only accounts for about a third of the reconnection rate observed by MESSENGER.</p>
                </div>

                <hr/>

                {/* Orbit, Rotation, & Longitude Of Mercury */}
                <h1 class="title-font sm:text-4xl text-3xl mt-10 mb-10 font-medium text-gray-900">Orbit, Rotation, & Longitude Of Mercury</h1>
                <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Mercuryorbitsolarsystem.gif"/>
                <div class="lg:w-2/3 w-full">
                  <p>Mercury has the most eccentric orbit of all the planets in the Solar System; its eccentricity is 0.21 with its distance from the Sun ranging from 46,000,000 to 70,000,000 km (29,000,000 to 43,000,000 mi). It takes 87.969 Earth days to complete an orbit. The diagram illustrates the effects of the eccentricity, showing Mercury's orbit overlaid with a circular orbit having the same semi-major axis. Mercury's higher velocity when it is near perihelion is clear from the greater distance it covers in each 5-day interval. In the diagram, the varying distance of Mercury to the Sun is represented by the size of the planet, which is inversely proportional to Mercury's distance from the Sun. This varying distance to the Sun leads to Mercury's surface being flexed by tidal bulges raised by the Sun that are about 17 times stronger than the Moon's on Earth. Combined with a 3:2 spin–orbit resonance of the planet's rotation around its axis, it also results in complex variations of the surface temperature. The resonance makes a single solar day on Mercury last exactly two Mercury years, or about 176 Earth days.</p>
                  <br/>
                  <p>Mercury's orbit is inclined by 7 degrees to the plane of Earth's orbit (the ecliptic), as shown in the diagram on the right. As a result, transits of Mercury across the face of the Sun can only occur when the planet is crossing the plane of the ecliptic at the time it lies between Earth and the Sun, which is in May or November. This occurs about every seven years on average.</p>
                  <br/>
                  <p>Mercury's axial tilt is almost zero, with the best measured value as low as 0.027 degrees. This is significantly smaller than that of Jupiter, which has the second smallest axial tilt of all planets at 3.1 degrees. This means that to an observer at Mercury's poles, the center of the Sun never rises more than 2.1 arcminutes above the horizon.</p>
                  <br/>
                  <p>Mercury's orbit is inclined by 7 degrees to the plane of Earth's orbit (the ecliptic), as shown in the diagram on the right. As a result, transits of Mercury across the face of the Sun can only occur when the planet is crossing the plane of the ecliptic at the time it lies between Earth and the Sun, which is in May or November. This occurs about every seven years on average.</p>
                  <br/>
                  <p>For the same reason, there are two points on Mercury's equator, 180 degrees apart in longitude, at either of which, around perihelion in alternate Mercurian years (once a Mercurian day), the Sun passes overhead, then reverses its apparent motion and passes overhead again, then reverses a second time and passes overhead a third time, taking a total of about 16 Earth-days for this entire process. In the other alternate Mercurian years, the same thing happens at the other of these two points. The amplitude of the retrograde motion is small, so the overall effect is that, for two or three weeks, the Sun is almost stationary overhead, and is at its most brilliant because Mercury is at perihelion, its closest to the Sun. This prolonged exposure to the Sun at its brightest makes these two points the hottest places on Mercury. Maximum temperature occurs when the Sun is at an angle of about 25 degrees past noon due to diurnal temperature lag, at 0.4 Mercury days and 0.8 Mercury years past sunrise. Conversely, there are two other points on the equator, 90 degrees of longitude apart from the first ones, where the Sun passes overhead only when the planet is at aphelion in alternate years, when the apparent motion of the Sun in Mercury's sky is relatively rapid. These points, which are the ones on the equator where the apparent retrograde motion of the Sun happens when it is crossing the horizon as described in the preceding paragraph, receive much less solar heat than the first ones described above.</p>
                  <br/>
                  <p>Mercury attains inferior conjunction (nearest approach to Earth) every 116 Earth days on average, but this interval can range from 105 days to 129 days due to the planet's eccentric orbit. Mercury can come as near as 82.2 gigametres (0.549 astronomical units; 51.1 million miles) to Earth, and that is slowly declining: The next approach to within 82.1 Gm (51.0 million miles) is in 2679, and to within 82.0 Gm (51.0 million miles) in 4487, but it will not be closer to Earth than 80 Gm (50 million miles) until 28,622.[96] Its period of retrograde motion as seen from Earth can vary from 8 to 15 days on either side of inferior conjunction. This large range arises from the planet's high orbital eccentricity. Essentially because Mercury is closest to the Sun, when taking an average over time, Mercury is the closest planet to the Earth, and - in that measure - it is the closest planet to each of the other planets in the Solar System</p>
                </div>

                {/* Longitude Convention Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Longitude Convention Of Mercury</h2>
                <div class="lg:w-2/3 w-full">
                  <p>The longitude convention for Mercury puts the zero of longitude at one of the two hottest points on the surface, as described above. However, when this area was first visited, by Mariner 10, this zero meridian was in darkness, so it was impossible to select a feature on the surface to define the exact position of the meridian. Therefore, a small crater further west was chosen, called Hun Kal, which provides the exact reference point for measuring longitude. The center of Hun Kal defines the 20° west meridian. A 1970 International Astronomical Union resolution suggests that longitudes be measured positively in the westerly direction on Mercury. The two hottest places on the equator are therefore at longitudes 0° W and 180° W, and the coolest points on the equator are at longitudes 90° W and 270° W. However, the MESSENGER project uses an east-positive convention.</p>
                </div>

                {/* Spin-Orbit Resonance Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Spin-Orbit Resonance Of Mercury</h2>
                <img alt="ecommerce" class="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Mercury%27s_orbital_resonance.svg/800px-Mercury%27s_orbital_resonance.svg.png" width="450"/>
                <div class="mt-4 mb-5">
                  <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">After one orbit, Mercury has rotated 1.5 times, so after two complete orbits the same hemisphere is again illuminated.</h3>
                </div>
                <div class="lg:w-2/3 w-full">
                  <p>For many years it was thought that Mercury was synchronously tidally locked with the Sun, rotating once for each orbit and always keeping the same face directed towards the Sun, in the same way that the same side of the Moon always faces Earth. Radar observations in 1965 proved that the planet has a 3:2 spin-orbit resonance, rotating three times for every two revolutions around the Sun. The eccentricity of Mercury's orbit makes this resonance stable—at perihelion, when the solar tide is strongest, the Sun is nearly still in Mercury's sky.</p>
                  <br/>
                  <p>The rare 3:2 resonant tidal locking is stabilized by the variance of the tidal force along Mercury's eccentric orbit, acting on a permanent dipole component of Mercury's mass distribution. In a circular orbit there is no such variance, so the only resonance stabilized in such an orbit is at 1:1 (e.g., Earth–Moon), when the tidal force, stretching a body along the "center-body" line, exerts a torque that aligns the body's axis of least inertia (the "longest" axis, and the axis of the aforementioned dipole) to point always at the center. However, with noticeable eccentricity, like that of Mercury's orbit, the tidal force has a maximum at perihelion and therefore stabilizes resonances, like 3:2, enforcing that the planet points its axis of least inertia roughly at the Sun when passing through perihelion.</p>
                  <br/>
                  <p>The original reason astronomers thought it was synchronously locked was that, whenever Mercury was best placed for observation, it was always nearly at the same point in its 3:2 resonance, hence showing the same face. This is because, coincidentally, Mercury's rotation period is almost exactly half of its synodic period with respect to Earth. Due to Mercury's 3:2 spin-orbit resonance, a solar day (the length between two meridian transits of the Sun) lasts about 176 Earth days. A sidereal day (the period of rotation) lasts about 58.7 Earth days.</p>
                  <br/>
                  <p>Simulations indicate that the orbital eccentricity of Mercury varies chaotically from nearly zero (circular) to more than 0.45 over millions of years due to perturbations from the other planets. This was thought to explain Mercury's 3:2 spin-orbit resonance (rather than the more usual 1:1), because this state is more likely to arise during a period of high eccentricity. However, accurate modeling based on a realistic model of tidal response has demonstrated that Mercury was captured into the 3:2 spin-orbit state at a very early stage of its history, within 20 (more likely, 10) million years after its formation.</p>
                  <br/>
                  <p>Numerical simulations show that a future secular orbital resonant perihelion interaction with Jupiter may cause the eccentricity of Mercury's orbit to increase to the point where there is a 1% chance that the planet will collide with Venus within the next five billion years.</p>
                </div>

                {/* Advance Of Perihelion Of Mercury */}
                <h2 class="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Advance Of Perihelion Of Mercury</h2>
                <div class="lg:w-2/3 w-full">
                  <p>In 1859, the French mathematician and astronomer Urbain Le Verrier reported that the slow precession of Mercury's orbit around the Sun could not be completely explained by Newtonian mechanics and perturbations by the known planets. He suggested, among possible explanations, that another planet (or perhaps instead a series of smaller 'corpuscules') might exist in an orbit even closer to the Sun than that of Mercury, to account for this perturbation. (Other explanations considered included a slight oblateness of the Sun.) The success of the search for Neptune based on its perturbations of the orbit of Uranus led astronomers to place faith in this possible explanation, and the hypothetical planet was named Vulcan, but no such planet was ever found.</p>
                  <br/>
                  <p>The perihelion precession of Mercury is 5,600 arcseconds (1.5556°) per century relative to Earth, or 574.10±0.65 arcseconds per century relative to the inertial ICRF. Newtonian mechanics, taking into account all the effects from the other planets, predicts a precession of 5,557 arcseconds (1.5436°) per century. In the early 20th century, Albert Einstein's general theory of relativity provided the explanation for the observed precession, by formalizing gravitation as being mediated by the curvature of spacetime. The effect is small: just 42.98 arcseconds per century for Mercury; it therefore requires a little over twelve million orbits for a full excess turn. Similar, but much smaller, effects exist for other Solar System bodies: 8.62 arcseconds per century for Venus, 3.84 for Earth, 1.35 for Mars, and 10.05 for 1566 Icarus.</p>
                  <br/>
                  <p>Einstein's formula for the perihelion shift per revolution is <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/6f5b4d3e3871278f6cb166971012c92cd016cfaf" alt="formula" class="inline-flex"/>, where <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/cd253103f0876afc68ebead27a5aa9867d927467" alt="e" class="inline-flex"/> is the orbital eccentricity, <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/ffd2487510aa438433a2579450ab2b3d557e5edc" alt="e" class="inline-flex"/> the semi-major axis, and <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/ec7200acd984a1d3a3d7dc455e262fbe54f7f6e0" alt="e" class="inline-flex"/> the orbital period. Filling in the values gives a result of 0.1035 arcseconds per revolution or 0.4297 arcseconds per Earth year, i.e., 42.97 arcseconds per century. This is in close agreement with the accepted value of Mercury's perihelion advance of 42.98 arcseconds per century.</p>
                </div>
              </div>
            </section>
        </>
    )
}
