import React from 'react';
import { Link } from "react-router-dom";

export default function DwrafPlanets() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 mx-auto">
              <div>
                <div className="py-6 mb-12">
                  <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Dwraf Planets</h1>
                  <div className="h-1 w-20 bg-indigo-500 rounded mt-1"></div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center sm:-m-4 -mx-4 -mb-10 -mt-4">
                <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                  <div className="rounded-lg h-64 overflow-hidden">
                    <image alt="Pluto Image" className="object-cover object-center h-full w-full" src="https://specials-images.forbesimg.com/imageserve/5d72a5675b52ce00088287fb/960x0.jpg?fit=scale"/>
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Pluto</h2>
                  <p className="text-base leading-relaxed mt-2">Pluto is Link dwarf planet in the Kuiper belt, Link ring of bodies beyond the orbit of Neptune. It was the first and the largest Kuiper belt object to be discovered. After Pluto was discovered in 1930, it was declared to be the ninth planet from the Sun.</p>
                  <Link to="/pluto" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
                <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                  <div className="rounded-lg h-64 overflow-hidden">
                    <image alt="Eris Image" className="object-cover object-center h-full w-full" src="https://www.spaceexploration92.com/wp-content/uploads/2020/01/IMG_20191130_210142-1024x707.jpg"/>
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Eris</h2>
                  <p className="text-base leading-relaxed mt-2">Eris is the most massive and second-largest known dwarf planet in the Solar System. Eris is Link trans-Neptunian object, has Link high-eccentricity orbit, and is Link member of the scattered disk.</p>
                  <Link to="/eris" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
                <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                  <div className="rounded-lg h-64 overflow-hidden">
                    <image alt="Ceres Image" className="object-cover object-center h-full w-full" src="https://solarsystem.nasa.gov/system/stellar_items/image_files/704_feature_1600x900_ceres.jpg"/>
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Ceres</h2>
                  <p className="text-base leading-relaxed mt-2">Ceres is the smallest recognized dwarf planet, the closest dwarf planet to the Sun, and the largest object in the main asteroid belt that lies between the orbits of Mars and Jupiter. With Link diameter of 940 km, Ceres is both the largest of the asteroids and the only recognized dwarf planet inside Neptune's orbit.</p>
                  <Link to="/ceres" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
                <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                  <div className="rounded-lg h-64 overflow-hidden">
                    <image alt="Makemake Image" className="object-cover object-center h-full w-full" src="https://cdn.mos.cms.futurecdn.net/8QBGiy9BhmttKdGeAAZpn8-320-80.jpg"/>
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Makemake</h2>
                  <p className="text-base leading-relaxed mt-2">Makemake is Link likely dwarf planet and perhaps the second largest Kuiper belt object in the classical population, with Link diameter approximately two-thirds that of Pluto. Makemake has one known satellite.</p>
                  <Link to="/makemake" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
                <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                  <div className="rounded-lg h-64 overflow-hidden">
                    <image alt="Haumea Image" className="object-cover object-center h-full w-full" src="https://solarsystem.nasa.gov/system/stellar_items/image_files/723_feature_1600x900_haumea.jpg"/>
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Haumea</h2>
                  <p className="text-base leading-relaxed mt-2">Haumea is Link dwarf planet located beyond Neptune's orbit. It was discovered in 2004 by Link team headed by Mike Brown of Caltech at the Palomar Observatory in the United States and independently in 2005 by Link team headed by José Luis Ortiz Moreno at the Sierra Nevada Observatory in Spain</p>
                  <Link to="/haumea" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
        </section>
    )
}
