import React from 'react';

export default function Mars() {
  return (
    <>
      {/* HeroSection */}
      <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img className="object-cover object-center rounded" alt="Mars Image" src="https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg"/>
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Mars</h1>
              <p className="mb-8 leading-relaxed">Mars is the fourth planet from the Sun and the second-smallest planet in the Solar System, being larger than only Mercury. In English, Mars carries the name of the Roman god of war and is often referred to as the "Red Planet"</p>
            </div>
          </div>
      </section>
      
      <hr />
      
      {/* ImfoOfMars */}
      <section className="text-gray-600 body-font">
        {/* Physical Characteristics Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Physical Characteristics Of Mars</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Mars%2C_Earth_size_comparison.jpg/1280px-Mars%2C_Earth_size_comparison.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Comparison: Earth and Mars</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Mars is approximately half the diameter of Earth, with a surface area only slightly less than the total area of Earth's dry land. Mars is less dense than Earth, having about 15% of Earth's volume and 11% of Earth's mass, resulting in about 38% of Earth's surface gravity. The red-orange appearance of the Martian surface is caused by iron(III) oxide, or rust. It can look like butterscotch; other common surface colors include golden, brown, tan, and greenish, depending on the minerals present.</p>
          </div>
          {/* Internal Structure Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Internal Structure Of Mars</h2>
          <div className="lg:w-2/3 w-full">
            <p>Like Earth, Mars has differentiated into a dense metallic core overlaid by less dense materials. Scientists initially determined that the core is at least partially liquid. Current models of its interior imply a core with a radius of about 1,794 ± 65 kilometres (1,115 ± 40 mi), consisting primarily of iron and nickel with about 16–17% sulfur. This iron(II) sulfide core is thought to be twice as rich in lighter elements as Earth's. The core is surrounded by a silicate mantle that formed many of the tectonic and volcanic features on the planet, but it appears to be dormant. Besides silicon and oxygen, the most abundant elements in the Martian crust are iron, magnesium, aluminium, calcium, and potassium. The average thickness of the planet's crust is about 50 kilometres (31 mi), with a maximum thickness of 125 kilometres (78 mi). Earth's crust averages 40 kilometres (25 mi).</p>
            <br/>
            <p>Mars is seismically active, with InSight recording over 450 marsquakes and related events in 2019. In March 2021, NASA reported, based on measurements of over 500 Marsquakes by the InSight lander on the planet Mars, that the core of Mars is between 1,810 and 1,860 km (1,120 and 1,160 mi), about half the size of the core of Earth, and significantly smaller — suggesting a core of lighter elements — than thought earlier.</p>
          </div>
          {/* Surface Geologyy Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Surface Geologyy Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Mars-topo-200-fast.gif" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The topographic map of Mars</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Mars is a terrestrial planet whose surface consists of minerals containing silicon and oxygen, metals, and other elements that typically make up rock. The Martian surface is primarily composed of tholeiitic basalt, although parts are more silica-rich than typical basalt and may be similar to andesitic rocks on Earth, or silica glass. Regions of low albedo suggest concentrations of plagioclase feldspar, with northern low albedo regions displaying higher than normal concentrations of sheet silicates and high-silicon glass. Parts of the southern highlands include detectable amounts of high-calcium pyroxenes. Localized concentrations of hematite and olivine have been found. Much of the surface is deeply covered by finely grained iron(III) oxide dust.</p>
            <br/>
            <p>Although Mars has no evidence of a structured global magnetic field, observations show that parts of the planet's crust have been magnetized, suggesting that alternating polarity reversals of its dipole field have occurred in the past. This paleomagnetism of magnetically susceptible minerals is similar to the alternating bands found on Earth's ocean floors. One theory, published in 1999 and re-examined in October 2005 (with the help of the Mars Global Surveyor), is that these bands suggest plate tectonic activity on Mars four billion years ago, before the planetary dynamo ceased to function and the planet's magnetic field faded.</p>
            <br/>
            <p>It is thought that, during the Solar System's formation, Mars was created as the result of a stochastic process of run-away accretion of material from the protoplanetary disk that orbited the Sun. Mars has many distinctive chemical features caused by its position in the Solar System. Elements with comparatively low boiling points, such as chlorine, phosphorus, and sulphur, are much more common on Mars than Earth; these elements were probably pushed outward by the young Sun's energetic solar wind.</p>
            <br/>
            <p>After the formation of the planets, all were subjected to the so-called "Late Heavy Bombardment". About 60% of the surface of Mars shows a record of impacts from that era, whereas much of the remaining surface is probably underlain by immense impact basins caused by those events. There is evidence of an enormous impact basin in the Northern Hemisphere of Mars, spanning 10,600 by 8,500 kilometres (6,600 by 5,300 mi), or roughly four times the size of the Moon's South Pole – Aitken basin, the largest impact basin yet discovered. This theory suggests that Mars was struck by a Pluto-sized body about four billion years ago. The event, thought to be the cause of the Martian hemispheric dichotomy, created the smooth Borealis basin that covers 40% of the planet.</p>
            <br />
            <p>The geological history of Mars can be split into many periods, but the following are the three primary periods:
              <li className="pl-5 mb-2">Noachian period (named after Noachis Terra): Formation of the oldest extant surfaces of Mars, 4.5 to 3.5 billion years ago. Noachian age surfaces are scarred by many large impact craters. The Tharsis bulge, a volcanic upland, is thought to have formed during this period, with extensive flooding by liquid water late in the period.</li>
              <li className="pl-5 mb-2">Hesperian period (named after Hesperia Planum): 3.5 to between 3.3 and 2.9 billion years ago. The Hesperian period is marked by the formation of extensive lava plains.</li>
              <li className="pl-5 mb-2">Amazonian period (named after Amazonis Planitia): between 3.3 and 2.9 billion years ago to the present. Amazonian regions have few meteorite impact craters but are otherwise quite varied. Olympus Mons formed during this period, with lava flows elsewhere on Mars.</li>
            </p>
          </div>
          {/* Soil Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Soil Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block mb-3" src="https://upload.wikimedia.org/wikipedia/commons/d/dd/Spirit_Mars_Silica_April_20_2007.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Exposure of silica-rich dust uncovered by the Spirit rover</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The Phoenix lander returned data showing Martian soil to be slightly alkaline and containing elements such as magnesium, sodium, potassium and chlorine. These nutrients are found in soils on Earth, and they are necessary for growth of plants. Experiments performed by the lander showed that the Martian soil has a basic pH of 7.7, and contains 0.6% of the salt perchlorate. This is a very high concentration and makes the Martian soil toxic (see also Martian soil toxicity).</p>
            <br/>
            <p>Streaks are common across Mars and new ones appear frequently on steep slopes of craters, troughs, and valleys. The streaks are dark at first and get lighter with age. The streaks can start in a tiny area, then spread out for hundreds of metres. They have been seen to follow the edges of boulders and other obstacles in their path. The commonly accepted theories include that they are dark underlying layers of soil revealed after avalanches of bright dust or dust devils. Several other explanations have been put forward, including those that involve water or even the growth of organisms.</p>
          </div>
          {/* Hydrology Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Hydrology Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/2/29/Nasa_mars_opportunity_rock_water_150_eng_02mar04.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Photomicrograph by Opportunity showing a gray hematite concretion, nicknamed "blueberries", indicative of the past existence of liquid water.</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Liquid water cannot exist on the surface of Mars due to low atmospheric pressure, which is less than 1% that of Earth's, except at the lowest elevations for short periods. The two polar ice caps appear to be made largely of water. The volume of water ice in the south polar ice cap, if melted, would be sufficient to cover the entire planetary surface to a depth of 11 metres (36 ft). A permafrost mantle stretches from the pole to latitudes of about 60°. Large quantities of ice are thought to be trapped within the thick cryosphere of Mars. Radar data from Mars Express and the Mars Reconnaissance Orbiter (MRO) show large quantities of ice at both poles (July 2005) and at middle latitudes (November 2008). The Phoenix lander directly sampled water ice in shallow Martian soil on 31 July 2008.</p>
            <br/>
            <p>Landforms visible on Mars strongly suggest that liquid water has existed on the planet's surface. Huge linear swathes of scoured ground, known as outflow channels, cut across the surface in about 25 places. These are thought to be a record of erosion caused by the catastrophic release of water from subsurface aquifers, though some of these structures have been hypothesized to result from the action of glaciers or lava. One of the larger examples, Ma'adim Vallis is 700 kilometres (430 mi) long, much greater than the Grand Canyon, with a width of 20 kilometres (12 mi) and a depth of 2 kilometres (1.2 mi) in places. It is thought to have been carved by flowing water early in Mars's history. The youngest of these channels are thought to have formed as recently as only a few million years ago.[106] Elsewhere, particularly on the oldest areas of the Martian surface, finer-scale, dendritic networks of valleys are spread across significant proportions of the landscape. Features of these valleys and their distribution strongly imply that they were carved by runoff resulting from precipitation in early Mars history. Subsurface water flow and groundwater sapping may play important subsidiary roles in some networks, but precipitation was probably the root cause of the incision in almost all cases.</p>
            <br />
            <p>Along crater and canyon walls, there are thousands of features that appear similar to terrestrial gullies. The gullies tend to be in the highlands of the Southern Hemisphere and to face the Equator; all are poleward of 30° latitude. A number of authors have suggested that their formation process involves liquid water, probably from melting ice, although others have argued for formation mechanisms involving carbon dioxide frost or the movement of dry dust. No partially degraded gullies have formed by weathering and no superimposed impact craters have been observed, indicating that these are young features, possibly still active. Other geological features, such as deltas and alluvial fans preserved in craters, are further evidence for warmer, wetter conditions at an interval or intervals in earlier Mars history. Such conditions necessarily require the widespread presence of crater lakes across a large proportion of the surface, for which there is independent mineralogical, sedimentological and geomorphological evidence.</p>
            <br />
            <p>Further evidence that liquid water once existed on the surface of Mars comes from the detection of specific minerals such as hematite and goethite, both of which sometimes form in the presence of water. In 2004, Opportunity detected the mineral jarosite. This forms only in the presence of acidic water, which demonstrates that water once existed on Mars. More recent evidence for liquid water comes from the finding of the mineral gypsum on the surface by NASA's Mars rover Opportunity in December 2011. It is estimated that the amount of water in the upper mantle of Mars, represented by hydroxyl ions contained within the minerals of Mars's geology, is equal to or greater than that of Earth at 50–300 parts per million of water, which is enough to cover the entire planet to a depth of 200–1,000 metres (660–3,280 ft).</p>
            <br />
            <p>n 2005, radar data revealed the presence of large quantities of water ice at the poles and at mid-latitudes. The Mars rover Spirit sampled chemical compounds containing water molecules in March 2007.</p>
            <br />
            <p>On 18 March 2013, NASA reported evidence from instruments on the Curiosity rover of mineral hydration, likely hydrated calcium sulfate, in several rock samples including the broken fragments of "Tintina" rock and "Sutton Inlier" rock as well as in veins and nodules in other rocks like "Knorr" rock and "Wernicke" rock. Analysis using the rover's DAN instrument provided evidence of subsurface water, amounting to as much as 4% water content, down to a depth of 60 centimetres (24 in), during the rover's traverse from the Bradbury Landing site to the Yellowknife Bay area in the Glenelg terrain. In September 2015, NASA announced that they had found conclusive evidence of hydrated brine flows on recurring slope lineae, based on spectrometer readings of the darkened areas of slopes. These observations provided confirmation of earlier hypotheses based on timing of formation and their rate of growth, that these dark streaks resulted from water flowing in the very shallow subsurface. The streaks contain hydrated salts, perchlorates, which have water molecules in their crystal structure. The streaks flow downhill in Martian summer, when the temperature is above −23° Celsius, and freeze at lower temperatures.</p>
            <br />
            <p>Researchers suspect that much of the low northern plains of the planet were covered with an ocean hundreds of meters deep, though this remains controversial. In March 2015, scientists stated that such an ocean might have been the size of Earth's Arctic Ocean. This finding was derived from the ratio of water to deuterium in the modern Martian atmosphere compared to that ratio on Earth. The amount of Martian deuterium is eight times the amount that exists on Earth, suggesting that ancient Mars had significantly higher levels of water. Results from the Curiosity rover had previously found a high ratio of deuterium in Gale Crater, though not significantly high enough to suggest the former presence of an ocean. Other scientists caution that these results have not been confirmed, and point out that Martian climate models have not yet shown that the planet was warm enough in the past to support bodies of liquid water.</p>
            <br />
            <p>Near the northern polar cap is the 81.4 kilometres (50.6 mi) wide Korolev Crater, where the Mars Express orbiter found it to be filled with approximately 2,200 cubic kilometres (530 cu mi) of water ice. The crater floor lies about 2 kilometres (1.2 mi) below the rim, and is covered by a 1.8 kilometres (1.1 mi) deep central mound of permanent water ice, up to 60 kilometres (37 mi) in diameter.</p>
            <br />
            <p>In February 2020, it was found that dark streaks called recurring slope lineae (RSL), which appear seasonably, are caused by briny water flowing for a few days annually.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/7/75/Perspective_view_of_Korolev_crater.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Perspective view of Korolev crater shows 1.9 kilometres (1.2 mi) deep water ice. Image taken by ESA's Mars Express.</h3>
          </div>
          {/* Polar Caps Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Polar Caps Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Martian_north_polar_cap.jpg/1024px-Martian_north_polar_cap.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">North polar early summer water ice cap (1999); a seasonal layer of carbon dioxide ice forms in winter and disappears in summer.</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Mars has two permanent polar ice caps. During a pole's winter, it lies in continuous darkness, chilling the surface and causing the deposition of 25–30% of the atmosphere into slabs of CO2 ice (dry ice). When the poles are again exposed to sunlight, the frozen CO2 sublimes. These seasonal actions transport large amounts of dust and water vapor, giving rise to Earth-like frost and large cirrus clouds. Clouds of water-ice were photographed by the Opportunity rover in 2004.</p>
            <br/>
            <p>The caps at both poles consist primarily (70%) of water ice. Frozen carbon dioxide accumulates as a comparatively thin layer about one metre thick on the north cap in the northern winter only, whereas the south cap has a permanent dry ice cover about eight metres thick. This permanent dry ice cover at the south pole is peppered by flat floored, shallow, roughly circular pits, which repeat imaging shows are expanding by meters per year; this suggests that the permanent CO2 cover over the south pole water ice is degrading over time. The northern polar cap has a diameter of about 1,000 kilometres (620 mi) during the northern Mars summer, and contains about 1.6 million cubic kilometres (5.7×1016 cu ft) of ice, which, if spread evenly on the cap, would be 2 kilometres (1.2 mi) thick. (This compares to a volume of 2.85 million cubic kilometres (1.01×1017 cu ft) for the Greenland ice sheet.) The southern polar cap has a diameter of 350 kilometres (220 mi) and a thickness of 3 kilometres (1.9 mi). The total volume of ice in the south polar cap plus the adjacent layered deposits has been estimated at 1.6 million cubic km. Both polar caps show spiral troughs, which recent analysis of SHARAD ice penetrating radar has shown are a result of katabatic winds that spiral due to the Coriolis Effect.</p>
            <br/>
            <p>The seasonal frosting of areas near the southern ice cap results in the formation of transparent 1-metre-thick slabs of dry ice above the ground. With the arrival of spring, sunlight warms the subsurface and pressure from subliming CO2 builds up under a slab, elevating and ultimately rupturing it. This leads to geyser-like eruptions of CO2 gas mixed with dark basaltic sand or dust. This process is rapid, observed happening in the space of a few days, weeks or months, a rate of change rather unusual in geology – especially for Mars. The gas rushing underneath a slab to the site of a geyser carves a spiderweb-like pattern of radial channels under the ice, the process being the inverted equivalent of an erosion network formed by water draining through a single plughole.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center h-full block mt-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/South_Polar_Cap_of_Mars_during_Martian_South_summer_2000.jpg/1280px-South_Polar_Cap_of_Mars_during_Martian_South_summer_2000.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">South polar midsummer ice cap (2000); the south cap has a permanent carbon dioxide ice cap mixed with water ice.</h3>
          </div>
          {/* Geography & Naming Of Surface Features Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Geography & Naming Of Surface Features Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Mars_topography_%28MOLA_dataset%29_with_poles_HiRes.jpg/1920px-Mars_topography_%28MOLA_dataset%29_with_poles_HiRes.jpg" width="650"/>
          <div className="lg:w-2/3 w-full">
            <p>Although better remembered for mapping the Moon, Johann Heinrich Mädler and Wilhelm Beer were the first areographers. They began by establishing that most of Mars's surface features were permanent and by more precisely determining the planet's rotation period. In 1840, Mädler combined ten years of observations and drew the first map of Mars. Rather than giving names to the various markings, Beer and Mädler simply designated them with letters; Meridian Bay (Sinus Meridiani) was thus feature "a".</p>
            <br/>
            <p>Today, features on Mars are named from a variety of sources. Albedo features are named for classical mythology. Craters larger than 60 km are named for deceased scientists and writers and others who have contributed to the study of Mars. Craters smaller than 60 km are named for towns and villages of the world with populations of less than 100,000. Large valleys are named for the word "Mars" or "star" in various languages; small valleys are named for rivers.</p>
            <br/>
            <p>Large albedo features retain many of the older names but are often updated to reflect new knowledge of the nature of the features. For example, Nix Olympica (the snows of Olympus) has become Olympus Mons (Mount Olympus). The surface of Mars as seen from Earth is divided into two kinds of areas, with differing albedo. The paler plains covered with dust and sand rich in reddish iron oxides were once thought of as Martian "continents" and given names like Arabia Terra (land of Arabia) or Amazonis Planitia (Amazonian plain). The dark features were thought to be seas, hence their names Mare Erythraeum, Mare Sirenum and Aurorae Sinus. The largest dark feature seen from Earth is Syrtis Major Planum. The permanent northern polar ice cap is named Planum Boreum, whereas the southern cap is called Planum Australe.</p>
            <br />
            <p>Mars's equator is defined by its rotation, but the location of its Prime Meridian was specified, as was Earth's (at Greenwich), by choice of an arbitrary point; Mädler and Beer selected a line for their first maps of Mars in 1830. After the spacecraft Mariner 9 provided extensive imagery of Mars in 1972, a small crater (later called Airy-0), located in the Sinus Meridiani ("Middle Bay" or "Meridian Bay"), was chosen by Merton Davies of the Rand Corporation for the definition of 0.0° longitude to coincide with the original selection.</p>
            <br />
            <p>Because Mars has no oceans and hence no "sea level", a zero-elevation surface had to be selected as a reference level; this is called the areoid of Mars, analogous to the terrestrial geoid. Zero altitude was defined by the height at which there is 610.5 Pa (6.105 mbar) of atmospheric pressure. This pressure corresponds to the triple point of water, and it is about 0.6% of the sea level surface pressure on Earth (0.006 atm).</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center h-full block mt-3" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/PIA11176_-_A_Recent_Cluster_of_Impacts.jpg/1280px-PIA11176_-_A_Recent_Cluster_of_Impacts.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">These new impact craters on Mars occurred sometime between 2008 and 2014, as detected from orbit</h3>
          </div>
          {/* Map Of Quadrangles Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Map Of Quadrangles Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/MGS_MOC_Wide_Angle_Map_of_Mars_PIA03467.jpg/1280px-MGS_MOC_Wide_Angle_Map_of_Mars_PIA03467.jpg" width="650"/>
          <div className="lg:w-2/3 w-full">
            <p>For mapping purposes, the United States Geological Survey divides the surface of Mars into thirty cartographic quadrangles, each named for a classical albedo feature it contains. The quadrangles can be seen and explored via the interactive image map below.</p>
          </div>
          {/* Impact Topography Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Impact Topography Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/PIA23304-Mars-ImpactCrater-Sep2016-Feb2019.jpg/1280px-PIA23304-Mars-ImpactCrater-Sep2016-Feb2019.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Newly formed impact crater (est 2016 – 2019). False blue color highlights exposed bedrock</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The dichotomy of Martian topography is striking: northern plains flattened by lava flows contrast with the southern highlands, pitted and cratered by ancient impacts. Research in 2008 has presented evidence regarding a theory proposed in 1980 postulating that, four billion years ago, the Northern Hemisphere of Mars was struck by an object one-tenth to two-thirds the size of Earth's Moon. If validated, this would make the Northern Hemisphere of Mars the site of an impact crater 10,600 by 8,500 kilometres (6,600 by 5,300 mi) in size, or roughly the area of Europe, Asia, and Australia combined, surpassing the South Pole–Aitken basin as the largest impact crater in the Solar System.</p>
            <br />
            <p>Mars is scarred by a number of impact craters: a total of 43,000 craters with a diameter of 5 kilometres (3.1 mi) or greater have been found. The largest confirmed of these is the Hellas impact basin, a light albedo feature clearly visible from Earth. Due to the smaller mass and size of Mars, the probability of an object colliding with the planet is about half that of Earth. Mars is located closer to the asteroid belt, so it has an increased chance of being struck by materials from that source. Mars is more likely to be struck by short-period comets, i.e., those that lie within the orbit of Jupiter. In spite of this, there are far fewer craters on Mars compared with the Moon, because the atmosphere of Mars provides protection against small meteors and surface modifying processes have erased some craters.</p>
            <br />
            <p>Martian craters can have a morphology that suggests the ground became wet after the meteor impacted.</p>
          </div>
          {/* Volcanoes Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Volcanoes Of Mars</h2>
          <div className="lg:w-2/3 w-full">
            <p>The shield volcano Olympus Mons (Mount Olympus) is an extinct volcano in the vast upland region Tharsis, which contains several other large volcanoes. Olympus Mons is roughly three times the height of Mount Everest, which in comparison stands at just over 8.8 kilometres (5.5 mi). It is either the tallest or second-tallest mountain in the Solar System, depending on how it is measured, with various sources giving figures ranging from about 21 to 27 kilometres (13 to 17 mi) high.</p>
          </div>
          {/* Holes Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Holes Of Mars</h2>
          <div className="lg:w-2/3 w-full">
            <p>Images from the Thermal Emission Imaging System (THEMIS) aboard NASA's Mars Odyssey orbiter have revealed seven possible cave entrances on the flanks of the volcano Arsia Mons. The caves, named after loved ones of their discoverers, are collectively known as the "seven sisters". Cave entrances measure from 100 to 252 metres (328 to 827 ft) wide and they are estimated to be at least 73 to 96 metres (240 to 315 ft) deep. Because light does not reach the floor of most of the caves, it is possible that they extend much deeper than these lower estimates and widen below the surface. "Dena" is the only exception; its floor is visible and was measured to be 130 metres (430 ft) deep. The interiors of these caverns may be protected from micrometeoroids, UV radiation, solar flares and high energy particles that bombard the planet's surface.</p>
          </div>
          {/* Atmosphere Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Atmosphere Of Mars</h2>
          <div className="lg:w-2/3 w-full">
            <p>Mars lost its magnetosphere 4 billion years ago, possibly because of numerous asteroid strikes, so the solar wind interacts directly with the Martian ionosphere, lowering the atmospheric density by stripping away atoms from the outer layer. Both Mars Global Surveyor and Mars Express have detected ionised atmospheric particles trailing off into space behind Mars, and this atmospheric loss is being studied by the MAVEN orbiter. Compared to Earth, the atmosphere of Mars is quite rarefied. Atmospheric pressure on the surface today ranges from a low of 30 Pa (0.0044 psi) on Olympus Mons to over 1,155 Pa (0.1675 psi) in Hellas Planitia, with a mean pressure at the surface level of 600 Pa (0.087 psi). The highest atmospheric density on Mars is equal to that found 35 kilometres (22 mi) above Earth's surface. The resulting mean surface pressure is only 0.6% of that of Earth 101.3 kPa (14.69 psi). The scale height of the atmosphere is about 10.8 kilometres (6.7 mi), which is higher than Earth's, 6 kilometres (3.7 mi), because the surface gravity of Mars is only about 38% of Earth's, an effect offset by both the lower temperature and 50% higher average molecular weight of the atmosphere of Mars.</p>
            <br />
            <p>The atmosphere of Mars consists of about 96% carbon dioxide, 1.93% argon and 1.89% nitrogen along with traces of oxygen and water. The atmosphere is quite dusty, containing particulates about 1.5 µm in diameter which give the Martian sky a tawny color when seen from the surface. It may take on a pink hue due to iron oxide particles suspended in it.</p>
          </div>
          {/* Methane Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Methane Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/PIA19088-MarsCuriosityRover-MethaneSource-20141216.png/1280px-PIA19088-MarsCuriosityRover-MethaneSource-20141216.png" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Potential sources and sinks of methane (CH<sub>4</sub>) on Mars</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Methane has been detected in the Martian atmosphere; it occurs in extended plumes, and the profiles imply that the methane is released from discrete regions. The concentration of methane fluctuates from about 0.24 ppb during the northern winter to about 0.65 ppb during the summer. Estimates of its lifetime range from 0.6 to 4 years, so its presence indicates that an active source of the gas must be present. Methane could be produced by non-biological process such as serpentinization involving water, carbon dioxide, and the mineral olivine, which is known to be common on Mars. Methanogenic microbial life forms in the subsurface are among possible sources. But even if rover missions determine that microscopic Martian life is the source of the methane, the life forms likely reside far below the surface, outside of the rover's reach.</p>
          </div>
          {/* Aurora Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Aurora Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/PIA18613-MarsMAVEN-Atmosphere-3UV-Views-20141014.jpg/1920px-PIA18613-MarsMAVEN-Atmosphere-3UV-Views-20141014.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Escaping atmosphere on Mars (carbon, oxygen, and hydrogen) by MAVEN in UV</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Methane has been detected in the Martian atmosphere; it occurs in extended plumes, and the profiles imply that the methane is released from discrete regions. The concentration of methane fluctuates from about 0.24 ppb during the northern winter to about 0.65 ppb during the summer. Estimates of its lifetime range from 0.6 to 4 years, so its presence indicates that an active source of the gas must be present. Methane could be produced by non-biological process such as serpentinization involving water, carbon dioxide, and the mineral olivine, which is known to be common on Mars. Methanogenic microbial life forms in the subsurface are among possible sources. But even if rover missions determine that microscopic Martian life is the source of the methane, the life forms likely reside far below the surface, outside of the rover's reach.</p>
            <br />
            <p>In late December 2014, NASA's MAVEN spacecraft detected evidence of widespread auroras in Mars's Northern Hemisphere and descended to approximately 20–30° North latitude of Mars's equator. The particles causing the aurora penetrated into the Martian atmosphere, creating auroras below 100 km above the surface, Earth's auroras range from 100 km to 500 km above the surface. Magnetic fields in the solar wind drape over Mars, into the atmosphere, and the charged particles follow the solar wind magnetic field lines into the atmosphere, causing auroras to occur outside the magnetic umbrellas.</p>
            <br />
            <p>On 18 March 2015, NASA reported the detection of an aurora that is not fully understood and an unexplained dust cloud in the atmosphere of Mars.</p>
            <br />
            <p>In September 2017, NASA reported radiation levels on the surface of the planet Mars were temporarily doubled, and were associated with an aurora 25 times brighter than any observed earlier, due to a massive, and unexpected, solar storm in the middle of the month.</p>
          </div>
          {/* Climate Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Climate Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/1/1d/PIA22487-Mars-BeforeAfterDust-20180719.gif" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Mars (before/after) global dust storm (July 2018)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Of all the planets in the Solar System, the seasons of Mars are the most Earth-like, due to the similar tilts of the two planets' rotational axes. The lengths of the Martian seasons are about twice those of Earth's because Mars's greater distance from the Sun leads to the Martian year being about two Earth years long. Martian surface temperatures vary from lows of about −143 °C (−225 °F) at the winter polar caps to highs of up to 35 °C (95 °F) in equatorial summer. The wide range in temperatures is due to the thin atmosphere which cannot store much solar heat, the low atmospheric pressure, and the low thermal inertia of Martian soil. The planet is 1.52 times as far from the Sun as Earth, resulting in just 43% of the amount of sunlight.</p>
            <br />
            <p>If Mars had an Earth-like orbit, its seasons would be similar to Earth's because its axial tilt is similar to Earth's. The comparatively large eccentricity of the Martian orbit has a significant effect. Mars is near perihelion when it is summer in the Southern Hemisphere and winter in the north, and near aphelion when it is winter in the Southern Hemisphere and summer in the north. As a result, the seasons in the Southern Hemisphere are more extreme and the seasons in the northern are milder than would otherwise be the case. The summer temperatures in the south can be warmer than the equivalent summer temperatures in the north by up to 30 °C (54 °F).</p>
            <br />
            <p>Mars has the largest dust storms in the Solar System, reaching speeds of over 160 km/h (100 mph). These can vary from a storm over a small area, to gigantic storms that cover the entire planet. They tend to occur when Mars is closest to the Sun, and have been shown to increase the global temperature.</p>
          </div>
        </div>
        
        <hr/>
        
        {/* Orbit & Rotation Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Orbit & Rotation Of Mars</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/5/54/Marsorbitsolarsystem.gif" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Comparison: Earth and Mars</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Mars's average distance from the Sun is roughly 230 million km (143 million mi), and its orbital period is 687 (Earth) days. The solar day (or sol) on Mars is only slightly longer than an Earth day: 24 hours, 39 minutes, and 35.244 seconds. A Martian year is equal to 1.8809 Earth years, or 1 year, 320 days, and 18.2 hours.</p>
            <br />
            <p>The axial tilt of Mars is 25.19° relative to its orbital plane, which is similar to the axial tilt of Earth. As a result, Mars has seasons like Earth, though on Mars they are nearly twice as long because its orbital period is that much longer. In the present day epoch, the orientation of the north pole of Mars is close to the star Deneb.</p>
            <br />
            <p>Mars has a relatively pronounced orbital eccentricity of about 0.09; of the seven other planets in the Solar System, only Mercury has a larger orbital eccentricity. It is known that in the past, Mars has had a much more circular orbit. At one point, 1.35 million Earth years ago, Mars had an eccentricity of roughly 0.002, much less than that of Earth today. Mars's cycle of eccentricity is 96,000 Earth years compared to Earth's cycle of 100,000 years. Mars has a much longer cycle of eccentricity, with a period of 2.2 million Earth years, and this overshadows the 96,000-year cycle in the eccentricity graphs. For the last 35,000 years, the orbit of Mars has been getting slightly more eccentric because of the gravitational effects of the other planets. The closest distance between Earth and Mars will continue to mildly decrease for the next 25,000 years.</p>
          </div>
        </div>
        
        <hr />
        
        {/* Habitability & Search For Life Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Habitability & Search For Life Of Mars</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Mars_Viking_11d128.png" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Viking 1 lander's sampling arm scooped up soil samples for tests (Chryse Planitia)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The current understanding of planetary habitability – the ability of a world to develop environmental conditions favorable to the emergence of life – favors planets that have liquid water on their surface. Most often this requires the orbit of a planet to lie within the habitable zone, which for the Sun extends from just beyond Venus to about the semi-major axis of Mars. During perihelion, Mars dips inside this region, but Mars's thin (low-pressure) atmosphere prevents liquid water from existing over large regions for extended periods. The past flow of liquid water demonstrates the planet's potential for habitability. Recent evidence has suggested that any water on the Martian surface may have been too salty and acidic to support regular terrestrial life.</p>
            <br />
            <p>The lack of a magnetosphere and the extremely thin atmosphere of Mars are a challenge: the planet has little heat transfer across its surface, poor insulation against bombardment of the solar wind and insufficient atmospheric pressure to retain water in a liquid form (water instead sublimes to a gaseous state). Mars is nearly, or perhaps totally, geologically dead; the end of volcanic activity has apparently stopped the recycling of chemicals and minerals between the surface and interior of the planet.</p>
            <br />
            <p>A 2014 analysis of the Phoenix WCL showed that the Ca(ClO<sub>4</sub>)<sub>2</sub> in the Phoenix soil has not interacted with liquid water of any form, perhaps for as long as 600 million years. If it had, the highly soluble Ca(ClO<sub>4</sub>)<sub>2</sub> in contact with liquid water would have formed only CaSO<sub>4</sub>. This suggests a severely arid environment, with minimal or no liquid water interaction.</p>
            <br />
            <p>Scientists have proposed that carbonate globules found in meteorite ALH84001, which is thought to have originated from Mars, could be fossilized microbes extant on Mars when the meteorite was blasted from the Martian surface by a meteor strike some 15 million years ago. This proposal has been met with skepticism, and an exclusively inorganic origin for the shapes has been proposed.</p>
            <br />
            <p>Small quantities of methane and formaldehyde detected by Mars orbiters are both claimed to be possible evidence for life, as these chemical compounds would quickly break down in the Martian atmosphere. Alternatively, these compounds may instead be replenished by volcanic or other geological means, such as serpentinite.</p>
            <br />
            <p>Impact glass, formed by the impact of meteors, which on Earth can preserve signs of life, has been found on the surface of the impact craters on Mars. Likewise, the glass in impact craters on Mars could have preserved signs of life if life existed at the site.</p>
            <br />
            <p>In May 2017, evidence of the earliest known life on land on Earth may have been found in 3.48-billion-year-old geyserite and other related mineral deposits (often found around hot springs and geysers) uncovered in the Pilbara Craton of Western Australia. These findings may be helpful in deciding where best to search for early signs of life on the planet Mars.</p>
            <br />
            <p>In early 2018, media reports speculated that certain rock features at a site called Jura looked like a type of fossil, but project scientists say the formations likely resulted from a geological process at the bottom of an ancient drying lakebed, and are related to mineral veins in the area similar to gypsum crystals.</p>
            <br />
            <p>On 7 June 2018, NASA announced that the Curiosity rover had discovered organic compounds in sedimentary rocks dating to three billion years old, indicating that some of the building blocks for life were present.</p>
            <br />
            <p>In July 2018, scientists reported the discovery of a subglacial lake on Mars, the first known stable body of water on the planet. It sits 1.5 km (0.9 mi) below the surface at the base of the southern polar ice cap and is about 20 kilometres (12 mi) wide. The lake was discovered using the MARSIS radar on board the Mars Express orbiter, and the profiles were collected between May 2012 and December 2015. The lake is centered at 193° East, 81° South, a flat area that does not exhibit any peculiar topographic characteristics. It is mostly surrounded by higher ground except on its eastern side, where there is a depression.</p>
          </div>
        </div>
        
        <hr />
        
        {/* Moons Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Moons Of Mars</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/d/db/Orbits_of_Phobos_and_Deimos.gif" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Orbits of Phobos and Deimos (to scale)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Mars has two relatively small (compared to Earth's) natural moons, Phobos (about 22 kilometres (14 mi) in diameter) and Deimos (about 12 kilometres (7.5 mi) in diameter), which orbit close to the planet. Asteroid capture is a long-favored theory, but their origin remains uncertain. Both satellites were discovered in 1877 by Asaph Hall; they are named after the characters Phobos (panic/fear) and Deimos (terror/dread), who, in Greek mythology, accompanied their father Ares, god of war, into battle. Mars was the Roman counterpart of Ares. In modern Greek, the planet retains its ancient name Ares (Aris: Άρης).</p>
            <br />
            <p>From the surface of Mars, the motions of Phobos and Deimos appear different from that of the Moon. Phobos rises in the west, sets in the east, and rises again in just 11 hours. Deimos, being only just outside synchronous orbit – where the orbital period would match the planet's period of rotation – rises as expected in the east but slowly. Despite the 30-hour orbit of Deimos, 2.7 days elapse between its rise and set for an equatorial observer, as it slowly falls behind the rotation of Mars.</p>
            <br />
            <p>Because the orbit of Phobos is below synchronous altitude, the tidal forces from the planet Mars are gradually lowering its orbit. In about 50 million years, it could either crash into Mars's surface or break up into a ring structure around the planet.</p>
            <br />
            <p>The origin of the two moons is not well understood. Their low albedo and carbonaceous chondrite composition have been regarded as similar to asteroids, supporting the capture theory. The unstable orbit of Phobos would seem to point towards a relatively recent capture. But both have circular orbits, near the equator, which is unusual for captured objects and the required capture dynamics are complex. Accretion early in the history of Mars is plausible, but would not account for a composition resembling asteroids rather than Mars itself, if that is confirmed.</p>
            <br />
            <p>A third possibility is the involvement of a third body or a type of impact disruption. More-recent lines of evidence for Phobos having a highly porous interior, and suggesting a composition containing mainly phyllosilicates and other minerals known from Mars, point toward an origin of Phobos from material ejected by an impact on Mars that reaccreted in Martian orbit, similar to the prevailing theory for the origin of Earth's moon. Although the VNIR spectra of the moons of Mars resemble those of outer-belt asteroids, the thermal infrared spectra of Phobos are reported to be inconsistent with chondrites of any class.</p>
            <br />
            <p>Mars may have moons smaller than 50 to 100 metres (160 to 330 ft) in diameter, and a dust ring is predicted to exist between Phobos and Deimos.</p>
          </div>
        </div>
        
        <hr />
        
        {/* Exploration Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Exploration Of Mars</h1>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/c7/HiRISE_image_of_MSL_during_EDL_%28refined%29.png" width="450"/>
          <div className="lg:w-2/3 w-full">
            <p>Dozens of crewless spacecraft, including orbiters, landers, and rovers, have been sent to Mars by the Soviet Union, the United States, Europe, India, and China to study the planet's surface, climate, and geology.</p>
            <br />
            <p>As of 2021, Mars is host to eleven functioning spacecraft: seven in orbit – 2001 Mars Odyssey, Mars Express, Mars Reconnaissance Orbiter, MAVEN, Mars Orbiter Mission, ExoMars Trace Gas Orbiter, and Emirates Mars Mission – and four on the surface – the Mars Science Laboratory Curiosity rover, the InSight lander, the Perseverance rover, and Tianwen-1. The public can request images of Mars via the Mars Reconnaissance Orbiter's HiWish program.</p>
            <br />
            <p>The Mars Science Laboratory, named Curiosity, launched on 26 November 2011, and reached Mars on 6 August 2012 UTC. It is larger and more advanced than the Mars Exploration Rovers, with a movement rate up to 90 metres (300 ft) per hour. Experiments include a laser chemical sampler that can deduce the make-up of rocks at a distance of 7 metres (23 ft). On 10 February 2013, the Curiosity rover obtained the first deep rock samples ever taken from another planetary body, using its on-board drill. The same year, it discovered that Mars's soil contains between 1.5% and 3% water by mass (albeit attached to other compounds and thus not freely accessible). Observations by the Mars Reconnaissance Orbiter had previously revealed the possibility of flowing water during the warmest months on Mars.</p>
            <br />
            <p>On 24 September 2014, Mars Orbiter Mission (MOM), launched by the Indian Space Research Organisation (ISRO), reached Mars orbit. ISRO launched MOM on 5 November 2013, with the aim of analyzing the Martian atmosphere and topography. The Mars Orbiter Mission used a Hohmann transfer orbit to escape Earth's gravitational influence and catapult into a nine-month-long voyage to Mars. The mission is the first successful Asian interplanetary mission.</p>
            <br />
            <p>The European Space Agency, in collaboration with Roscosmos, launched the ExoMars Trace Gas Orbiter and Schiaparelli lander on 14 March 2016. While the Trace Gas Orbiter successfully entered Mars orbit on 19 October 2016, Schiaparelli crashed during its landing attempt.</p>
            <br />
            <p>In May 2018, NASA's InSight lander was launched, along with the twin MarCO CubeSats that flew by Mars and acted as telemetry relays during the landing. The mission arrived at Mars in November 2018. InSight detected potential seismic activity (a "marsquake") in April 2019.</p>
            <br />
            <p>In 2019, MAVEN spacecraft mapped high-altitude global wind patterns at Mars for the first time. It was discovered that the winds which are miles above the surface retained information about the land forms below.</p>
            <br />
            <p>The United Arab Emirates' Mars Hope orbiter was launched on 19 July 2020, and successfully entered orbit around Mars on 9 February 2021. The probe will conduct a global study of the Martian atmosphere. With this accomplishment, UAE became the second country, after India, to reach Mars on its first attempt.</p>
            <br />
            <p>China's Tianwen-1 successfully landed on Mars on 15 May 2021.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/PIA23140-Mars-InsightLander-Panorama-12092018.jpg/1920px-PIA23140-Mars-InsightLander-Panorama-12092018.jpg" width="1000"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">InSight Lander – panorama (9 December 2018)</h3>
          </div>
          {/* Future Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Future Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Orion_docked_to_Mars_Transfer_Vehicle.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Concept for a Bimodal Nuclear Thermal Transfer Vehicle in low Earth orbit</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>NASA launched the Mars 2020 mission on 30 July 2020. The rover Perseverance and Ingenuity successfully landed on the surface of Mars on 18 February 2021. The mission will cache samples for future retrieval and return of them to Earth. The current concept for the Mars sample-return mission would launch in 2026 and feature hardware built by NASA and ESA. The European Space Agency will launch the ExoMars rover and surface platform sometime between August and October 2022.</p>
            <br />
            <p>Several plans for a human mission to Mars have been proposed throughout the 20th and 21st centuries, but no human mission has yet launched. SpaceX founder Elon Musk presented a plan in September 2016 to, optimistically, launch a crewed mission to Mars in 2024 at an estimated development cost of US$10 billion, but this mission is not expected to take place before 2027. In October 2016, President Barack Obama renewed United States policy to pursue the goal of sending humans to Mars in the 2030s, and to continue using the International Space Station as a technology incubator in that pursuit. The NASA Authorization Act of 2017 directed NASA to get humans near or on the surface of Mars by the early 2030s.</p>
          </div>
        </div>
        
        <hr />
        
        {/* Astronomy On Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Astronomy On Mars</h1>
          <div className="lg:w-2/3 w-full">
            <p>With the presence of various orbiters, landers, and rovers, it is possible to practice astronomy from Mars. Although Mars's moon Phobos appears about one-third the angular diameter of the full moon on Earth, Deimos appears more or less star-like, looking only slightly brighter than Venus does from Earth.</p>
            <br />
            <p>Various phenomena seen from Earth have also been observed from Mars, such as meteors and auroras. The apparent sizes of the moons Phobos and Deimos are sufficiently smaller than that of the Sun; thus, their partial "eclipses" of the Sun are best considered transits (see transit of Deimos and Phobos from Mars). Transits of Mercury and Venus have been observed from Mars. A transit of Earth will be seen from Mars on 10 November 2084.</p>
          </div>
        </div>
        
        <hr />
        
        {/* Viewing */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Viewing</h1>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/7/70/Apparent_retrograde_motion_of_Mars_in_2003.gif" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Animation of the apparent retrograde motion of Mars in 2003 as seen from Earth.</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The mean apparent magnitude of Mars is +0.71 with a standard deviation of 1.05. Because the orbit of Mars is eccentric, the magnitude at opposition from the Sun can range from about −3.0 to −1.4. The minimum brightness is magnitude +1.86 when the planet is in conjunction with the Sun. At its brightest, Mars (along with Jupiter) is second only to Venus in luminosity. Mars usually appears distinctly yellow, orange, or red. NASA's Spirit rover has taken pictures of a greenish-brown, mud-colored landscape with blue-grey rocks and patches of light red sand. When farthest away from Earth, it is more than seven times farther away than when it is closest. When least favorably positioned, it can be lost in the Sun's glare for months at a time. At its most favorable times — at 15-year or 17-year intervals, and always between late July and late September — a lot of surface detail can be seen with a telescope. Especially noticeable, even at low magnification, are the polar ice caps.</p>
            <br />
            <p>As Mars approaches opposition, it begins a period of retrograde motion, which means it will appear to move backwards in a looping motion with respect to the background stars. The duration of this retrograde motion lasts for about 72 days, and Mars reaches its peak luminosity in the middle of this motion.</p>
          </div>
          {/* Closest Approaches Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 font-medium text-gray-900">Closest Approaches Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/5/57/Animation_of_Mars_orbit_around_Earth.gif" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Geocentric animation of Mars's orbit relative to Earth from January 2003 to January 2019 <span className="legend-color bg-RoyalBlue">&nbsp;&nbsp;</span>  Mars · <span className="legend-color bg-magenta">&nbsp;&nbsp;</span> Earth</h3>
          </div>
          {/* Relative */}
          <h3 className="subtitle-font sm:text-2xl text-1xl mt-5 mb-5 font-medium text-gray-900">Relative</h3>
          <div className="lg:w-2/3 w-full">
            <p>The point at which Mars's geocentric longitude is 180° different from the Sun's is known as opposition, which is near the time of closest approach to Earth. The time of opposition can occur as much as 8.5 days away from the closest approach. The distance at close approach varies between about 54 and 103 million km (34 and 64 million mi) due to the planets' elliptical orbits, which causes comparable variation in angular size. The second-to-last Mars opposition occurred on 27 July 2018, at a distance of about 58 million km (36 million mi). The last Mars opposition occurred on 13 October 2020, at a distance of about 63 million km (39 million mi). The average time between the successive oppositions of Mars, its synodic period, is 780 days; but the number of days between the dates of successive oppositions can range from 764 to 812.</p>
            <br />
            <p>As Mars approaches opposition it begins a period of retrograde motion, which makes it appear to move backwards in a looping motion relative to the background stars. The duration of this retrograde motion is about 72 days.</p>
          </div>
          {/* Absolute, Around The Present Time */}
          <h3 className="subtitle-font sm:text-2xl text-1xl mt-5 mb-5 font-medium text-gray-900">Absolute, Around The Present Time</h3>
          <div className="lg:w-2/3 w-full">
            <p>Mars made its closest approach to Earth and maximum apparent brightness in nearly 60,000 years, 55,758,006 km (0.37271925 AU; 34,646,419 mi), magnitude −2.88, on 27 August 2003, at 09:51:13 UTC. This occurred when Mars was one day from opposition and about three days from its perihelion, making it particularly easy to see from Earth. The last time it came so close is estimated to have been on 12 September 57,617 BC, the next time being in 2287. This record approach was only slightly closer than other recent close approaches. For instance, the minimum distance on 22 August 1924, was 0.37285 AU, and the minimum distance on 24 August 2208, will be 0.37279 AU.</p>
            <br />
            <p>Every 15 to 17 years, Mars comes into opposition near its perihelion. These perihelic oppositions make a closer approach to earth than other oppositions which occur every 2.1 years. Mars comes into perihelic opposition in 2003, 2018 and 2035, with 2020 and 2033 being close to perihelic opposition.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Mars_distance_from_Earth.svg/1280px-Mars_distance_from_Earth.svg.png" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Mars distance from Earth in millions of km (Gm).</h3>
          </div>
        </div>
        
        <hr />
        
        {/* Historical Observations Of Mars */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Historical Observations Of Mars</h1>
          <div className="lg:w-2/3 w-full">
            <p>The history of observations of Mars is marked by the oppositions of Mars when the planet is closest to Earth and hence is most easily visible, which occur every couple of years. Even more notable are the perihelic oppositions of Mars, which occur every 15 or 17 years and are distinguished because Mars is close to perihelion, making it even closer to Earth.</p>
          </div>
          {/* Ancient & Medieval Observations Of Mars */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 font-medium text-gray-900">Ancient & Medieval Observations Of Mars</h2>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/cc/Galileo.arp.300pix.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Galileo Galilei, first person to see Mars via telescope in 1610.</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The ancient Sumerians believed that Mars was Nergal, the god of war and plague. During Sumerian times, Nergal was a minor deity of little significance, but, during later times, his main cult center was the city of Nineveh. In Mesopotamian texts, Mars is referred to as the "star of judgement of the fate of the dead." The existence of Mars as a wandering object in the night sky was recorded by the ancient Egyptian astronomers and, by 1534 BCE, they were familiar with the retrograde motion of the planet. By the period of the Neo-Babylonian Empire, the Babylonian astronomers were making regular records of the positions of the planets and systematic observations of their behavior. For Mars, they knew that the planet made 37 synodic periods, or 42 circuits of the zodiac, every 79 years. They invented arithmetic methods for making minor corrections to the predicted positions of the planets. In Ancient Greece, the planet was known as Πυρόεις.</p>
            <br />
            <p>In the fourth century BCE, Aristotle noted that Mars disappeared behind the Moon during an occultation, indicating that the planet was farther away. Ptolemy, a Greek living in Alexandria, attempted to address the problem of the orbital motion of Mars. Ptolemy's model and his collective work on astronomy was presented in the multi-volume collection Almagest, which became the authoritative treatise on Western astronomy for the next fourteen centuries. Literature from ancient China confirms that Mars was known by Chinese astronomers by no later than the fourth century BCE. In the East Asian cultures, Mars is traditionally referred to as the "fire star" (Chinese: 火星), based on the Five elements.</p>
            <br />
            <p>During the seventeenth century, Tycho Brahe measured the diurnal parallax of Mars that Johannes Kepler used to make a preliminary calculation of the relative distance to the planet. When the telescope became available, the diurnal parallax of Mars was again measured in an effort to determine the Sun-Earth distance. This was first performed by Giovanni Domenico Cassini in 1672. The early parallax measurements were hampered by the quality of the instruments. The only occultation of Mars by Venus observed was that of 13 October 1590, seen by Michael Maestlin at Heidelberg. In 1610, Mars was viewed by Italian astronomer Galileo Galilei, who was first to see it via telescope. The first person to draw a map of Mars that displayed any terrain features was the Dutch astronomer Christiaan Huygens.</p>
          </div>
          {/* Martian "canals" */}
          <h3 className="subtitle-font sm:text-2xl text-1xl mt-5 mb-5 font-medium text-gray-900">Martian "canals"</h3>
          <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/d/dd/Karte_Mars_Schiaparelli_MKL1888.png" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Map of Mars by Giovanni Schiaparelli</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>By the 19th century, the resolution of telescopes reached a level sufficient for surface features to be identified. A perihelic opposition of Mars occurred on 5 September 1877. In that year, the Italian astronomer Giovanni Schiaparelli used a 22 centimetres (8.7 in) telescope in Milan to help produce the first detailed map of Mars. These maps notably contained features he called canali, which were later shown to be an optical illusion. These canali were supposedly long, straight lines on the surface of Mars, to which he gave names of famous rivers on Earth. His term, which means "channels" or "grooves", was popularly mistranslated in English as "canals".</p>
            <br />
            <p>Influenced by the observations, the orientalist Percival Lowell founded an observatory which had 30 and 45 centimetres (12 and 18 in) telescopes. The observatory was used for the exploration of Mars during the last good opportunity in 1894 and the following less favorable oppositions. He published several books on Mars and life on the planet, which had a great influence on the public. The canali were independently found by other astronomers, like Henri Joseph Perrotin and Louis Thollon in Nice, using one of the largest telescopes of that time.</p>
            <br />
            <p>The seasonal changes (consisting of the diminishing of the polar caps and the dark areas formed during Martian summer) in combination with the canals led to speculation about life on Mars, and it was a long-held belief that Mars contained vast seas and vegetation. The telescope never reached the resolution required to give proof to any speculations. As bigger telescopes were used, fewer long, straight canali were observed. During an observation in 1909 by Camille Flammarion with an 84 centimetres (33 in) telescope, irregular patterns were observed, but no canali were seen.</p>
            <br />
            <p>Even in the 1960s, articles were published on Martian biology, putting aside explanations other than life for the seasonal changes on Mars. Detailed scenarios for the metabolism and chemical cycles for a functional ecosystem have been published.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/d/da/Mars_HST_Mollweide_map_1999.png" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Map of Mars from the Hubble Space Telescope as seen near the 1999 opposition (north on top)</h3>
          </div>
          {/* Spacecraft Visitation */}
          <h3 className="subtitle-font sm:text-2xl text-1xl mt-5 mb-5 font-medium text-gray-900">Spacecraft Visitation</h3>
          <div className="lg:w-2/3 w-full">
            <p>Once spacecraft visited the planet during NASA's Mariner missions in the 1960s and 1970s, these concepts were radically broken. The results of the Viking life-detection experiments aided an intermission in which the hypothesis of a hostile, dead planet was generally accepted.</p>
            <br />
            <p>Mariner 9 and Viking allowed better maps of Mars to be made using the data from these missions, and another major leap forward was the Mars Global Surveyor mission, launched in 1996 and operated until late 2006, that allowed complete, extremely detailed maps of the Martian topography, magnetic field and surface minerals to be obtained. These maps are available online; for example, at Google Mars. Mars Reconnaissance Orbiter and Mars Express continued exploring with new instruments and supporting lander missions. NASA provides two online tools: Mars Trek, which provides visualizations of the planet using data from 50 years of exploration, and Experience Curiosity, which simulates traveling on Mars in 3-D with Curiosity.</p>
          </div>
        </div>
      </section>
    </>
  )
}
