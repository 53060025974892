import React from 'react';
import { Link } from "react-router-dom";

export default function Planets() {
    return (
        <section className="text-gray-600 body-font">
          <div className="container px-5 mx-auto">
            <div>
              <div className="py-6 mb-12">
                <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Planets</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded mt-1"></div>
              </div>
            </div>
            <div className="flex flex-wrap justify-center sm:-m-4 -mx-4 -mb-10 -mt-4">
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Mercury Image" className="object-cover object-center h-full w-full" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Mercury_in_color_-_Prockter07-edit1.jpg/1024px-Mercury_in_color_-_Prockter07-edit1.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Mercury</h2>
                <p className="text-base leading-relaxed mt-2">Mercury is the smallest and closest planet to the Sun in the Solar System. Its orbit around the Sun takes 87.97 Earth days, the shortest of all the planets in the Solar System</p>
                <Link to="/mercury" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Venus Image" className="object-cover object-center h-full w-full" src="https://media.nature.com/lw800/magazine-assets/d41586-020-02785-5/d41586-020-02785-5_18436254.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Venus</h2>
                <p className="text-base leading-relaxed mt-2">Venus is the second planet from the Sun. It is named after the Roman goddess of love and beauty. As the brightest natural object in Earth's night sky after the Moon, Venus can cast shadows and can be, on rare occasion, visible to the naked eye in broad daylight.</p>
                <Link to="/venus" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Earth Image" className="object-cover object-center h-full w-full" src="https://media.npr.org/assets/img/2013/03/06/bluemarble3k-smaller-nasa_custom-644f0b7082d6d0f6814a9e82908569c07ea55ccb-s800-c85.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Earth</h2>
                <p className="text-base leading-relaxed mt-2">Earth is the third planet from the Sun and the only astronomical object known to harbor life. About 29% of Earth's surface is land consisting of continents and islands.</p>
                <Link to="/earth-and-its-moons/earth" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Mars Image" className="object-cover object-center h-full w-full" src="https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Mars</h2>
                <p className="text-base leading-relaxed mt-2">Mars is the fourth planet from the Sun and the second-smallest planet in the Solar System, being larger than only Mercury. In English, Mars carries the name of the Roman god of war and is often referred to as the "Red Planet"</p>
                <Link to="/mars-and-its-moons/mars" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Jupiter Image" className="object-cover object-center h-full w-full" src="https://upload.wikimedia.org/wikipedia/commons/2/2b/Jupiter_and_its_shrunken_Great_Red_Spot.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Jupiter</h2>
                <p className="text-base leading-relaxed mt-2">Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is Link gas giant with Link mass one-thousandth that of the Sun, but two and Link half times that of all the other planets in the Solar System combined.</p>
                <Link to="/jupiter-and-its-moons/jupiter" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Saturn Image" className="object-cover object-center h-full w-full" src="https://specials-images.forbesimg.com/imageserve/5f083e8dd6aee1000672717a/960x0.jpg?cropX1=357&cropX2=1800&cropY1=376&cropY2=1053"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Saturn</h2>
                <p className="text-base leading-relaxed mt-2">Saturn is the sixth planet from the Sun and the second-largest in the Solar System, after Jupiter. It is Link gas giant with an average radius of about nine times that of Earth. It only has one-eighth the average density of Earth; however, with its larger volume, Saturn is over 95 times more massive.</p>
                <Link to="/saturn" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Uranus Image" className="object-cover object-center h-full w-full" src="https://upload.wikimedia.org/wikipedia/commons/3/3d/Uranus2.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Uranus</h2>
                <p className="text-base leading-relaxed mt-2">Uranus is the seventh planet from the Sun. Its name is Link reference to the Greek god of the sky, Uranus, who, according to Greek mythology, was the grandfather of Zeus and father of Cronus. It has the third-largest planetary radius and fourth-largest planetary mass in the Solar System.</p>
                <Link to="/uranus" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
              <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img alt="Neptune Image" className="object-cover object-center h-full w-full" src="https://upload.wikimedia.org/wikipedia/commons/6/63/Neptune_-_Voyager_2_%2829347980845%29_flatten_crop.jpg"/>
                </div>
                <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Neptune</h2>
                <p className="text-base leading-relaxed mt-2">Neptune is the eighth and farthest-known Solar planet from the Sun. In the Solar System, it is the fourth-largest planet by diameter, the third-most-massive planet, and the densest giant planet. It is 17 times the mass of Earth, slightly more massive than its near-twin Uranus.</p>
                <Link to="/neptune" className="text-indigo-500 inline-flex items-center mt-3">Learn More
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </section>
    )
}
