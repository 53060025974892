import React from 'react'

const Deimos = () => {
   return (
      <>
         {/* HeroSection */}
         <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
               <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                  <img className="object-cover object-center rounded" alt="Deimos Image" src="https://upload.wikimedia.org/wikipedia/commons/8/8d/Deimos-MRO.jpg"/>
               </div>
               <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Deimos</h1>
                  <p className="mb-8 leading-relaxed">Deimos is the smaller and outermost of the two natural satellites of Mars, the other being Phobos. Deimos has a mean radius of 6.2 km and takes 30.3 hours to orbit Mars. Deimos is 23,460 km from Mars, much further than Mars's other moon, Phobos.</p>
               </div>
            </div>
         </section>
      
         <hr />

         {/* ImfoOfDeimos */}
         <section className="text-gray-600 body-font">
            {/* Discovery Of Deimos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Discovery Of Deimos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Deimos-viking1.jpg/330px-Deimos-viking1.jpg" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Deimos (Viking 2, 5 October 1977)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Deimos was discovered by Asaph Hall, III at the United States Naval Observatory in Washington, D.C. on 12 August 1877, at about 07:48 UTC (given in contemporary sources as "11 August 14:40" Washington Mean Time, using a pre-1925 astronomical convention of beginning a day at noon, so 12 hours must be added to get the actual local mean time). Hall also discovered Phobos on 18 August 1877, at about 09:14 GMT, after deliberately searching for Martian moons.</p>
                  <br />
                  <p>It is named after Deimos, a figure representing dread in Greek mythology. The names, at first spelled Phobus and Deimus, were suggested by Henry Madan (1838–1901), Science Master of Eton, from Book XV of the Iliad, where Ares (the Roman god Mars) summons Dread (Deimos) and Fear (Phobos).</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Physical Characteristics Of Deimos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Physical Characteristics Of Deimos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block mr-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Deimos_Surface.png/188px-Deimos_Surface.png" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The surface of Deimos from 30 km (Viking, 1977)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Deimos, like Mars' other moon, Phobos, has spectra, albedos, and densities similar to those of a C- or D-type asteroid. Like most bodies of its size, Deimos is highly non-spherical with triaxial dimensions of 15 × 12.2 × 11 km, making it 56% of the size of Phobos. Deimos is composed of rock rich in carbonaceous material, much like C-type asteroids and carbonaceous chondrite meteorites. It is cratered, but the surface is noticeably smoother than that of Phobos, caused by the partial filling of craters with regolith. The regolith is highly porous and has a radar-estimated density of only 1.471 g/cm<sup>3</sup>.</p>
                  <br />
                  <p>Escape velocity from Deimos is 5.6 m/s. This velocity could theoretically be achieved by a human performing a vertical jump. The apparent magnitude of Deimos is 12.45.</p>
               </div>
               {/* Named Geological Features Of Phobos */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Named Geological Features Of Phobos</h2>
               <div className="lg:w-2/3 w-full">
                  <p>Geological features on Phobos are named after astronomers who studied Phobos and people and places from Jonathan Swift's Gulliver's Travels.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Orbital Characteristics Of Deimos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Orbital Characteristics Of Deimos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/d/db/Orbits_of_Phobos_and_Deimos.gif" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Orbits of Phobos and Deimos (to scale)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Deimos's orbit is nearly circular and is close to Mars's equatorial plane. Deimos is possibly an asteroid that was perturbed by Jupiter into an orbit that allowed it to be captured by Mars, though this hypothesis is still controversial and disputed. Both Deimos and Phobos have very circular orbits which lie almost exactly in Mars' equatorial plane, and hence a capture origin requires a mechanism for circularizing the initially highly eccentric orbit, and adjusting its inclination into the equatorial plane, most likely by a combination of atmospheric drag and tidal forces; it is not clear that sufficient time was available for this to have occurred for Deimos.</p>
                  <br />
                  <p>As seen from Mars, Deimos would have an angular diameter of no more than 2.5 minutes (sixty minutes make one degree), one twelfth of the width of the Moon as seen from Earth, and would therefore appear almost star-like to the naked eye. At its brightest ("full moon") it would be about as bright as Venus is from Earth; at the first- or third-quarter phase it would be about as bright as Vega. With a small telescope, a Martian observer could see Deimos's phases, which take 1.2648 days to run their course.</p>
                  <br />
                  <p>Unlike Phobos, which orbits so fast that it rises in the west and sets in the east, Deimos rises in the east and sets in the west. The Sun-synodic orbital period of Deimos of about 30.4 hours exceeds the Martian solar day ("sol") of about 24.7 hours by such a small amount that 2.48 days (2.41 sols) elapse between its rising and setting for an equatorial observer. From Deimos-rise to Deimos-rise (or setting to setting), 5.466 days (5.320 sols) elapse.</p>
                  <br />
                  <p>Because Deimos's orbit is relatively close to Mars and has only a very small inclination to Mars's equator, it cannot be seen from Martian latitudes greater than 82.7°.</p>
                  <br />
                  <p>Deimos's orbit is slowly getting larger, because it is far enough away from Mars and because of tidal acceleration. It is expected to eventually escape Mars's gravity.</p>
               </div>
               <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/2/2f/PIA17352-MarsMoons-PhobosPassesDeimos-RealTime.gif" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Curiosity's view of the Mars moons: Phobos passing in front of Deimos – in real-time (video-gif, 1 August 2013)</h3>
               </div>
               {/* Solar Transits */}
               <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Solar Transits</h2>
               <div className="lg:w-2/3 w-full">
                  <p>Deimos regularly passes in front of the Sun as seen from Mars. It is too small to cause a total eclipse, appearing only as a small black dot moving across the Sun. Its angular diameter is only about 2.5 times the angular diameter of Venus during a transit of Venus from Earth. On 4 March 2004 a transit of Deimos was photographed by Mars rover Opportunity, and on 13 March 2004 a transit was photographed by Mars rover Spirit.</p>
               </div>
            </div>
            
            <hr/>
            
            {/* Origin Of Deimos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Origin Of Deimos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/NASA-Deimos-MarsMoon-19951003.jpg/300px-NASA-Deimos-MarsMoon-19951003.jpg" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Deimos imaged by one of the Viking orbiters</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>The origin of Mars's moons is unknown and the hypotheses are controversial. The main hypotheses are that they formed either by capture or by accretion. Because of the similarity to the composition of C- or D-type asteroids, one hypothesis is that the moons may be objects captured into Martian orbit from the asteroid belt, with orbits that have been circularized either by atmospheric drag or tidal forces, as capture requires dissipation of energy. The current Martian atmosphere is too thin to capture a Phobos-sized object by atmospheric braking. Geoffrey Landis has pointed out that the capture could have occurred if the original body was a binary asteroid that separated due to tidal forces. The main alternative hypothesis is that the moons accreted in the present position. Another hypothesis is that Mars was once surrounded by many Phobos- and Deimos-sized bodies, perhaps ejected into orbit around it by a collision with a planetesimal.</p>
                  <br />
                  <p>Most recently, Amirhossein Bagheri (ETH Zurich), Amir Khan (ETH Zurich), Michael Efroimsky (US Naval Observatory) and their colleagues proposed a new hypothesis on the origin of the moons. By analyzing the seismic and orbital data from Mars InSight Mission and other missions, they proposed that the moons are born from disruption of a common parent body around 1 to 2.7 billion years ago. The common progenitor of Phobos and Deimos was most probably hit by another object and shattered to form Phobos and Deimos.</p>
               </div>
               <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/c0/Deimos_viking2.jpg" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Deimos photo by the Viking 2 Orbiter in October 1977</h3>
               </div>
            </div>
            
            <hr/>
            
            {/* Exploration Of Deimos */}
            <div className="container mx-auto flex px-5 py-10 items-center flex-col">
               <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Exploration Of Deimos</h1>
               <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/0/0c/PIA17351-ApparentSizes-MarsDeimosPhobos-EarthMoon.jpg" width="450"/>
               <div className="mt-4 mb-5">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Deimos and Phobos as seen from Mars, compared to the Moon as seen from Earth (in angular sizes)</h3>
               </div>
               <div className="lg:w-2/3 w-full">
                  <p>Overall, its exploration history is similar to those of Mars and of Phobos. Deimos has been photographed in close-up by several spacecraft whose primary mission has been to photograph Mars. No landings on Deimos have been made.</p>
                  <br />
                  <p>The Soviet Phobos program sent two probes to Phobos. In case Phobos 1 succeeded, Phobos 2 could have been sent to Deimos. Both probes launched successfully in July 1988. The first was lost en route to Mars, whereas the second returned some data and images but failed shortly before beginning its detailed examination of Phobos's surface, including a lander.</p>
                  <br />
                  <p>In 1997 and 1998, the proposed Aladdin mission was selected as a finalist in the NASA Discovery Program. The plan was to visit both Phobos and Deimos, and launch projectiles at the satellites. The probe would collect the ejecta as it performed a slow flyby (~1 km/s). These samples would be returned to Earth for study three years later. The principal investigator was Carle M. Pieters of Brown University. The total mission cost, including launch vehicle and operations was $247.7 million. Ultimately, the mission chosen to fly was MESSENGER, a probe to the planet Mercury.</p>
                  <br />
                  <p>In 2008, NASA Glenn Research Center began studying a Phobos and Deimos sample-return mission that would use solar electric propulsion. The study gave rise to the "Hall" mission concept, a New Frontiers-class mission currently under further study.</p>
                  <br />
                  <p>Also, the sample-return mission called Gulliver has been conceptualized and dedicated to Deimos, in which 1 kilogram (2.2 pounds) of material from Deimos would be returned to Earth.</p>
                  <br />
                  <p>Another concept of sample-return mission from Phobos and Deimos is OSIRIS-REx 2, which would use heritage from the first OSIRIS-REx.</p>
                  <br />
                  <p>In March 2014, a Discovery class mission was proposed to place an orbiter on Mars orbit by 2021 and study Phobos and Deimos. It is called Phobos And Deimos & Mars Environment (PADME).</p>
                  <br />
                  <p>Human exploration of Deimos could serve as a catalyst for the human exploration of Mars. Recently, it was proposed that the sands of Deimos or Phobos could serve as a valuable material for aerobraking in the colonization of Mars. Because the small delta-v budget of Phobos, a small amount of chemical fuel brought from Earth could be transformed in a very large amount of sand lifted from the surface of Phobos – from a permanent outpost, to a transfer orbit. This sand could be released in front of the spacecraft during the descent maneuver and then resulting in a densification of the atmosphere just in front of the spacecraft.</p>
               </div>
            </div>
         </section>
      </>
   )
}

export default Deimos
