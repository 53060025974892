import React from 'react'

const Jupiter = () => {
   return (
      <>
         {/* HeroSection */}
      <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
               <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                  <img className="object-cover object-center rounded" alt="Jupiter Image" src="https://upload.wikimedia.org/wikipedia/commons/2/2b/Jupiter_and_its_shrunken_Great_Red_Spot.jpg"/>
               </div>
               <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Jupiter</h1>
                  <p className="mb-8 leading-relaxed">Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is Link gas giant with Link mass one-thousandth that of the Sun, but two and Link half times that of all the other planets in the Solar System combined.</p>
               </div>
            </div>
      </section>
      
      <hr />
      
      {/* ImfoOfJupiter */}
      <section className="text-gray-600 body-font">
         {/* Formation & Migration Of Jupiter */}
         <div className="container mx-auto flex px-5 py-10 items-center flex-col">
            <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Formation & Migration Of Jupiter</h1>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter is most likely the oldest planet in the Solar System. Current models of Solar System formation suggest that Jupiter formed at or beyond the snow line; a distance from the early Sun where the temperature is sufficiently cold for volatiles such as water to condense into solids. It first assembled a large solid core before accumulating its gaseous atmosphere. As a consequence, the core must have formed before the solar nebula began to dissipate after 10 million years. Formation models suggest Jupiter grew to 20 times the mass of the Earth in under a million years. The orbiting mass created a gap in the disk, thereafter slowly increasing to 50 Earth masses in 3–4 million years.</p>
               <br />
               <p>According to the "grand tack hypothesis", Jupiter would have begun to form at a distance of roughly 3.5 AU. As the young planet accreted mass, interaction with the gas disk orbiting the Sun and orbital resonances with Saturn caused it to migrate inward. This would have upset the orbits of what are believed to be super-Earths orbiting closer to the Sun, causing them to collide destructively. Saturn would later have begun to migrate inwards too, much faster than Jupiter, leading to the two planets becoming locked in a 3:2 mean motion resonance at approximately 1.5 AU. This in turn would have changed the direction of migration, causing them to migrate away from the Sun and out of the inner system to their current locations. These migrations would have occurred over an 800,000 year time period, with all of this happening over a time period of up to 6 million years after Jupiter began to form (3 million being a more likely figure). This departure would have allowed the formation of the inner planets from the rubble, including Earth.</p>
               <br />
               <p>However, the formation timescales of terrestrial planets resulting from the grand tack hypothesis appear inconsistent with the measured terrestrial composition. Moreover, the likelihood that the outward migration actually occurred in the solar nebula is very low. In fact, some models predict the formation of Jupiter's analogues whose properties are close to those of the planet at the current epoch.</p>
               <br />
               <p>Other models have Jupiter forming at distances much further out, such as 18 AU. In fact, based on Jupiter's composition, researchers have made the case for an initial formation outside the molecular nitrogen (N<sub>2</sub>) snowline, which is estimated at 20-30 AU, and possibly even outside the argon snowline, which may be as far as 40 AU. Having formed at one of these extreme distances, Jupiter would then have migrated inwards to its current location. This inward migration would have occurred over a roughly 700,000 year time period, during an epoch approximately 2–3 million years after the planet began to form. Saturn, Uranus and Neptune would have formed even further out than Jupiter, and Saturn would also have migrated inwards.</p>
            </div>
            </div>

            <hr />

         {/* Physical Characteristics Of Jupiter */}
         <div className="container mx-auto flex px-5 py-10 items-center flex-col">
            <h1 className="title-font sm:text-4xl text-3xl font-medium mb-5 text-gray-900">Physical Characteristics Of Jupiter</h1>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter is one of the four gas giants, being primarily composed of gas and liquid rather than solid matter. It is the largest planet in the Solar System, with a diameter of 142,984 km (88,846 mi) at its equator. The average density of Jupiter, 1.326 g/cm<sup>3</sup>, is the second highest of the giant planets, but lower than those of the four terrestrial planets.</p>
            </div>
            {/* Composition Of Jupiter */}
            <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Composition Of Jupiter</h2>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter's upper atmosphere is about 90% hydrogen and 10% helium by volume. Since helium atoms are more massive than hydrogen atoms, Jupiter's atmosphere is approximately 75% hydrogen and 24% helium by mass, with the remaining one percent consisting of other elements. The atmosphere contains trace amounts of methane, water vapour, ammonia, and silicon-based compounds. There are also fractional amounts of carbon, ethane, hydrogen sulfide, neon, oxygen, phosphine, and sulfur. The outermost layer of the atmosphere contains crystals of frozen ammonia. Through infrared and ultraviolet measurements, trace amounts of benzene and other hydrocarbons have also been found. The interior of Jupiter contains denser materials—by mass it is roughly 71% hydrogen, 24% helium, and 5% other elements.</p>
               <br />
               <p>The atmospheric proportions of hydrogen and helium are close to the theoretical composition of the primordial solar nebula. Neon in the upper atmosphere only consists of 20 parts per million by mass, which is about a tenth as abundant as in the Sun. Helium is also depleted to about 80% of the Sun's helium composition. This depletion is a result of precipitation of these elements as helium-rich droplets deep in the interior of the planet.</p>
               <br />
               <p>Based on spectroscopy, Saturn is thought to be similar in composition to Jupiter, but the other giant planets Uranus and Neptune have relatively less hydrogen and helium and relatively more of the next most abundant elements, including oxygen, carbon, nitrogen, and sulfur. As their volatile compounds are mainly in ice form, they are called ice giants.</p>
            </div>
            {/* Mass & Size Of Jupiter */}
            <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Mass & Size Of Jupiter</h2>
            <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/SolarSystem_OrdersOfMagnitude_Sun-Jupiter-Earth-Moon.jpg/800px-SolarSystem_OrdersOfMagnitude_Sun-Jupiter-Earth-Moon.jpg" width="650"/>
            <div className="xl:w-1/2 md:w-10/12 mt-4 mb-5">
               <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Jupiter's diameter is one order of magnitude smaller (×0.10045) than that of the Sun, and one order of magnitude larger (×10.9733) than that of Earth. The Great Red Spot is roughly the same size as Earth.</h3>
            </div>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter's mass is 2.5 times that of all the other planets in the Solar System combined—this is so massive that its barycentre with the Sun lies above the Sun's surface at 1.068 solar radii from the Sun's centre. Jupiter is much larger than Earth and considerably less dense: its volume is that of about 1,321 Earths, but it is only 318 times as massive. Jupiter's radius is about one tenth the radius of the Sun, and its mass is one thousandth the mass of the Sun, so the densities of the two bodies are similar. A "Jupiter mass" (M<sub>J</sub> or M<sub>Jup</sub>) is often used as a unit to describe masses of other objects, particularly extrasolar planets and brown dwarfs. For example, the extrasolar planet HD 209458 b has a mass of 0.69 M<sub>J</sub>, while Kappa Andromedae b has a mass of 12.8 M<sub>J</sub>.</p>
               <br />
               <p>Theoretical models indicate that if Jupiter had much more mass than it does at present, it would shrink. For small changes in mass, the radius would not change appreciably, and above 160% of the current mass the interior would become so much more compressed under the increased pressure that its volume would decrease despite the increasing amount of matter. As a result, Jupiter is thought to have about as large a diameter as a planet of its composition and evolutionary history can achieve. The process of further shrinkage with increasing mass would continue until appreciable stellar ignition was achieved, as in high-mass brown dwarfs having around 50 Jupiter masses.</p>
               <br />
               <p>Although Jupiter would need to be about 75 times more massive to fuse hydrogen and become a star, the smallest red dwarf is only about 30 percent larger in radius than Jupiter. Despite this, Jupiter still radiates more heat than it receives from the Sun; the amount of heat produced inside it is similar to the total solar radiation it receives. This additional heat is generated by the Kelvin–Helmholtz mechanism through contraction. This process causes Jupiter to shrink by about 1 mm/yr. When formed, Jupiter was hotter and was about twice its current diameter.</p>
            </div>
            {/* Internal Structure Of Jupiter */}
            <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Internal Structure Of Jupiter</h2>
            <div className="lg:w-2/3 w-full">
               <p>Before the early 21st century, most scientists expected Jupiter to either consist of a dense core, a surrounding layer of liquid metallic hydrogen (with some helium) extending outward to about 80% of the radius of the planet, and an outer atmosphere consisting predominantly of molecular hydrogen, or perhaps to have no core at all, consisting instead of denser and denser fluid (predominantly molecular and metallic hydrogen) all the way to the center, depending on whether the planet accreted first as a solid body or collapsed directly from the gaseous protoplanetary disk. When the Juno mission arrived in July 2016, it found that Jupiter has a very diffuse core that mixes into its mantle. A possible cause is an impact from a planet of about ten Earth masses a few million years after Jupiter's formation, which would have disrupted an originally solid Jovian core. It is estimated that the core is 30–50% of the planet's radius, and contains heavy elements 7–25 times the mass of Earth.</p>
               <br />
               <p>Above the layer of metallic hydrogen lies a transparent interior atmosphere of hydrogen. At this depth, the pressure and temperature are above molecular hydrogen's critical pressure of 1.3 MPa and critical temperature of only 33 K. In this state, there are no distinct liquid and gas phases—hydrogen is said to be in a supercritical fluid state. It is convenient to treat hydrogen as gas extending downward from the cloud layer to a depth of about 1,000 km, and as liquid in deeper layers. Physically, there is no clear boundary—the gas smoothly becomes hotter and denser as depth increases. Rain-like droplets of helium and neon precipitate downward through the lower atmosphere, depleting the abundance of these elements in the upper atmosphere. Calculations suggest that helium drops separate from metalic hydrogen at a radius of 60,000 km (11,000 km below the cloudtops) and merge again at 50,000 km (22,000 km beneath the clouds). Rainfalls of diamonds have been suggested to occur, as well as on Saturn and the ice giants Uranus and Neptune.</p>
               <br />
               <p>The temperature and pressure inside Jupiter increase steadily inward, this is observed in microwave emission and required because the heat of formation can only escape by convection. At the pressure level of 10 bars (1 MPa), the temperature is around 340 K (67 °C; 152 °F). The hydrogen is always supercritical (that is, it never encounters a first-order phase transition) even as it changes gradually from a molecular fluid to a metallic fluid at around 100–200 GPa, where the temperature is perhaps 5,000 K (4,730 °C; 8,540 °F). The temperature of Jupiter's diluted core is estimated at around 20,000 K (19,700 °C; 35,500 °F) or more with an estimated pressure of around 4,500 GPa.</p>
            </div>
            {/* Atmosphere Of Jupiter */}
            <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Atmosphere Of Jupiter</h2>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter has the deepest planetary atmosphere in the Solar System, spanning over 5,000 km (3,000 mi) in altitude.</p>
            </div>
            {/* Cloud Layers Of Jupiter */}
            <h3 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Cloud Layers Of Jupiter</h3>
            <div className="lg:flex">
               <img alt="ecommerce" className="object-cover object-center mb-3 mr-2 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Map_of_Jupiter.jpg/800px-Map_of_Jupiter.jpg" width="450"/>
               <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/PIA21641-Jupiter-SouthernStorms-JunoCam-20170525.jpg/800px-PIA21641-Jupiter-SouthernStorms-JunoCam-20170525.jpg" width="411.5"/>
            </div>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter is perpetually covered with clouds composed of ammonia crystals, and possibly ammonium hydrosulfide. The clouds are in the tropopause and are in bands of different latitudes, known as tropical regions. These are subdivided into lighter-hued zones and darker belts. The interactions of these conflicting circulation patterns cause storms and turbulence. Wind speeds of 100 metres per second (360 km/h; 220 mph) are common in zonal jet streams. The zones have been observed to vary in width, colour and intensity from year to year, but they have remained sufficiently stable for scientists to name them.</p>
               <br />
               <p>The cloud layer is about 50 km (31 mi) deep, and consists of at least two decks of clouds: a thick lower deck and a thin clearer region. There may also be a thin layer of water clouds underlying the ammonia layer. Supporting the presence of water clouds are the flashes of lightning detected in the atmosphere of Jupiter. These electrical discharges can be up to a thousand times as powerful as lightning on Earth. The water clouds are assumed to generate thunderstorms in the same way as terrestrial thunderstorms, driven by the heat rising from the interior. The Juno mission revealed the presence of "shallow lightning" which originates from ammonia-water clouds relatively high in the atmosphere. These discharges carry "mushballs" of water-ammonia slushes covered in ice, which fall deep into the atmosphere. Upper-atmospheric lightning has been observed in Jupiter's upper atmosphere, bright flashes of light that last around 1.4 milliseconds. These are known as "elves" or "sprites" and appear blue or pink due to the hydrogen.</p>
               <br />
               <p>The orange and brown colours in the clouds of Jupiter are caused by upwelling compounds that change colour when they are exposed to ultraviolet light from the Sun. The exact makeup remains uncertain, but the substances are thought to be phosphorus, sulfur or possibly hydrocarbons. These colourful compounds, known as chromophores, mix with the warmer lower deck of clouds. The zones are formed when rising convection cells form crystallising ammonia that masks out these lower clouds from view.</p>
               <br />
               <p>Jupiter's low axial tilt means that the poles always receive less solar radiation than the planet's equatorial region. Convection within the interior of the planet transports energy to the poles, balancing out the temperatures at the cloud layer.</p>
            </div>
            {/* Great Red Spot & Other Vortices Of Jupiter */}
            <h3 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Great Red Spot & Other Vortices Of Jupiter</h3>
            <img alt="ecommerce" className="object-cover object-center mb-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/a/a3/790106-0203_Voyager_58M_to_31M_reduced.gif" width="650"/>
            <div className="xl:w-1/2 md:w-10/12 mt-4 mb-5">
               <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Time-lapse sequence from the approach of Voyager 1, showing the motion of atmospheric bands and circulation of the Great Red Spot. Recorded over 32 days with one photograph taken every 10 hours (once per Jovian day).</h3>
            </div>
            <div className="lg:w-2/3 w-full">
               <p>The best known feature of Jupiter is the Great Red Spot, a persistent anticyclonic storm located 22° south of the equator. It is known to have existed since at least 1831, and possibly since 1665. Images by the Hubble Space Telescope have shown as many as two "red spots" adjacent to the Great Red Spot. The storm is visible through Earth-based telescopes with an aperture of 12 cm or larger. The oval object rotates counterclockwise, with a period of about six days. The maximum altitude of this storm is about 8 km (5 mi) above the surrounding cloudtops. The Spot's composition and the source of its red color remain uncertain, although photodissociated ammonia reacting with acetylene is a robust candidate to explain the coloration.</p>
               <br />
               <p>The Great Red Spot is larger than the Earth. Mathematical models suggest that the storm is stable and will be a permanent feature of the planet. However, it has significantly decreased in size since its discovery. Initial observations in the late 1800s showed it to be approximately 41,000 km (25,500 mi) across. By the time of the Voyager flybys in 1979, the storm had a length of 23,300 km (14,500 mi) and a width of approximately 13,000 km (8,000 mi). Hubble observations in 1995 showed it had decreased in size to 20,950 km (13,020 mi), and observations in 2009 showed the size to be 17,910 km (11,130 mi). As of 2015, the storm was measured at approximately 16,500 by 10,940 km (10,250 by 6,800 mi), and was decreasing in length by about 930 km (580 mi) per year.</p>
               <br />
               <p>Juno missions show that there are several polar cyclone groups at Jupiter's poles. The northern group contains nine cyclones, with a large one in the center and eight others around it, while its southern counterpart also consists of a center vortex but is surrounded by five large storms and a single smaller one. These polar structures are caused by the turbulence in Jupiter's atmosphere and can be compared with the hexagon at Saturn's north pole. </p>
               <br />
               <p>In 2000, an atmospheric feature formed in the southern hemisphere that is similar in appearance to the Great Red Spot, but smaller. This was created when smaller, white oval-shaped storms merged to form a single feature—these three smaller white ovals were first observed in 1938. The merged feature was named Oval BA and has been nicknamed "Red Spot Junior." It has since increased in intensity and changed from white to red.</p>
               <br />
               <p>In April 2017, a "Great Cold Spot" was discovered in Jupiter's thermosphere at its north pole. This feature is 24,000 km (15,000 mi) across, 12,000 km (7,500 mi) wide, and 200 °C (360 °F) cooler than surrounding material. While this spot changes form and intensity over the short term, it has maintained its general position in the atmosphere for more than 15 years. It may be a giant vortex similar to the Great Red Spot, and appears to be quasi-stable like the vortices in Earth's thermosphere. Interactions between charged particles generated from Io and the planet's strong magnetic field likely resulted in redistribution of heat flow, forming the Spot.</p>
            </div>
            {/* Magnetosphere Of Jupiter */}
            <h3 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Magnetosphere Of Jupiter</h3>
            <img alt="ecommerce" className="object-cover object-center mt-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/9/91/PIA23465-PlanetJupiter-Aurorae-20191001.gif" width="650"/>
            <div className="mt-4 mb-5">
               <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Aurorae on the north and south poles (animation)</h3>
            </div>
            <div className="lg:w-2/3 w-full">
               <p>Jupiter's magnetic field is fourteen times stronger than Earth's, ranging from 4.2 gauss (0.42 mT) at the equator to 10–14 gauss (1.0–1.4 mT) at the poles, making it the strongest in the Solar System (except for sunspots). This field is thought to be generated by eddy currents—swirling movements of conducting materials—within the liquid metallic hydrogen core. The volcanoes on the moon Io emit large amounts of sulfur dioxide, forming a gas torus along the moon's orbit. The gas is ionised in the magnetosphere, producing sulfur and oxygen ions. They, together with hydrogen ions originating from the atmosphere of Jupiter, form a plasma sheet in Jupiter's equatorial plane. The plasma in the sheet co-rotates with the planet, causing deformation of the dipole magnetic field into that of a magnetodisk. Electrons within the plasma sheet generate a strong radio signature that produces bursts in the range of 0.6–30 MHz which are detectable from Earth with consumer-grade shortwave radio receivers.</p>
               <br />
               <p>At about 75 Jupiter radii from the planet, the interaction of the magnetosphere with the solar wind generates a bow shock. Surrounding Jupiter's magnetosphere is a magnetopause, located at the inner edge of a magnetosheath—a region between it and the bow shock. The solar wind interacts with these regions, elongating the magnetosphere on Jupiter's lee side and extending it outward until it nearly reaches the orbit of Saturn. The four largest moons of Jupiter all orbit within the magnetosphere, which protects them from the solar wind.</p>
               <br />
               <p>The magnetosphere of Jupiter is responsible for intense episodes of radio emission from the planet's polar regions. Volcanic activity on Jupiter's moon Io injects gas into Jupiter's magnetosphere, producing a torus of particles about the planet. As Io moves through this torus, the interaction generates Alfvén waves that carry ionised matter into the polar regions of Jupiter. As a result, radio waves are generated through a cyclotron maser mechanism, and the energy is transmitted out along a cone-shaped surface. When Earth intersects this cone, the radio emissions from Jupiter can exceed the solar radio output.</p>
            </div>
            <div className="lg:flex">
               <div className="w-full">
                  <img alt="ecommerce" className="object-cover object-center mt-3 mr-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Hubble_Captures_Vivid_Auroras_in_Jupiter%27s_Atmosphere.jpg/1024px-Hubble_Captures_Vivid_Auroras_in_Jupiter%27s_Atmosphere.jpg" width="350"/>
                  <div className="mt-4 mb-5 text-center">
                     <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Aurorae on the north pole (Hubble)</h3>
                  </div>
               </div>
               <div className="w-full">
                  <img alt="ecommerce" className="object-cover object-center mt-3 mr-3 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/4/4f/PIA21033_Juno%27s_View_of_Jupiter%27s_Southern_Lights.jpg" width="350"/>
                  <div className="mt-4 mb-5 text-center">
                     <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Infrared view of southern lights (Jovian IR Mapper)</h3>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </>
   )
}

export default Jupiter;
