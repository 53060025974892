import React from 'react';

export default function Moon() {
  return (
    <>
      {/* HeroSection */}
      <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
              <img className="object-cover object-center rounded" alt="Venus Image" src="https://earthsky.org/upl/2018/10/moon-waxing-crescent-earthshine-1-8-2019-Chuck-Reinhart-Vincennes-IN-e1547258318928.jpg"/>
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Moon</h1>
              <p className="mb-8 leading-relaxed">The Moon is Earth's only proper natural satellite. At one-quarter the diameter of Earth, it is the largest natural satellite in the Solar System relative to the size of its planet, and the fifth largest satellite in the Solar System overall.</p>
            </div>
          </div>
      </section>

      <hr />

      {/* ImfoOfMoon */}
      <section className="text-gray-600 body-font">
        {/* Formation Of Moon */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Formation Of Moon</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block mb-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Moon_-_Giant_Impact_Hypothesis_-_Simple_model.png/449px-Moon_-_Giant_Impact_Hypothesis_-_Simple_model.png" width="650"/>
          <div className="lg:w-2/3 w-full">
            <p>The Moon formed 4.51 billion years ago, or even 100 million years earlier, some 50 million years after the origin of the Solar System, as research published in 2019 suggests. Several forming mechanisms have been proposed, including the fission of the Moon from Earth's crust through centrifugal force (which would require too great an initial rotation rate of Earth), the gravitational capture of a pre-formed Moon (which would require an unfeasibly extended atmosphere of Earth to dissipate the energy of the passing Moon), and the co-formation of Earth and the Moon together in the primordial accretion disk (which does not explain the depletion of metals in the Moon). These hypotheses also cannot account for the high angular momentum of the Earth–Moon system.</p>
            <br/>
            <p>The prevailing theory is that the Earth–Moon system formed after a giant impact of a Mars-sized body (named Theia) with the proto-Earth. The impact blasted material into Earth's orbit and then the material accreted and formed the Moon just beyond the Earth's Roche limit of ~2.56 R<sub>⊕</sub>.</p>
            <br/>
            <p>This theory best explains the evidence. Eighteen months prior to an October 1984 conference on lunar origins, Bill Hartmann, Roger Phillips, and Jeff Taylor challenged fellow lunar scientists: "You have eighteen months. Go back to your Apollo data, go back to your computer, do whatever you have to, but make up your mind. Don't come to our conference unless you have something to say about the Moon's birth." At the 1984 conference at Kona, Hawaii, the giant-impact hypothesis emerged as the most consensual.</p>
            <br/>
            <p>Before the conference, there were partisans of the three "traditional" theories, plus a few people who were starting to take the giant impact seriously, and there was a huge apathetic middle who didn't think the debate would ever be resolved. Afterward, there were essentially only two groups: the giant impact camp and the agnostics.</p>
            <br/>
            <p>Giant impacts are thought to have been common in the early Solar System. Computer simulations of giant impacts have produced results that are consistent with the mass of the lunar core and the angular momentum of the Earth–Moon system. These simulations also show that most of the Moon derived from the impactor, rather than the proto-Earth. However, more recent simulations suggest a larger fraction of the Moon derived from the proto-Earth. Other bodies of the inner Solar System such as Mars and Vesta have, according to meteorites from them, very different oxygen and tungsten isotopic compositions compared to Earth. However, Earth and the Moon have nearly identical isotopic compositions. The isotopic equalization of the Earth-Moon system might be explained by the post-impact mixing of the vaporized material that formed the two, although this is debated.</p>
            <br/>
            <p>The impact released a lot of energy and then the released material re-accreted into the Earth–Moon system. This would have melted the outer shell of Earth, and thus formed a magma Similarly, the newly formed Moon would also have been affected and had its own lunar magma ocean; its depth is estimated from about 500 km (300 miles) to 1,737 km (1,079 miles).</p>
            <br/>
            <p>While the giant-impact theory explains many lines of evidence, some questions are still unresolved, most of which involve the Moon's composition.</p>
            <br/>
            <p>In 2001, a team at the Carnegie Institute of Washington reported the most precise measurement of the isotopic signatures of lunar rocks. The rocks from the Apollo program had the same isotopic signature as rocks from Earth, differing from almost all other bodies in the Solar System. This observation was unexpected, because most of the material that formed the Moon was thought to come from Theia and it was announced in 2007 that there was less than a 1% chance that Theia and Earth had identical isotopic signatures. Other Apollo lunar samples had in 2012 the same titanium isotopes composition as Earth, which conflicts with what is expected if the Moon formed far from Earth or is derived from Theia. These discrepancies may be explained by variations of the giant-impact theory.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center h-full block mt-5" src="https://upload.wikimedia.org/wikipedia/commons/8/89/14-236-LunarGrailMission-OceanusProcellarum-Rifts-Overall-20141001.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Ancient rift valleys – rectangular structure (visible – topography – GRAIL gravity gradients)</h3>
          </div>
        </div>
              
        <hr/>

        {/* Physical Characteristics Of Moon */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Physical Characteristics Of Moon</h1>
          <div className="lg:w-2/3 w-full">
            <p>The Moon is a very slightly scalene ellipsoid due to tidal stretching, with its long axis displaced 30° from facing the Earth (due to gravitational anomalies from impact basins). Its shape is more elongated than current tidal forces can account for. This 'fossil bulge' indicates that the Moon solidified when it orbited at half its current distance to the Earth, and that it is now too cold for its shape to adjust to its orbit.</p>
          </div>
          {/* Internal Structure Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Internal Structure Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://i.pinimg.com/originals/ff/ae/b4/ffaeb47db46ebd8b14264d645d7b8849.jpg" width="450"/>
          <div className="lg:w-2/3 w-full">
            <p>The Moon is a differentiated body. It has a geochemically distinct crust, mantle, and core. The Moon has a solid iron-rich inner core with a radius possibly as small as 240 kilometres (150 mi) and a fluid outer core primarily made of liquid iron with a radius of roughly 300 kilometres (190 mi). Around the core is a partially molten boundary layer with a radius of about 500 kilometres (310 mi). This structure is thought to have developed through the fractional crystallization of a global magma ocean shortly after the Moon's formation 4.5 billion years ago.</p>
            <br/>
            <p>Crystallization of this magma ocean would have created a mafic mantle from the precipitation and sinking of the minerals olivine, clinopyroxene, and orthopyroxene; after about three-quarters of the magma ocean had crystallised, lower-density plagioclase minerals could form and float into a crust atop. The final liquids to crystallise would have been initially sandwiched between the crust and mantle, with a high abundance of incompatible and heat-producing elements.</p>
            <br/>
            <p>Consistent with this perspective, geochemical mapping made from orbit suggests the crust of mostly anorthosite. The Moon rock samples of the flood lavas that erupted onto the surface from partial melting in the mantle confirm the mafic mantle composition, which is more iron-rich than that of Earth. The crust is on average about 50 kilometres (31 mi) thick.</p>
            <br/>
            <p>The Moon is the second-densest satellite in the Solar System, after Io. However, the inner core of the Moon is small, with a radius of about 350 kilometres (220 mi) or less, around 20% of the radius of the Moon. Its composition is not well understood, but is probably metallic iron alloyed with a small amount of sulfur and nickel; analyses of the Moon's time-variable rotation suggest that it is at least partly molten.</p>
          </div>
          {/* Surface Geology Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Surface Geology Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block mb-5" src="https://upload.wikimedia.org/wikipedia/commons/3/32/Near_and_far_sides_of_Moon%2C_small_geological_map.jpg" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Geological features of the Moon (near side / north pole at left, far side / south pole at right)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The topography of the Moon has been measured with laser altimetry and stereo image analysis. Its most visible topographic feature is the giant far-side South Pole–Aitken basin, some 2,240 km (1,390 mi) in diameter, the largest crater on the Moon and the second-largest confirmed impact crater in the Solar System. At 13 km (8.1 mi) deep, its floor is the lowest point on the surface of the Moon. The highest elevations of the surface are located directly to the northeast, and it has been suggested might have been thickened by the oblique formation impact of the South Pole–Aitken basin. Other large impact basins such as Imbrium, Serenitatis, Crisium, Smythii, and Orientale also possess regionally low elevations and elevated rims. The far side of the lunar surface is on average about 1.9 km (1.2 mi) higher than that of the near side.</p>
            <br/>
            <p>The discovery of fault scarp cliffs by the Lunar Reconnaissance Orbiter suggest that the Moon has shrunk within the past billion years, by about 90 metres (300 ft). Similar shrinkage features exist on Mercury. A recent study of over 12000 images from the orbiter has observed that Mare Frigoris near the north pole, a vast basin assumed to be geologically dead, has been cracking and shifting. Since the Moon doesn't have tectonic plates, its tectonic activity is slow and cracks develop as it loses heat over the years.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center h-full block mt-5" src="https://upload.wikimedia.org/wikipedia/commons/b/b0/MoonTopoLOLA.png" width="650"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Topography of the Moon</h3>
          </div>
          {/* Volcanic Features Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Volcanic Features Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/14284-Moon-Maskelyne-LRO-20141012.jpg/800px-14284-Moon-Maskelyne-LRO-20141012.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Evidence of young lunar volcanism</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The dark and relatively featureless lunar plains, clearly seen with the naked eye, are called maria (Latin for "seas"; singular mare), as they were once believed to be filled with water; they are now known to be vast solidified pools of ancient basaltic lava. Although similar to terrestrial basalts, lunar basalts have more iron and no minerals altered by water. The majority of these lavas erupted or flowed into the depressions associated with impact basins. Several geologic provinces containing shield volcanoes and volcanic domes are found within the near side "maria".</p>
            <br/>
            <p>Almost all maria are on the near side of the Moon, and cover 31% of the surface of the near side, compared with 2% of the far side. This is thought to be due to a concentration of heat-producing elements under the crust on the near side, seen on geochemical maps obtained by Lunar Prospector's gamma-ray spectrometer, which would have caused the underlying mantle to heat up, partially melt, rise to the surface and erupt. Most of the Moon's mare basalts erupted during the Imbrian period, 3.0–3.5 billion years ago, although some radiometrically dated samples are as old as 4.2 billion years. Until recently, the youngest eruptions, dated by crater counting, appeared to have been only 1.2 billion years ago. In 2006, a study of Ina, a tiny depression in Lacus Felicitatis, found jagged, relatively dust-free features that, because of the lack of erosion by infalling debris, appeared to be only 2 million years old. Moonquakes and releases of gas also indicate some continued lunar activity. In 2014 NASA announced "widespread evidence of young lunar volcanism" at 70 irregular mare patches identified by the Lunar Reconnaissance Orbiter, some less than 50 million years old. This raises the possibility of a much warmer lunar mantle than previously believed, at least on the near side where the deep crust is substantially warmer because of the greater concentration of radioactive elements. Just prior to this, evidence has been presented for 2–10 million years younger basaltic volcanism inside the crater Lowell, Orientale basin, located in the transition zone between the near and far sides of the Moon. An initially hotter mantle and/or local enrichment of heat-producing elements in the mantle could be responsible for prolonged activities also on the far side in the Orientale basin.</p>
            <br/>
            <p>The lighter-colored regions of the Moon are called terrae, or more commonly highlands, because they are higher than most maria. They have been radiometrically dated to having formed 4.4 billion years ago, and may represent plagioclase cumulates of the lunar magma ocean. In contrast to Earth, no major lunar mountains are believed to have formed as a result of tectonic events.</p>
            <br/>
            <p>The concentration of maria on the Near Side likely reflects the substantially thicker crust of the highlands of the Far Side, which may have formed in a slow-velocity impact of a second moon of Earth a few tens of millions of years after their formation.</p>
          </div>
          {/* Impact Craters Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Impact Craters Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/d/d4/Moon-craters.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Lunar crater Daedalus on the Moon's far side</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The other major geologic process that has affected the Moon's surface is impact cratering, with craters formed when asteroids and comets collide with the lunar surface. There are estimated to be roughly 300,000 craters wider than 1 km (0.6 mi) on the Moon's near side alone. The lunar geologic timescale is based on the most prominent impact events, including Nectaris, Imbrium, and Orientale, structures characterized by multiple rings of uplifted material, between hundreds and thousands of kilometers in diameter and associated with a broad apron of ejecta deposits that form a regional stratigraphic horizon. The lack of an atmosphere, weather and recent geological processes mean that many of these craters are well-preserved. Although only a few multi-ring basins have been definitively dated, they are useful for assigning relative ages. Because impact craters accumulate at a nearly constant rate, counting the number of craters per unit area can be used to estimate the age of the surface. The radiometric ages of impact-melted rocks collected during the Apollo missions cluster between 3.8 and 4.1 billion years old: this has been used to propose a Late Heavy Bombardment of impacts.</p>
            <br/>
            <p>Blanketed on top of the Moon's crust is a highly comminuted (broken into ever smaller particles) and impact gardened surface layer called regolith, formed by impact processes. The finer regolith, the lunar soil of silicon dioxide glass, has a texture resembling snow and a scent resembling spent gunpowder. The regolith of older surfaces is generally thicker than for younger surfaces: it varies in thickness from 10–20 km (6.2–12.4 mi) in the highlands and 3–5 km (1.9–3.1 mi) in the maria. Beneath the finely comminuted regolith layer is the megaregolith, a layer of highly fractured bedrock many kilometers thick.</p>
            <br/>
            <p>Comparison of high-resolution images obtained by the Lunar Reconnaissance Orbiter has shown a contemporary crater-production rate significantly higher than previously estimated. A secondary cratering process caused by distal ejecta is thought to churn the top two centimeters of regolith a hundred times more quickly than previous models suggested – on a timescale of 81,000 years.</p>
          </div>
          {/* Lunar Swirls Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Lunar Swirls Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/cf/Reiner-gamma-clem1.jpg" width="450"/>
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Lunar swirls at Reiner Gamma</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Lunar swirls are enigmatic features found across the Moon's surface. They are characterized by a high albedo, appear optically immature (i.e. the optical characteristics of a relatively young regolith), and have often a sinuous shape. Their shape is often accentuated by low albedo regions that wind between the bright swirls.</p>
          </div>
          {/* Presence Of Water On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Presence Of Water On Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>Liquid water cannot persist on the lunar surface. When exposed to solar radiation, water quickly decomposes through a process known as photodissociation and is lost to space. However, since the 1960s, scientists have hypothesized that water ice may be deposited by impacting comets or possibly produced by the reaction of oxygen-rich lunar rocks, and hydrogen from solar wind, leaving traces of water which could possibly persist in cold, permanently shadowed craters at either pole on the Moon. Computer simulations suggest that up to 14,000 km2 (5,400 sq mi) of the surface may be in permanent shadow. The presence of usable quantities of water on the Moon is an important factor in rendering lunar habitation as a cost-effective plan; the alternative of transporting water from Earth would be prohibitively expensive.</p>
            <br/>
            <p>In years since, signatures of water have been found to exist on the lunar surface.[103] In 1994, the bistatic radar experiment located on the Clementine spacecraft, indicated the existence of small, frozen pockets of water close to the surface. However, later radar observations by Arecibo, suggest these findings may rather be rocks ejected from young impact craters.[104] In 1998, the neutron spectrometer on the Lunar Prospector spacecraft showed that high concentrations of hydrogen are present in the first meter of depth in the regolith near the polar regions. Volcanic lava beads, brought back to Earth aboard Apollo 15, showed small amounts of water in their interior.</p>
            <br/>
            <p>The 2008 Chandrayaan-1 spacecraft has since confirmed the existence of surface water ice, using the on-board Moon Mineralogy Mapper. The spectrometer observed absorption lines common to hydroxyl, in reflected sunlight, providing evidence of large quantities of water ice, on the lunar surface. The spacecraft showed that concentrations may possibly be as high as 1,000 ppm. Using the mapper's reflectance spectra, indirect lighting of areas in shadow confirmed water ice within 20° latitude of both poles in 2018. In 2009, LCROSS sent a 2,300 kg (5,100 lb) impactor into a permanently shadowed polar crater, and detected at least 100 kg (220 lb) of water in a plume of ejected material. Another examination of the LCROSS data showed the amount of detected water to be closer to 155 ± 12 kg (342 ± 26 lb).</p>
            <br/>
            <p>In May 2011, 615–1410 ppm water in melt inclusions in lunar sample 74220 was reported, the famous high-titanium "orange glass soil" of volcanic origin collected during the Apollo 17 mission in 1972. The inclusions were formed during explosive eruptions on the Moon approximately 3.7 billion years ago. This concentration is comparable with that of magma in Earth's upper mantle. Although of considerable selenological interest, this announcement affords little comfort to would-be lunar colonists – the sample originated many kilometers below the surface, and the inclusions are so difficult to access that it took 39 years to find them with a state-of-the-art ion microprobe instrument.</p>
            <br/>
            <p>Analysis of the findings of the Moon Mineralogy Mapper (M3) revealed in August 2018 for the first time "definitive evidence" for water-ice on the lunar surface. The data revealed the distinct reflective signatures of water-ice, as opposed to dust and other reflective substances. The ice deposits were found on the North and South poles, although it is more abundant in the South, where water is trapped in permanently shadowed craters and crevices, allowing it to persist as ice on the surface since they are shielded from the sun.</p>
            <br/>
            <p>In October 2020, astronomers reported detecting molecular water on the sunlit surface of the Moon by several independent spacecraft, including the Stratospheric Observatory for Infrared Astronomy (SOFIA).</p>
          </div>
          {/* Gravitational Field Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Gravitational Field Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block mb-5" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/GRAIL%27s_gravity_map_of_the_moon.jpg/1280px-GRAIL%27s_gravity_map_of_the_moon.jpg" width="450"/>
          <div className="lg:w-2/3 w-full">
            <p>The gravity of Earth is the acceleration that is imparted to objects due to the distribution of mass within Earth. Near Earth's surface, gravitational acceleration is approximately 9.8 m/s<sup>2</sup> (32 ft/s<sup>2</sup>). Local differences in topography, geology, and deeper tectonic structure cause local and broad, regional differences in Earth's gravitational field, known as gravity anomalies.</p>
          </div>
          {/* Magnetic Field Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Magnetic Field Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>The Moon has an external magnetic field of generally less than 0.2 nanoteslas, or less than one hundred thousandth that of Earth. The Moon does not currently have a global dipolar magnetic field and only has crustal magnetization likely acquired early in its history when a dynamo was still operating. However, early in its history, 4 billion years ago, its magnetic field strength was likely close to that of Earth today. This early dynamo field apparently expired by about one billion years ago, after the lunar core had completely crystallized. Theoretically, some of the remnant magnetization may originate from transient magnetic fields generated during large impacts through the expansion of plasma clouds. These clouds are generated during large impacts in an ambient magnetic field. This is supported by the location of the largest crustal magnetizations situated near the antipodes of the giant impact basins.</p>
          </div>
          {/* Atmosphere Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Atmosphere Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>The Moon has an atmosphere so tenuous as to be nearly vacuum, with a total mass of less than 10 tonnes (9.8 long tons; 11 short tons). The surface pressure of this small mass is around 3 × 10−15 atm (0.3 nPa); it varies with the lunar day. Its sources include outgassing and sputtering, a product of the bombardment of lunar soil by solar wind ions. Elements that have been detected include sodium and potassium, produced by sputtering (also found in the atmospheres of Mercury and Io); helium-4 and neon from the solar wind; and argon-40, radon-222, and polonium-210, outgassed after their creation by radioactive decay within the crust and mantle. The absence of such neutral species (atoms or molecules) as oxygen, nitrogen, carbon, hydrogen and magnesium, which are present in the regolith, is not understood. Water vapor has been detected by Chandrayaan-1 and found to vary with latitude, with a maximum at ~60–70 degrees; it is possibly generated from the sublimation of water ice in the regolith. These gases either return into the regolith because of the Moon's gravity or are lost to space, either through solar radiation pressure or, if they are ionized, by being swept away by the solar wind's magnetic field.</p>                  
          </div>
          {/* Dust Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Dust Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>A permanent asymmetric Moon dust cloud exists around the Moon, created by small particles from comets. Estimates are 5 tons of comet particles strike the Moon's surface every 24 hours. The particles striking the Moon's surface eject Moon dust above the Moon. The dust stays above the Moon approximately 10 minutes, taking 5 minutes to rise, and 5 minutes to fall. On average, 120 kilograms of dust are present above the Moon, rising to 100 kilometers above the surface. The dust measurements were made by LADEE's Lunar Dust EXperiment (LDEX), between 20 and 100 kilometers above the surface, during a six-month period. LDEX detected an average of one 0.3 micrometer Moon dust particle each minute. Dust particle counts peaked during the Geminid, Quadrantid, Northern Taurid, and Omicron Centaurid meteor showers, when the Earth, and Moon, pass through comet debris. The cloud is asymmetric, more dense near the boundary between the Moon's dayside and nightside.</p>                  
          </div>
          {/* Past Thicker Atmosphere Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Past Thicker Atmosphere Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>In October 2017, NASA scientists at the Marshall Space Flight Center and the Lunar and Planetary Institute in Houston announced their finding, based on studies of Moon magma samples retrieved by the Apollo missions, that the Moon had once possessed a relatively thick atmosphere for a period of 70 million years between 3 and 4 billion years ago. This atmosphere, sourced from gases ejected from lunar volcanic eruptions, was twice the thickness of that of present-day Mars. The ancient lunar atmosphere was eventually stripped away by solar winds and dissipated into space.</p>                  
          </div>
          {/* Seasons Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Seasons Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>The Moon's axial tilt with respect to the ecliptic is only 1.5424°, much less than the 23.44° of Earth. Because of this, the Moon's solar illumination varies much less with season, and topographical details play a crucial role in seasonal effects. From images taken by Clementine in 1994, it appears that four mountainous regions on the rim of the crater Peary at the Moon's north pole may remain illuminated for the entire lunar day, creating peaks of eternal light. No such regions exist at the south pole. Similarly, there are places that remain in permanent shadow at the bottoms of many polar craters, and these "craters of eternal darkness" are extremely cold: Lunar Reconnaissance Orbiter measured the lowest summer temperatures in craters at the southern pole at 35 K (−238 °C; −397 °F) and just 26 K (−247 °C; −413 °F) close to the winter solstice in the north polar crater Hermite. This is the coldest temperature in the Solar System ever measured by a spacecraft, colder even than the surface of Pluto. Average temperatures of the Moon's surface are reported, but temperatures of different areas will vary greatly depending upon whether they are in sunlight or shadow.</p>                  
          </div>
          {/* Rotation Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-10 mb-10 font-medium text-gray-900">Rotation Of Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>The Moon is rotating around its own axis. This rotation is due to tidal locking synchronous to its orbital period around Earth.</p>    
            <br />              
            <p>The rotation period depends on the frame of reference. There are sidereal rotation periods (or sidereal day, in relation to the stars), and synodic rotation periods (or synodic day, in relation to the Sun). A lunar day is a synodic day.</p>
            <br />
            <p>Because of the tidal locked rotation, the sidereal and synodic rotation periods correspond to the sidereal (27.3 Earth days) and synodic (29.5 Earth days) orbital periods.</p>
          </div>
        </div>

        <hr />
        
        {/* Earth–Moon System Of Moon */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Earth–Moon System Of Moon</h1>
          {/* Lunar Distance Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Lunar Distance Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Earth-moon-to-scale.svg/1920px-Earth-moon-to-scale.svg.png" width="1000"/>
          <div className="lg:w-2/3 w-full">
            <p>Lunar distance, also called Earth–Moon distance, Earth–Moon characteristic distance, or distance to the Moon, is a unit of measure in astronomy. It is the average distance from the center of Earth to the center of the Moon. More technically, it is the mean semi-major axis of the geocentric lunar orbit. It may also refer to the time-averaged distance between the centers of the Earth and the Moon, or less commonly, the instantaneous Earth–Moon distance. The lunar distance is approximately 400,000 km, which is a quarter of a million miles or 1.28 light-seconds. This is roughly Earth's circumference times ten, its diameter times thirty or 1/389 of Earth's distance to the Sun (the astronomical unit).</p>
          </div>
          {/* Orbit Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Orbit Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Earth-Moon.svg/1280px-Earth-Moon.svg.png" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Earth–Moon system (schematic)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The Moon makes a complete orbit around Earth with respect to the fixed stars about once every 27.3 days (its sidereal period). However, because Earth is moving in its orbit around the Sun at the same time, it takes slightly longer for the Moon to show the same phase to Earth, which is about 29.5 days (its synodic period). Unlike most satellites of other planets, the Moon orbits closer to the ecliptic plane than to the planet's equatorial plane. The Moon's orbit is subtly perturbed by the Sun and Earth in many small, complex and interacting ways. For example, the plane of the Moon's orbit gradually rotates once every 18.61 years, which affects other aspects of lunar motion. These follow-on effects are mathematically described by Cassini's laws.</p>
          </div>
          {/* Relative Size Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Relative Size Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Dscovrepicmoontransitfull.gif/800px-Dscovrepicmoontransitfull.gif" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">DSCOVR satellite sees the Moon passing in front of Earth</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The Moon is an exceptionally large natural satellite relative to Earth: Its diameter is more than a quarter and its mass is 1/81 of Earth's. It is the largest moon in the Solar System relative to the size of its planet, though Charon is larger relative to the dwarf planet Pluto, at 1/9 Pluto's mass. The Earth and the Moon's barycentre, their common center of mass, is located 1,700 km (1,100 mi) beneath the Earth's surface.</p>
            <br />
            <p>The Earth revolves around the Earth-Moon barycentre once a sidereal month, with 1/81 the speed of the Moon, or about 12.5 metres (41 ft) per second. This motion is superimposed on the much larger revolution of the Earth around the Sun at a speed of about 30 kilometres (19 mi) per second.</p>
            <br />
            <p>The surface area of the Moon is slightly less than the areas of North and South America combined.</p>
          </div>
          {/* Appearance From Earth */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Appearance From Earth</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1024px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" width="550" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The Moon is prominently featured in Vincent van Gogh's 1889 painting, The Starry Night</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The Moon is in synchronous rotation as it orbits Earth; it rotates about its axis in about the same time it takes to orbit Earth. This results in it always keeping nearly the same face turned towards Earth. However, because of the effect of libration, about 59% of the Moon's surface can actually be seen from Earth. The side of the Moon that faces Earth is called the near side, and the opposite the far side. The far side is often inaccurately called the "dark side", but it is in fact illuminated as often as the near side: once every 29.5 Earth days. During new moon, the near side is dark.</p>
            <br />
            <p>The Moon had once rotated at a faster rate, but early in its history its rotation slowed and became tidally locked in this orientation as a result of frictional effects associated with tidal deformations caused by Earth. With time, the energy of rotation of the Moon on its axis was dissipated as heat, until there was no rotation of the Moon relative to Earth. In 2016, planetary scientists using data collected on the much earlier NASA Lunar Prospector mission, found two hydrogen-rich areas (most likely former water ice) on opposite sides of the Moon. It is speculated that these patches were the poles of the Moon billions of years ago before it was tidally locked to Earth.</p>
            <br />
            <p>The Moon has an exceptionally low albedo, giving it a reflectance that is slightly brighter than that of worn asphalt. Despite this, it is the brightest object in the sky after the Sun. This is due partly to the brightness enhancement of the opposition surge; the Moon at quarter phase is only one-tenth as bright, rather than half as bright, as at full moon. Additionally, color constancy in the visual system recalibrates the relations between the colors of an object and its surroundings, and because the surrounding sky is comparatively dark, the sunlit Moon is perceived as a bright object. The edges of the full moon seem as bright as the center, without limb darkening, because of the reflective properties of lunar soil, which retroreflects light more towards the Sun than in other directions. The Moon does appear larger when close to the horizon, but this is a purely psychological effect, known as the moon illusion, first described in the 7th century BC. The full Moon's angular diameter is about 0.52° (on average) in the sky, roughly the same apparent size as the Sun (see § Eclipses)</p>
            <br />
            <p>The Moon's highest altitude at culmination varies by its phase and time of year. The full moon is highest in the sky during winter (for each hemisphere). The orientation of the Moon's crescent also depends on the latitude of the viewing location; an observer in the tropics can see a smile-shaped crescent Moon. The Moon is visible for two weeks every 27.3 days at the North and South Poles. Zooplankton in the Arctic use moonlight when the Sun is below the horizon for months on end.</p>
            <br />
            <p>The distance between the Moon and Earth varies from around 356,400 km (221,500 mi) to 406,700 km (252,700 mi) at perigee (closest) and apogee (farthest), respectively. On 14 November 2016, it was closer to Earth when at full phase than it has been since 1948, 14% closer than its farthest position in apogee. Reported as a "supermoon", this closest point coincided within an hour of a full moon, and it was 30% more luminous than when at its greatest distance because its angular diameter is 14% greater. At lower levels, the human perception of reduced brightness as a percentage is provided by the following formula</p>
            <img alt="ecommerce" className="object-cover object-center my-3 h-full block" src="https://wikimedia.org/api/rest_v1/media/math/render/svg/dd5a098d1ccd406ae5d1826bbfdcde7cfa60220c" width="450" />
            <p>When the actual reduction is 1.00 / 1.30, or about 0.770, the perceived reduction is about 0.877, or 1.00 / 1.14. This gives a maximum perceived increase of 14% between apogee and perigee moons of the same phase.</p>
            <br />
            <p>There has been historical controversy over whether features on the Moon's surface change over time. Today, many of these claims are thought to be illusory, resulting from observation under different lighting conditions, poor astronomical seeing, or inadequate drawings. However, outgassing does occasionally occur and could be responsible for a minor percentage of the reported lunar transient phenomena. Recently, it has been suggested that a roughly 3 km (1.9 mi) diameter region of the lunar surface was modified by a gas release event about a million years ago.</p>
            <br />
            <p>The Moon's appearance, like the Sun's, can be affected by Earth's atmosphere. Common optical effects are the 22° halo ring, formed when the Moon's light is refracted through the ice crystals of high cirrostratus clouds, and smaller coronal rings when the Moon is seen through thin clouds.</p>
            <br />
            <p>The illuminated area of the visible sphere (degree of illumination) is given by <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/a333005860c046c66303f7be22e19e89e9935ee7" alt="Formula" className="inline-block" /> , where <img src="https://wikimedia.org/api/rest_v1/media/math/render/svg/cd253103f0876afc68ebead27a5aa9867d927467" alt="Formula" className="inline-block" /> is the elongation (i.e., the angle between Moon, the observer (on Earth) and the Sun).</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Moon_phases_en.jpg/1920px-Moon_phases_en.jpg" width="750" />
          {/* Tidal Effects Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Tidal Effects Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/ca/Lunar_libration_with_phase_Oct_2007_%28continuous_loop%29.gif" width="550" />
          <div className="lg:w-2/3 w-full">
            <p>The gravitational attraction that masses have for one another decreases inversely with the square of the distance of those masses from each other. As a result, the slightly greater attraction that the Moon has for the side of Earth closest to the Moon, as compared to the part of the Earth opposite the Moon, results in tidal forces. Tidal forces affect both the Earth's crust and oceans.</p>
            <br />
            <p>The most obvious effect of tidal forces is to cause two bulges in the Earth's oceans, one on the side facing the Moon and the other on the side opposite. This results in elevated sea levels called ocean tides. As the Earth rotates on its axis, one of the ocean bulges (high tide) is held in place "under" the Moon, while another such tide is opposite. As a result, there are two high tides, and two low tides in about 24 hours. Since the Moon is orbiting the Earth in the same direction of the Earth's rotation, the high tides occur about every 12 hours and 25 minutes; the 25 minutes is due to the Moon's time to orbit the Earth. The Sun has the same tidal effect on the Earth, but its forces of attraction are only 40% that of the Moon's; the Sun's and Moon's interplay is responsible for spring and neap tides. If the Earth were a water world (one with no continents) it would produce a tide of only one meter, and that tide would be very predictable, but the ocean tides are greatly modified by other effects: the frictional coupling of water to Earth's rotation through the ocean floors, the inertia of water's movement, ocean basins that grow shallower near land, the sloshing of water between different ocean basins. As a result, the timing of the tides at most points on the Earth is a product of observations that are explained, incidentally, by theory.</p>
            <br />
            <p>While gravitation causes acceleration and movement of the Earth's fluid oceans, gravitational coupling between the Moon and Earth's solid body is mostly elastic and plastic. The result is a further tidal effect of the Moon on the Earth that causes a bulge of the solid portion of the Earth nearest the Moon that acts as a torque in opposition to the Earth's rotation. This "drains" angular momentum and rotational kinetic energy from Earth's rotation, slowing the Earth's rotation. That angular momentum, lost from the Earth, is transferred to the Moon in a process (confusingly known as tidal acceleration), which lifts the Moon into a higher orbit and results in its lower orbital speed about the Earth. Thus the distance between Earth and Moon is increasing, and the Earth's rotation is slowing in reaction. Measurements from laser reflectors left during the Apollo missions (lunar ranging experiments) have found that the Moon's distance increases by 38 mm (1.5 in) per year (roughly the rate at which human fingernails grow). Atomic clocks also show that Earth's day lengthens by about 15 microseconds every year, slowly increasing the rate at which UTC is adjusted by leap seconds. This tidal drag would continue until the rotation of Earth and the orbital period of the Moon matched, creating mutual tidal locking between the two and suspending the Moon over one meridian (this is currently the case with Pluto and its moon Charon). However, the Sun will become a red giant engulfing the Earth-Moon system long before this occurrence. If this tidal locking did happen, the rotation of the Earth would continue to slow down because of the tides caused by the Sun. With the day longer than the month, the Moon would then move slowly from west to east in the sky. The tides caused by the Moon would then cause the opposite effect from before, and the Moon would get closer to the Earth. It would eventually come within the Roche limit and be broken up into a ring.</p>
            <br />
            <p>In a like manner, the lunar surface experiences tides of around 10 cm (4 in) amplitude over 27 days, with two components: a fixed one due to Earth, because they are in synchronous rotation, and a varying component from the Sun. The Earth-induced component arises from libration, a result of the Moon's orbital eccentricity. Libration also changes the angle from which the Moon is seen, allowing a total of about 59% of its surface to be seen from Earth over time. The cumulative effects of stress built up by these tidal forces produces moonquakes. Moonquakes are much less common and weaker than are earthquakes, although moonquakes can last for up to an hour – significantly longer than terrestrial quakes – because of the absence of water to damp out the seismic vibrations. The existence of moonquakes was an unexpected discovery from seismometers placed on the Moon by Apollo astronauts from 1969 through 1972.</p>
            <br />
            <p>According to recent research, scientists suggest that the Moon's influence on the Earth may contribute to maintaining Earth's magnetic field.</p>
          </div>
          {/* Eclipses Of Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Eclipses Of Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Lunar_eclipse_October_8_2014_California_Alfredo_Garcia_Jr_mideclipse.JPG/1024px-Lunar_eclipse_October_8_2014_California_Alfredo_Garcia_Jr_mideclipse.JPG" width="550" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">The Moon, tinted reddish, during a lunar eclipse</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Eclipses only occur when the Sun, Earth, and Moon are all in a straight line (termed "syzygy"). Solar eclipses occur at new moon, when the Moon is between the Sun and Earth. In contrast, lunar eclipses occur at full moon, when Earth is between the Sun and Moon. The apparent size of the Moon is roughly the same as that of the Sun, with both being viewed at close to one-half a degree wide. The Sun is much larger than the Moon but it is the vastly greater distance that gives it the same apparent size as the much closer and much smaller Moon from the perspective of Earth. The variations in apparent size, due to the non-circular orbits, are nearly the same as well, though occurring in different cycles. This makes possible both total (with the Moon appearing larger than the Sun) and annular (with the Moon appearing smaller than the Sun) solar eclipses. In a total eclipse, the Moon completely covers the disc of the Sun and the solar corona becomes visible to the naked eye. Because the distance between the Moon and Earth is very slowly increasing over time, the angular diameter of the Moon is decreasing. Also, as it evolves toward becoming a red giant, the size of the Sun, and its apparent diameter in the sky, are slowly increasing. The combination of these two changes means that hundreds of millions of years ago, the Moon would always completely cover the Sun on solar eclipses, and no annular eclipses were possible. Likewise, hundreds of millions of years in the future, the Moon will no longer cover the Sun completely, and total solar eclipses will not occur.</p>
            <br />
            <p>Because the Moon's orbit around Earth is inclined by about 5.145° to the orbit of Earth around the Sun, eclipses do not occur at every full and new moon. For an eclipse to occur, the Moon must be near the intersection of the two orbital planes. The periodicity and recurrence of eclipses of the Sun by the Moon, and of the Moon by Earth, is described by the saros, which has a period of approximately 18 years.</p>
            <br />
            <p>Because the Moon continuously blocks the view of a half-degree-wide circular area of the sky, the related phenomenon of occultation occurs when a bright star or planet passes behind the Moon and is occulted: hidden from view. In this way, a solar eclipse is an occultation of the Sun. Because the Moon is comparatively close to Earth, occultations of individual stars are not visible everywhere on the planet, nor at the same time. Because of the precession of the lunar orbit, each year different stars are occulted.</p>
          </div>
        </div>

        <hr />

        {/* Observation & Exploration Of Moon */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Observation & Exploration Of Moon</h1>
          {/* Before Spaceflight On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Before Spaceflight On Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/0/03/Moon_by_Johannes_hevelius_1645.PNG" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Map of the Moon by Johannes Hevelius from his Selenographia (1647), the first map to include the libration zones</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>One of the earliest-discovered possible depictions of the Moon is a 5000-year-old rock carving Orthostat 47 at Knowth, Ireland.</p>
            <br />
            <p>Understanding of the Moon's cycles was an early development of astronomy: by the 5th century BC, Babylonian astronomers had recorded the 18-year Saros cycle of lunar eclipses, and Indian astronomers had described the Moon's monthly elongation. The Chinese astronomer Shi Shen (fl. 4th century BC) gave instructions for predicting solar and lunar eclipses. Later, the physical form of the Moon and the cause of moonlight became understood. The ancient Greek philosopher Anaxagoras (d. 428 BC) reasoned that the Sun and Moon were both giant spherical rocks, and that the latter reflected the light of the former. Although the Chinese of the Han Dynasty believed the Moon to be energy equated to qi, their 'radiating influence' theory also recognized that the light of the Moon was merely a reflection of the Sun, and Jing Fang (78–37 BC) noted the sphericity of the Moon. In the 2nd century AD, Lucian wrote the novel A True Story, in which the heroes travel to the Moon and meet its inhabitants. In 499 AD, the Indian astronomer Aryabhata mentioned in his Aryabhatiya that reflected sunlight is the cause of the shining of the Moon. The astronomer and physicist Alhazen (965–1039) found that sunlight was not reflected from the Moon like a mirror, but that light was emitted from every part of the Moon's sunlit surface in all directions. Shen Kuo (1031–1095) of the Song dynasty created an allegory equating the waxing and waning of the Moon to a round ball of reflective silver that, when doused with white powder and viewed from the side, would appear to be a crescent.</p>
            <br />
            <p>In Aristotle's (384–322 BC) description of the universe, the Moon marked the boundary between the spheres of the mutable elements (earth, water, air and fire), and the imperishable stars of aether, an influential philosophy that would dominate for centuries. However, in the 2nd century BC, Seleucus of Seleucia correctly theorized that tides were due to the attraction of the Moon, and that their height depends on the Moon's position relative to the Sun. In the same century, Aristarchus computed the size and distance of the Moon from Earth, obtaining a value of about twenty times the radius of Earth for the distance. These figures were greatly improved by Ptolemy (90–168 AD): his values of a mean distance of 59 times Earth's radius and a diameter of 0.292 Earth diameters were close to the correct values of about 60 and 0.273 respectively. Archimedes (287–212 BC) designed a planetarium that could calculate the motions of the Moon and other objects in the Solar System.</p>
            <br />
            <p>During the Middle Ages, before the invention of the telescope, the Moon was increasingly recognised as a sphere, though many believed that it was "perfectly smooth".</p>
            <br />
            <p>In 1609, Galileo Galilei drew one of the first telescopic drawings of the Moon in his book Sidereus Nuncius and noted that it was not smooth but had mountains and craters. Thomas Harriot had made, but not published such drawings a few months earlier. Telescopic mapping of the Moon followed: later in the 17th century, the efforts of Giovanni Battista Riccioli and Francesco Maria Grimaldi led to the system of naming of lunar features in use today. The more exact 1834–36 Mappa Selenographica of Wilhelm Beer and Johann Heinrich Mädler, and their associated 1837 book Der Mond, the first trigonometrically accurate study of lunar features, included the heights of more than a thousand mountains, and introduced the study of the Moon at accuracies possible in earthly geography. Lunar craters, first noted by Galileo, were thought to be volcanic until the 1870s proposal of Richard Proctor that they were formed by collisions. This view gained support in 1892 from the experimentation of geologist Grove Karl Gilbert, and from comparative studies from 1920 to the 1940s, leading to the development of lunar stratigraphy, which by the 1950s was becoming a new and growing branch of astrogeology.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Galileo%27s_sketches_of_the_moon.png/800px-Galileo%27s_sketches_of_the_moon.png" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Galileo's sketches of the Moon from Sidereus Nuncius</h3>
          </div>
          {/* 1959–1970s */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">1959–1970s</h2>
          <div className="lg:w-2/3 w-full">
            <p>Between the first human arrival with the robotic Soviet Luna program in 1958, to the 1970s with the last Missions of the crewed U.S. Apollo landings and last Luna mission in 1976, the Cold War-inspired Space Race between the Soviet Union and the U.S. led to an acceleration of interest in exploration of the Moon. Once launchers had the necessary capabilities, these nations sent uncrewed probes on both flyby and impact/lander missions.</p>
          </div>
          {/* Soviet Missions On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Soviet Missions On Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Luna_3_moon.jpg/1280px-Luna_3_moon.jpg" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">First view in history of the far side of the Moon, taken by Luna 3, 7 October 1959</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>Spacecraft from the Soviet Union's Luna program were the first to accomplish a number of goals: following three unnamed, failed missions in 1958, the first human-made object to escape Earth's gravity and pass near the Moon was Luna 1; the first human-made object to impact the lunar surface was Luna 2, and the first photographs of the normally occluded far side of the Moon were made by Luna 3, all in 1959.</p>
            <br />
            <p>The first spacecraft to perform a successful lunar soft landing was Luna 9 and the first uncrewed vehicle to orbit the Moon was Luna 10, both in 1966. Rock and soil samples were brought back to Earth by three Luna sample return missions (Luna 16 in 1970, Luna 20 in 1972, and Luna 24 in 1976), which returned 0.3 kg total. Two pioneering robotic rovers landed on the Moon in 1970 and 1973 as a part of Soviet Lunokhod programme.</p>
            <br />
            <p>Luna 24 was the last Soviet mission to the Moon.</p>
          </div>
          {/* United States Missions On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">United States Missions On Moon</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/NASA-Apollo8-Dec24-Earthrise.jpg/800px-NASA-Apollo8-Dec24-Earthrise.jpg" width="450" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Earthrise (Apollo 8, 1968, taken by William Anders)</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>During the late 1950s at the height of the Cold War, the United States Army conducted a classified feasibility study that proposed the construction of a staffed military outpost on the Moon called Project Horizon with the potential to conduct a wide range of missions from scientific research to nuclear Earth bombardment. The study included the possibility of conducting a lunar-based nuclear test. The Air Force, which at the time was in competition with the Army for a leading role in the space program, developed its own similar plan called Lunex. However, both these proposals were ultimately passed over as the space program was largely transferred from the military to the civilian agency NASA.</p>
            <br />
            <p>Following President John F. Kennedy's 1961 commitment to a manned Moon landing before the end of the decade, the United States, under NASA leadership, launched a series of uncrewed probes to develop an understanding of the lunar surface in preparation for human missions: the Jet Propulsion Laboratory's Ranger program produced the first close-up pictures; the Lunar Orbiter program produced maps of the entire Moon; the Surveyor program landed its first spacecraft four months after Luna 9. The crewed Apollo program was developed in parallel; after a series of uncrewed and crewed tests of the Apollo spacecraft in Earth orbit, and spurred on by a potential Soviet lunar human landing, in 1968 Apollo 8 made the first human mission to lunar orbit. The subsequent landing of the first humans on the Moon in 1969 is seen by many as the culmination of the Space Race.</p>
            <br />
            <p>Neil Armstrong became the first person to walk on the Moon as the commander of the American mission Apollo 11 by first setting foot on the Moon at 02:56 UTC on 21 July 1969. An estimated 500 million people worldwide watched the transmission by the Apollo TV camera, the largest television audience for a live broadcast at that time. The Apollo missions 11 to 17 (except Apollo 13, which aborted its planned lunar landing) removed 380.05 kilograms (837.87 lb) of lunar rock and soil in 2,196 separate samples. The American Moon landing and return was enabled by considerable technological advances in the early 1960s, in domains such as ablation chemistry, software engineering, and atmospheric re-entry technology, and by highly competent management of the enormous technical undertaking.</p>
            <br />
            <p>Scientific instrument packages were installed on the lunar surface during all the Apollo landings. Long-lived instrument stations, including heat flow probes, seismometers, and magnetometers, were installed at the Apollo 12, 14, 15, 16, and 17 landing sites. Direct transmission of data to Earth concluded in late 1977 because of budgetary considerations, but as the stations' lunar laser ranging corner-cube retroreflector arrays are passive instruments, they are still being used. Ranging to the stations is routinely performed from Earth-based stations with an accuracy of a few centimeters, and data from this experiment are being used to place constraints on the size of the lunar core.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/As11-40-5886%2C_uncropped.jpg/800px-As11-40-5886%2C_uncropped.jpg" width="550" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Neil Armstrong working at the Lunar Module Eagle during Apollo 11 (1969)</h3>
          </div>
          {/* 1970s – Present */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">1970s – Present</h2>
          <img alt="ecommerce" className="object-cover object-center mb-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Chandrayaan1_Spacecraft_Discovery_Moon_Water.jpg" width="550" />
          <div className="lg:w-2/3 w-full">
            <p>In the 1970s, after the Moon race, the focus of astronautic exploration shifted, as probes like Pioneer 10 and the Voyager program were sent towards the outer solar system. Years of near lunar quietude followed, only broken by a beginning internationalization of space and the Moon through, for example, the negotiation of the Moon treaty.</p>
            <br />
            <p>Since the 1990s, many more countries have become involved in direct exploration of the Moon. In 1990, Japan became the third country to place a spacecraft into lunar orbit with its Hiten spacecraft. The spacecraft released a smaller probe, Hagoromo, in lunar orbit, but the transmitter failed, preventing further scientific use of the mission. In 1994, the U.S. sent the joint Defense Department/NASA spacecraft Clementine to lunar orbit. This mission obtained the first near-global topographic map of the Moon, and the first global multispectral images of the lunar surface. This was followed in 1998 by the Lunar Prospector mission, whose instruments indicated the presence of excess hydrogen at the lunar poles, which is likely to have been caused by the presence of water ice in the upper few meters of the regolith within permanently shadowed craters.</p>
            <br />
            <p>The European spacecraft SMART-1, the second ion-propelled spacecraft, was in lunar orbit from 15 November 2004 until its lunar impact on 3 September 2006, and made the first detailed survey of chemical elements on the lunar surface.</p>
            <br />
            <p>The ambitious Chinese Lunar Exploration Program began with Chang'e 1, which successfully orbited the Moon from 5 November 2007 until its controlled lunar impact on 1 March 2009. It obtained a full image map of the Moon. Chang'e 2, beginning in October 2010, reached the Moon more quickly, mapped the Moon at a higher resolution over an eight-month period, then left lunar orbit for an extended stay at the Earth–Sun L2 Lagrangian point, before finally performing a flyby of asteroid 4179 Toutatis on 13 December 2012, and then heading off into deep space. On 14 December 2013, Chang'e 3 landed a lunar lander onto the Moon's surface, which in turn deployed a lunar rover, named Yutu (Chinese: 玉兔; literally "Jade Rabbit"). This was the first lunar soft landing since Luna 24 in 1976, and the first lunar rover mission since Lunokhod 2 in 1973. Another rover mission was launched in 2019, becoming the first ever spacecraft to land on the Moon's far side. China intends to following this up with a sample return mission in 2020.</p>
            <br />
            <p>Between 4 October 2007 and 10 June 2009, the Japan Aerospace Exploration Agency's Kaguya (Selene) mission, a lunar orbiter fitted with a high-definition video camera, and two small radio-transmitter satellites, obtained lunar geophysics data and took the first high-definition movies from beyond Earth orbit. India's first lunar mission, Chandrayaan-1, orbited from 8 November 2008 until loss of contact on 27 August 2009, creating a high-resolution chemical, mineralogical and photo-geological map of the lunar surface, and confirming the presence of water molecules in lunar soil. The Indian Space Research Organisation planned to launch Chandrayaan-2 in 2013, which would have included a Russian robotic lunar rover. However, the failure of Russia's Fobos-Grunt mission has delayed this project, and was launched on 22 July 2019. The lander Vikram attempted to land on the lunar south pole region on 6 September, but lost the signal in 2.1 km (1.3 mi). What happened after that is unknown.</p>
            <br />
            <p>The U.S. co-launched the Lunar Reconnaissance Orbiter (LRO) and the LCROSS impactor and follow-up observation orbiter on 18 June 2009; LCROSS completed its mission by making a planned and widely observed impact in the crater Cabeus on 9 October 2009, whereas LRO is currently in operation, obtaining precise lunar altimetry and high-resolution imagery. In November 2011, the LRO passed over the large and bright crater Aristarchus. NASA released photos of the crater on 25 December 2011.</p>
            <br />
            <p>Two NASA GRAIL spacecraft began orbiting the Moon around 1 January 2012, on a mission to learn more about the Moon's internal structure. NASA's LADEE probe, designed to study the lunar exosphere, achieved orbit on 6 October 2013.</p>
          </div>
          {/* Future */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Future</h2>
          <div className="lg:w-2/3 w-full">
            <p>Upcoming lunar missions include Russia's Luna-Glob: an uncrewed lander with a set of seismometers, and an orbiter based on its failed Martian Fobos-Grunt mission. Privately funded lunar exploration has been promoted by the Google Lunar X Prize, announced 13 September 2007, which offers US$20 million to anyone who can land a robotic rover on the Moon and meet other specified criteria.</p>
            <br />
            <p>NASA began to plan to resume human missions following the call by U.S. President George W. Bush on 14 January 2004 for a human mission to the Moon by 2019 and the construction of a lunar base by 2024. The Constellation program was funded and construction and testing begun on a crewed spacecraft and launch vehicle, and design studies for a lunar base. That program was cancelled in 2010, however, and was eventually replaced with the Artemis program, which plans to return humans to the Moon by 2025. India had also expressed its hope to send people to the Moon by 2020.</p>
            <br />
            <p>On 28 February 2018, SpaceX, Vodafone, Nokia and Audi announced a collaboration to install a 4G wireless communication network on the Moon, with the aim of streaming live footage on the surface to Earth.</p>
            <br />
            <p>Recent reports also indicate NASA's intent to send a woman astronaut to the Moon in their planned mid-2020s mission</p>
            {/* Planned Commercial Missions */}
            <h3 className="subtitle-font mt-5 mb-5 font-medium text-gray-900">Planned Commercial Missions</h3>
            <p>In 2007, the X Prize Foundation together with Google launched the Google Lunar X Prize to encourage commercial endeavors to the Moon. A prize of $20 million was to be awarded to the first private venture to get to the Moon with a robotic lander by the end of March 2018, with additional prizes worth $10 million for further milestones. As of August 2016, 16 teams were reportedly participating in the competition. In January 2018 the foundation announced that the prize would go unclaimed as none of the finalist teams would be able to make a launch attempt by the deadline.</p>
            <br />
            <p>In August 2016, the US government granted permission to US-based start-up Moon Express to land on the Moon. This marked the first time that a private enterprise was given the right to do so. The decision is regarded as a precedent helping to define regulatory standards for deep-space commercial activity in the future. Previously, private companies were restricted to operating on or around Earth.</p>
            <br />
            <p>On 29 November 2018 NASA announced that nine commercial companies would compete to win a contract to send small payloads to the Moon in what is known as Commercial Lunar Payload Services. According to NASA administrator Jim Bridenstine, "We are building a domestic American capability to get back and forth to the surface of the moon.".</p>
          </div>
        </div>

        <hr />

        {/* Human Presence On Moon */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Human Presence On Moon</h1>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Apollo_11_Lunar_Laser_Ranging_Experiment.jpg/800px-Apollo_11_Lunar_Laser_Ranging_Experiment.jpg" width="450" />
          <div className="mt-4">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">A photo of the still in use reflector of the Lunar Laser Ranging Experiment of Apollo 11.</h3>
          </div>
          {/* Human Impact On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Before Spaceflight On Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>Beside the traces of human activity on the Moon, there have been some intended permanent installations like the Moon Museum art piece, Apollo 11 goodwill messages, Lunar plaque, the Fallen Astronaut memorial, and other artifacts.</p>
          </div>
          {/* Infrastructure */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">1959–1970s</h2>
          <div className="lg:w-2/3 w-full">
            <p>Longterm missions continuing to be active are some orbiters such as the 2009-launched Lunar Reconnaissance Orbiter surveilling the Moon for future missions, as well as some Landers such as the 2013-launched Chang'e 3 with its Lunar Ultraviolet Telescope still operational.</p>
            <br />
            <p>There are several missions by different agencies and companies planned to establish a longterm human presence on the Moon, with the Lunar Gateway as the currently most advanced project as part of the Artemis program.</p>
          </div>
          {/* Astronomy From The Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Astronomy From The Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>For many years, the Moon has been recognized as an excellent site for telescopes. It is relatively nearby; astronomical seeing is not a concern; certain craters near the poles are permanently dark and cold, and thus especially useful for infrared telescopes; and radio telescopes on the far side would be shielded from the radio chatter of Earth. The lunar soil, although it poses a problem for any moving parts of telescopes, can be mixed with carbon nanotubes and epoxies and employed in the construction of mirrors up to 50 meters in diameter. A lunar zenith telescope can be made cheaply with an ionic liquid.</p>
            <br />
            <p>In April 1972, the Apollo 16 mission recorded various astronomical photos and spectra in ultraviolet with the Far Ultraviolet Camera/Spectrograph.</p>
          </div>
          {/* Living On The Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Living On The Moon</h2>
          <div className="lg:w-2/3 w-full">
            <p>Humans have stayed for days on the Moon, such as during Apollo 17. One particular challenge for astronauts' daily life during their stay on the surface is the lunar dust sticking to their suits and being carried into their quarters. Subsequently, the dust was tasted and smelled by the astronauts, calling it the "Apollo aroma". This contamination poses a danger since the fine lunar dust can cause health issues.</p>
            <br />
            <p>In 2019 at least one plant seed sprouted in an experiment, carried along with other small life from Earth on the Chang'e 4 lander in its Lunar Micro Ecosystem.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/ALSEP_AS17-134-20500.jpg/800px-ALSEP_AS17-134-20500.jpg" width="550" />
          <div className="mt-4 mb-5">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Remains of human activity, Apollo 17's Lunar Surface Experiments Package</h3>
          </div>
        </div>

        <hr />

        {/* Legal Status */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">Legal Status</h1>
          <div className="lg:w-2/3 w-full">
            <p>Although Luna landers scattered pennants of the Soviet Union on the Moon, and U.S. flags were symbolically planted at their landing sites by the Apollo astronauts, no nation claims ownership of any part of the Moon's surface. Russia, China, India, and the U.S. are party to the 1967 Outer Space Treaty, which defines the Moon and all outer space as the "province of all mankind". This treaty also restricts the use of the Moon to peaceful purposes, explicitly banning military installations and weapons of mass destruction. The 1979 Moon Agreement was created to restrict the exploitation of the Moon's resources by any single nation, but as of January 2020, it has been signed and ratified by only 18 nations, none of which engages in self-launched human space exploration. Although several individuals have made claims to the Moon in whole or in part, none of these are considered credible.</p>
            <br />
            <p>In 2020, U.S. President Donald Trump signed an executive order called "Encouraging International Support for the Recovery and Use of Space Resources". The order emphasizes that "the United States does not view outer space as a 'global commons'" and calls the Moon Agreement "a failed attempt at constraining free enterprise."</p>
            <br />
            <p>The Declaration of the Rights of the Moon was created by a group of "lawyers, space archaeologists and concerned citizens" in 2021, drawing on precedents in the Rights of Nature movement and the concept of legal personality for non-human entities in space.</p>
          </div>
        </div>

        
        <hr />

        {/* In Culture */}
        <div className="container mx-auto flex px-5 py-10 items-center flex-col">
          <h1 className="title-font sm:text-4xl text-3xl mb-5 font-medium text-gray-900">In Culture</h1>
          {/* Human Impact On Moon */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Before Spaceflight On Moon</h2>
          <img alt="ecommerce" className="object-cover object-center h-full block" src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Moon-bonatti.png" width="450" />
          <div className="mt-4">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Luna, the Moon, from a 1550 edition of Guido Bonatti's Liber astronomiae</h3>
          </div>
          <div className="lg:w-2/3 w-full">
            <p>The contrast between the brighter highlands and the darker maria creates the patterns seen by different cultures as the Man in the Moon, the rabbit and the buffalo, among others. In many prehistoric and ancient cultures, the Moon was personified as a deity or other supernatural phenomenon, and astrological views of the Moon continue to be propagated.</p>
            <br />
            <p>In Proto-Indo-European religion, the Moon was personified as the male god *Meh1not. The ancient Sumerians believed that the Moon was the god Nanna, who was the father of Inanna, the goddess of the planet Venus, and Utu, the god of the Sun. Nanna was later known as Sîn, and was particularly associated with magic and sorcery. In Greco-Roman mythology, the Sun and the Moon are represented as male and female, respectively (Helios/Sol and Selene/Luna); this is a development unique to the eastern Mediterranean and traces of an earlier male moon god in the Greek tradition are preserved in the figure of Menelaus.</p>
            <br />
            <p>In Mesopotamian iconography, the crescent was the primary symbol of Nanna-Sîn. In ancient Greek art, the Moon goddess Selene was represented wearing a crescent on her headgear in an arrangement reminiscent of horns. The star and crescent arrangement also goes back to the Bronze Age, representing either the Sun and Moon, or the Moon and planet Venus, in combination. It came to represent the goddess Artemis or Hecate, and via the patronage of Hecate came to be used as a symbol of Byzantium.</p>
            <br />
            <p>An iconographic tradition of representing Sun and Moon with faces developed in the late medieval period.</p>
            <br />
            <p>The splitting of the Moon (Arabic: انشقاق القمر‎) is a miracle attributed to Muhammad. A song titled 'Moon Anthem' was released on the occasion of landing of India's Chandrayan-II on the Moon.</p>
          </div>
          <img alt="ecommerce" className="object-cover object-center mt-5 h-full block" src="https://upload.wikimedia.org/wikipedia/commons/b/b2/Nuremberg_chronicles_f_76r_3.png" width="450" />
          <div className="mt-4">
            <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">Sun and Moon with faces (1493 woodcut)</h3>
          </div>
          {/* Calendar */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Calendar</h2>
          <div className="lg:w-2/3 w-full">
            <p>The Moon's regular phases make it a convenient timepiece, and the periods of its waxing and waning form the basis of many of the oldest calendars. Tally sticks, notched bones dating as far back as 20–30,000 years ago, are believed by some to mark the phases of the Moon. The ~30-day month is an approximation of the lunar cycle. The English noun month and its cognates in other Germanic languages stem from Proto-Germanic *mǣnṓth-, which is connected to the above-mentioned Proto-Germanic *mǣnōn, indicating the usage of a lunar calendar among the Germanic peoples (Germanic calendar) prior to the adoption of a solar calendar. The PIE root of moon, *méh1nōt, derives from the PIE verbal root *meh1-, "to measure", "indicat a functional conception of the Moon, i.e. marker of the month" (cf. the English words measure and menstrual), and echoing the Moon's importance to many ancient cultures in measuring time (see Latin mensis and Ancient Greek μείς (meis) or μήν (mēn), meaning "month"). Most historical calendars are lunisolar. The 7th-century Islamic calendar is an example of a purely lunar calendar, where months are traditionally determined by the visual sighting of the hilal, or earliest crescent moon, over the horizon.</p>
          </div>
          {/* Lunar Effect */}
          <h2 className="subtitle-font sm:text-2xl text-1xl mt-7 mb-10 font-medium text-gray-900">Lunar Effect</h2>
          <div className="lg:w-2/3 w-full">
            <p>The lunar effect is a purported unproven correlation between specific stages of the roughly 29.5-day lunar cycle and behavior and physiological changes in living beings on Earth, including humans.</p>
            <br />
            <p>The Moon has long been particularly associated with insanity and irrationality; the words lunacy and lunatic (popular shortening loony) are derived from the Latin name for the Moon, Luna. Philosophers Aristotle and Pliny the Elder argued that the full moon induced insanity in susceptible individuals, believing that the brain, which is mostly water, must be affected by the Moon and its power over the tides, but the Moon's gravity is too slight to affect any single person. Even today, people who believe in a lunar effect claim that admissions to psychiatric hospitals, traffic accidents, homicides or suicides increase during a full moon, but dozens of studies invalidate these claims.</p>
          </div>
        </div>
      </section>
    </>
  )
}
